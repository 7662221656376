import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Select } from 'antd'
import { API } from '../../api'
import { errorAlert } from '../Forms/formUtil'
const { Option } = Select

function mapStateToProps({ User }) {
  return {
    token: User.token
  }
}

class TypeLoadSelector extends Component {

  state = {
    data: []
  }

  componentDidMount() {
    this.setData()
  }

  setData = async () => {
    const { data } = await API.Vehicle.getAllTypeLoad(this.props.token)
    if (data.error) {
      errorAlert(data.error)
    } else {
      this.setState({
        data
      })
    }
  }

  render() {
    const { data } = this.state
    const selectedValues = this.props.values.map(item => item.id ? item.id : item)
    return (
      <Select
        mode='multiple'
        placeholder='Selecciona los permisos'
        onChange={this.props.handleTypeSelect}
        defaultValue={selectedValues}
        style={{ width: '100%' }}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {data.map(item =>
          (
            <Option
              key={item.name}
              value={item.id}
            >
              {item.name}
            </Option>
          )
        )}
      </Select>
    )
  }
}

TypeLoadSelector.defaultProps = {
  values: []
}

export default connect(mapStateToProps)(TypeLoadSelector)
