import React from 'react'
import { Link } from 'react-router-dom'
import {
  UncontrolledCollapse,
  Navbar,
  Container,
  Row,
  Col,
  NavItem,
  Nav,
  NavLink
} from 'reactstrap'

class IndexNavbar extends React.Component {
  render () {
    return (
      <>
        <Navbar
          className='navbar-top navbar-horizontal navbar-dark'
          expand='md'
        >
          <Container className='px-4'>
            {/* <NavbarBrand to='/' tag={Link}>
              <img alt='...' src={require('assets/img/brand/tuikit/logo-inverso.png')} />
            </NavbarBrand> */}
            <button className='navbar-toggler' id='navbar-collapse-main'>
              <span className='navbar-toggler-icon' />
            </button>
            <UncontrolledCollapse navbar toggler='#navbar-collapse-main'>
              <div className='navbar-collapse-header d-md-none'>
                <Row>
                  <Col className='collapse-brand' xs='6'>
                    <Link to='/'>
                      <img
                        alt='...'
                        src={require('assets/img/brand/tuikit/logo.png')}
                      />
                    </Link>
                  </Col>
                  <Col className='collapse-close' xs='6'>
                    <button
                      className='navbar-toggler'
                      id='navbar-collapse-main'
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className='ml-auto' navbar>
                <NavItem>
                  <NavLink
                    className='nav-link-icon'
                    to='/reset/resetPassword'
                    tag={Link}
                  >
                    Recuperar contraseña
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className='nav-link-icon'
                    to='/auth/login'
                    tag={Link}
                  >
                    Iniciar sesión
                  </NavLink>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </>
    )
  }
}

export default IndexNavbar
