import React, { Component } from 'react'
import { Input, Button, Form, Select, Statistic, notification } from 'antd'
import { connect } from 'react-redux'
import { getPrefix, errorAlert } from './formUtil'
import { API } from '../../api'
const { Option } = Select
const { Item } = Form
const { TextArea } = Input

function mapStateToProps({ User, Profile }) {
  return {
    token: User.token,
    id: Profile._id
  }
}

class TakeServiceForm extends Component {
  state = {
    isOffer: false
  }

  _modifyOption = value => {
    this.setState({ isOffer: value })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields(this._take)
  }

  _take = async (err, values) => {
    if (!err) {
      const { isOffer } = this.state
      const idOrder = this.props.item._id
      const idDriver = this.props.id
      const value = this.props.item.price
      const offer = isOffer
        ? { idDriver, idOrder, isOffer, ...values }
        : { value, idDriver, idOrder, isOffer }
      this._registerTake(offer)
    }
  }

  _registerTake = async offer => {
    const { data } = await API.Order.createOffer(this.props.token, offer)
    if (data._id) {
      this._successAlert()
      this.props.onOk()
      this.props.form.resetFields()
      this.props.updateData()
    } else {
      errorAlert(data.error)
    }
  }

  _successAlert = () => {
    const description = this.state.isOffer
      ? 'Se ha registrado la oferta, si el cliente acepta el valor éste servicio pasará a la pestaña mis servicios'
      : 'Ha tomado el servicio, dirijase a la pestaña mis servicios para asignar un conductor y un vehiculo'
    notification.open({
      type: 'success',
      message: 'Hecho',
      description
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { isOffer } = this.state
    return (
      <Form onSubmit={this.handleSubmit}>
        <Item>
          <Select
            placeholder='Selecciona una opcion de oferta'
            onChange={this._modifyOption}
            defaultValue={isOffer}
          >
            <Option value={true}>Ofertar otro valor</Option>
            <Option value={false}>Aceptar el valor del cliente</Option>
          </Select>
        </Item>
        {isOffer ? (
          <>
            <Item>
              {getFieldDecorator('value', {
                rules: [{ required: isOffer, message: 'El valor es obligatorio' }]
              })(
                <Input
                  prefix={getPrefix('$', true)}
                  placeholder='Precio'
                  type='number'
                />
              )}
            </Item>
            <Item>
              {getFieldDecorator('description', {
                rules: [{ required: isOffer, message: 'El valor es obligatorio' }]
              })(
                <TextArea
                  prefix={getPrefix('edit')}
                  placeholder='Escribe el motivo para ofertar un precio diferente'
                />
              )}
            </Item>
          </>
        ) : (
          <Item className='text-center'>
            <Statistic prefix='$' value={this.props.item.price || ''} />
          </Item>
        )}
        <Button type='primary' htmlType='submit' block>
          {isOffer ? 'Ofertar' : 'Aceptar'}
        </Button>
      </Form>
    )
  }
}

const TakeForm = Form.create({ name: 'take_service_form' })(TakeServiceForm)
export default connect(mapStateToProps)(TakeForm)
