import Axios from 'axios'
import { URL_PQR } from './Constant'

class Pqr {

  async register(token, pqr) {
    const response = await Axios.post(`${URL_PQR}/create`, pqr, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response
  }

  async findAll(token) {
    const response = await Axios.get(`${URL_PQR}/all`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response
  }
  async answerRequest(token, pqr) {
    const response = await Axios.put(`${URL_PQR}/update/${pqr._id}`, pqr, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response
  }
}

export default new Pqr()
