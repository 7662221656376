/*global google*/
import React from 'react'
import {useState, useEffect} from 'react'
import {
    GoogleMap,
    Marker,
    withGoogleMap,
    withScriptjs,
    DirectionsRenderer
} from 'react-google-maps'
import Wait from 'components/Feedback/Wait'
import socketIOClient from 'socket.io-client'
import {getUrl} from '../../api/Constant'

import truck from '../../assets/img/icons/marker.png'
import originIcon from '../../assets/img/icons/origin.png'
import destinationIcon from '../../assets/img/icons/destination.png'


import {db} from '../../firebase/index'

const MyMapComponent = withScriptjs(
    withGoogleMap(({position, origin, destination}) => {
        const [directions, setDirections] = useState()

        useEffect(() => {
            getDirections()
        }, [])

        const getDirections = () => {
            const directionsService = new google.maps.DirectionsService()
            directionsService.route(
                {
                    origin: getCoordinates(origin),
                    destination: getCoordinates(destination),
                    travelMode: google.maps.TravelMode.DRIVING
                },
                callbackDirections
            )
        }

        const callbackDirections = (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
                setDirections(result)
            } else {
                console.error(result)
            }
        }

        const getCoordinates = item => {
            return {lat: item.latitude, lng: item.longitude}
        }

        return (
            <GoogleMap
                zoom={position ? 17 : 10}
                center={position ? position : {lat: 4.65, lng: -74.1}}
            >
                <Marker
                    icon={truck}
                    position={position}
                    animation={google.maps.Animation.DROP}
                />
                <Marker
                    icon={originIcon}
                    position={getCoordinates(origin)}
                    animation={google.maps.Animation.DROP}
                />
                <Marker
                    icon={destinationIcon}
                    position={getCoordinates(destination)}
                    animation={google.maps.Animation.DROP}
                />
                <DirectionsRenderer
                    directions={directions}
                    defaultOptions={{
                        suppressMarkers: true,
                        markerOptions: {},
                        polylineOptions: {
                            strokeColor: '#1E306E'
                        }
                    }}
                ></DirectionsRenderer>
            </GoogleMap>
        )
    })
)

const Map = ({driver, origin, destination, idOrder}) => {
    const [position, setPosition] = useState()

    useEffect(() => {
        listen(db)
    }, [])

    const emit = (socket, send) => {
        const {idSocket} = driver
        socket.emit('getLocation', {
            idSocket,
            send
        })
    }

    const listen = db => {
        db.collection('services').where("idOrder", "==", idOrder)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    console.log(doc.id, " => ", doc.data());
                    db.collection('services').where("idOrder", "==", idOrder).onSnapshot(documentSnapshot => {
                        const data = documentSnapshot.docChanges()
                        const {location} = data[0].doc.data()
                        const newCoordinate = {lat: location.x_, lng: location.N_}
                        setPosition(newCoordinate)
                    })
                });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });


    }

    return (
        <MyMapComponent
            position={position}
            origin={origin}
            destination={destination}
            googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyCPaJZ9c3nvSP6KR1qYyaAZ2XyYZmhPLM8'
            loadingElement={<Wait/>}
            containerElement={<div style={{height: `600px`}}/>}
            mapElement={<div style={{height: `100%`}}/>}
        />
    )
}

Map.defaultProps = {
    driver: {}
}

export default Map
