import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import '../../node_modules/aos/dist/aos.css'
import AOS from 'aos'
import { NavbarBrand } from 'reactstrap'
import { Link } from 'react-router-dom'

import { routesSecurity as routes } from 'routes.js'

class Reset extends Component {
  componentDidMount() {
    AOS.init()
    document.body.classList.add('bg-default')
  }
  componentWillUnmount() {
    document.body.classList.remove('bg-default')
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === '/reset') {
        return (<Route path={prop.layout + prop.path} component={prop.component} key={key} />)
      }
      else {
        return null
      }
    })
  }
  render() {
    return (
      <>
        <div className='main-content' style={{ height: '100%' }}>
          <Row className='no-gutters ' style={{ height: '100%' }}>
            <Col xs='12' lg='6' className='col-auto d-flex py-8 flex-column justify-content-center bg-gradient-primary' style={{ zIndex: '2' }}>
              <div className='d-flex justify-content-center'>
                <NavbarBrand to='/' tag={Link} className='text-center'>
                  <img
                    style={{ maxWidth: '35%', margin: 'auto' }}
                    alt='...'
                    className='img-fluid'
                    src={require('assets/img/brand/tuikit/logo-inverso.png')}
                  />
                </NavbarBrand>
              </div>
              <div className='mt-6 text-center'>
                <img
                  style={{ maxWidth: '80%' }}
                  data-aos='fade-up'
                  data-aos-duration='1500'
                  data-aos-anchor-placement='bottom-bottom'
                  alt='...'
                  className='img-fluid'
                  src={require('assets/img/brand/tuikit/bg-restaurar.png')}
                />
              </div>
            </Col>
            <Col className='bg-secondary d-flex justify-content-center align-items-center py-9 py-md-9 py-lg-0'>
              <Switch>{this.getRoutes(routes)}</Switch>
            </Col>
          </Row>
        </div>
      </>
    )
  }
}

export default Reset

