import React from 'react'
import {Descriptions, Avatar, Tag} from 'antd'
import {green} from '@ant-design/colors'

import moment from 'moment'
import StatusTag from '../Tags/StatusTag'
import {Link} from 'react-router-dom'
import ServicePriceStatistic from './ServicePriceStatistic'
import DescriptionLabel from '../util/DescriptionLabel'

const {Item} = Descriptions

const date = (dateOne, dateTwo) => {
    let date1 = moment(dateOne)
    let date2 = moment(dateTwo)
    let minutes = date1.diff(date2, 'minutes')
    let hours = date1.diff(date2, 'hours')
    let secods = date1.diff(date2, 'seconds')
    let days = date1.diff(date2, 'days')

    return `Días: ${days},` + `Horas: ${hours},` + `Minutos: ${minutes},` + `Segundos: ${secods}`
}
const ServiceOverviewer = ({item, profile}) => {

    let dateDiference = date(item.tripStartAt, item.inPositionAt)
    let dateDiferenceFinsh = date(item.inFinishAt, item.finishStartAt)
    return (
        <Descriptions
            column={{xxl: 2, xl: 2, lg: 1, md: 1, sm: 1, xs: 1}}
            bordered
        >
            <Item label='codigo' span='2'>
                {item._id.substr(18)}
            </Item>

            <Item label='Vehiculo'>
                {item.vehicle && item.vehicle.licensePlate ? (
                    <Link to={`/main/vehiculo/${item.vehicle.licensePlate}`}>
                        <Tag>{item.vehicle.licensePlate}</Tag>
                    </Link>
                ) : (
                    <Tag>No asignado</Tag>
                )}
            </Item>
            <Item label='Estado'>
                <StatusTag status={item.status}/>
            </Item>
            {item.status === 4 ? <Item label='Motivo de cancelación' span='2'>
                {item.cancelation.description}
            </Item> : <></>}

            <Item label='Tipo de carga'>
                {item.typeLoad && item.typeLoad.name ? (
                    item.typeLoad.name
                ) : (
                    ''
                )}
            </Item>
            <Item label='Alto'>
                {!item.high || item.high.toLowerCase() === 'undefined cm' || item.high.toLowerCase() === 'undefined m' ? 'Indefinido' : item.high}
            </Item>

            <Item label='Peso'>
                {!item.weight || item.weight.toLowerCase() === 'undefined kg' || item.weight.toLowerCase() === 'undefined tn' ? 'Indefinido' : item.weight}
            </Item>

            <Item label='Ancho'>
                {!item.width || item.width.toLowerCase() === 'undefined cm' || item.width.toLowerCase() === 'undefined m' ? 'Indefinido' : item.width}
            </Item>

            <Item label='Volumen'>
                {!item.volume || item.volume.toLowerCase() === 'undefined cm' || item.volume.toLowerCase() === 'undefined m' ? 'Indefinido' : item.volume}
            </Item>

            <Item label='Fondo'>
                {!item.bottom || item.bottom.toLowerCase() === 'undefined cm' || item.bottom.toLowerCase() === 'undefined m' ? 'Indefinido' : item.bottom}
            </Item>


            {item.status === 4 ? <Item label='Motivo de cancelación' span='2'>
                {item.cancelation.description}
            </Item> : <></>}


            <Item label='Fecha de creacion'>
                {moment(item.createdAt).format('LLL')}
            </Item>
            <Item label='Fecha de servicio'>
                {moment(item.reservationDate).format('LLL')}
            </Item>


            <Item label='Origen'>{item.origin.name}</Item>
            <Item label='Destino'>{item.destination.name}</Item>
            <Item label='Transportador'>
                <Avatar icon='user' src={item.driver ? item.driver.image : ''}/>
                &nbsp;
                {item.driver ? item.driver.name : 'No asignado'}
            </Item>
            <Item label='Dueño de carga'>
                <Avatar icon='user' src={item.client.image}/>
                &nbsp;
                {item.client.name}
            </Item>

            <Item label='Fecha de llegada a punto de origen'>
                {moment(item.inPositionAt).format('LLL')}
            </Item>
            <Item label='Fecha de incio de recorrido'>
                {moment(item.tripStartAt).format('LLL')}
            </Item>
            <Item label='Tiempo en carga de mercancia' span='2'>
                {dateDiference}
            </Item>

            {
                item.inFinish ? <>
                    <Item label='Fecha de llegada a punto de destino'>
                        {moment(item.finishStartAt).format('LLL')}
                    </Item>
                    <Item label='Fecha de servicio finalizado'>
                        {moment(item.inFinishAt).format('LLL')}
                    </Item>
                    <Item label='Tiempo en descarga' span='2'>
                        {dateDiferenceFinsh}
                    </Item>
                </> : ''
            }


            <Item label='Descripción' span='2'>
                {item.description}
            </Item>
            {item.isInsurance ? <Item label='Link de aseguradora' span='2'>
                <a href={item.dataInsurance.link} target="_blank">{item.dataInsurance.link}</a>
            </Item> : <></>}


            {
                profile.rol == 'administrator' ? <Item
                    label={
                        <DescriptionLabel
                            text='Valor sugerido'
                            secondary='Para transportador'
                        />
                    }
                >
                    <ServicePriceStatistic value={item.price}/>
                </Item> : ''
            }


            <Item
                label={
                    <DescriptionLabel text='Valor sugerido' secondary='Por el cliente'/>
                }
            >
                <ServicePriceStatistic value={item.clientPrice}/>
            </Item>

            <Item
                label={
                    <DescriptionLabel
                        text='Valor oferta aceptada'
                        secondary='Para transportador'
                    />
                }
            >
                <ServicePriceStatistic value={item.offer ? item.offer.value : ''}/>
            </Item>
            <Item
                label={
                    <DescriptionLabel
                        text='Valor oferta total'
                        secondary='Pagado por el cliente'
                    />
                }
            >
                <ServicePriceStatistic
                    value={item.offer ? item.offer.valueProfit : ''}
                    valueStyle={{color: green.primary}}
                />
            </Item>
        </Descriptions>
    )
}

export default ServiceOverviewer
