import React from 'react'

const ServiceInfo = ({ icon, title, value }) => {
  return (
    <div className='col d-flex flex-row'>
      <div className='d-flex align-items-center'>
        <i className={`fas ${icon} text-primary`}></i>
      </div>
      <div className='ml-4 d-flex flex-column justify-content-center'>
        <h4 className='mb-0'>{title}</h4>
        <small className='text-wrap'>{value}</small>
      </div>
    </div>
  )
}

export default ServiceInfo
