import Axios from 'axios'
import { URL_USER, URL_AUTH } from './Constant'

class User {
  async createToken (email, password) {
    const response = await Axios.post(`${URL_AUTH}/tokenbackoffice`, {
      email,
      password
    })
    return response
  }
  
  async createTokenPassword (email) {
    const response = await Axios.post(`${URL_USER}/verifycode`, { email })
    return response
  }

  async changePassword (email, code, password) {
    const response = await Axios.post(`${URL_USER}/resetpassword`, {
      code,
      email,
      password
    })
    return response
  }
}

export default new User()
