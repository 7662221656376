import React, { Component } from 'react'
import { API } from '../../api'
import { Form, Input, Button, Rate, notification } from 'antd'
import { connect } from 'react-redux'
import { errorAlert,successAlert } from './formUtil'
const { Item } = Form
const { TextArea } = Input

function mapStateToProps({ User }) {
  return {
    token: User.token
  }
}

class RateServiceForm extends Component {
  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields(this._mainAction)
  }

  _mainAction = async (err, values) => {
    if (!err) {
      const { order } = this.props
      this._rate({ _id: order._id, ...values })
    }
  }

  _rate = async values => {
    const { data } = await API.ClientOrder.rateService(values, this.props.token)
    console.log('response data', data)
    if (data._id) {
      successAlert('servicio',true)
      this.props.onOk()
      this.props.form.resetFields()
    } else {
      errorAlert(data.error)
    }
  }

  _successAlert = () => {
    notification.open({
      type: 'success',
      message: 'Hecho',
      description: 'Servicio puntuado exitosamente'
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Form onSubmit={this.handleSubmit}>
        <Item>
          {getFieldDecorator('calification', {
            rules: [
              { required: true, message: 'La calificación es obligatoria' }
            ]
          })(<Rate />)}
        </Item>
        <Item>
          {getFieldDecorator('description')(
            <TextArea placeholder='Descripción (Opcional)' />
          )}
        </Item>
        <Button type='primary' htmlType='submit' block>
          Puntuar
        </Button>
      </Form>
    )
  }
}

const createdForm = Form.create({ name: 'rate_form' })(RateServiceForm)
export default connect(mapStateToProps)(createdForm)
