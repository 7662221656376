import React, { Component } from 'react'
import { Modal, Button } from 'antd'

class ModalForm extends Component {
  state = {
    defaultModal: false
  }

  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    })
  }

  render() {
    const children = React.cloneElement(this.props.children || <></>, {
      onOk: this.toggleModal.bind(this, this.props.modalId)
    })
    return (
      <>
        <Button
          onClick={() => this.toggleModal(this.props.modalId)}
          type={this.props.type}
          block={this.props.block ? true : false}
          disabled={this.props.disabled}
          ghost={this.props.ghost}
        >
          <span className={this.props.icon}></span>
        </Button>
        <Modal
          title={
            <>
              <i className={this.props.icon} />
              &nbsp; {this.props.title}{' '}
            </>
          }
          visible={this.state[this.props.modalId]}
          onOk={() => this.toggleModal(this.props.modalId)}
          onCancel={() => this.toggleModal(this.props.modalId)}
          footer={null}
          width={this.props.isForm ? 550 : this.props.width}
        >
          <div className={this.props.padding ? 'reset-padding' : ''}>
            {children}
          </div>
        </Modal>
      </>
    )
  }
}

ModalForm.defaultProps = {
  width: 1000,
  disabled: false
}

export default ModalForm
