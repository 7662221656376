import React from 'react'

const DescriptionLabel = ({ text, secondary }) => {
  return (
    <>
      {text}
      <div className='text-muted'>{secondary}</div>
    </>
  )
}

export default DescriptionLabel
