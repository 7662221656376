import Axios from 'axios'
import { URL_USER } from './Constant'

class User {
  async getAllWithoutRole(token) {
    const response = await Axios.get(`${URL_USER}/all`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response
  }

  async findAll(token) {
    const response = await Axios.get(`${URL_USER}/backoffice`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response
  }

  async register(token, user) {
    const response = await Axios.post(`${URL_USER}/create`, user, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response
  }

  async detailUser(token, id) {
    const response = await Axios.get(`${URL_USER}/detail/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response
  }

  async update(token, user) {
    const response = await Axios.put(`${URL_USER}/update/${user._id}`, user, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response
  }
}

export default new User()
