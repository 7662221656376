import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Form, Button, Upload, message, DatePicker, Icon} from 'antd'

const {Item} = Form

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

function mapStateToProps({User}) {
    return {
        token: User.token
    }
}

class VehicleProperty extends Component {
    constructor() {
        super()
        this.state = {
            frontProperty: undefined,
            backProperty: undefined,
            loaderFront: undefined,
            loaderBack: undefined
        }
    }

    _handleSubmit = e => {
        e.preventDefault()
        this.props.form.validateFields(this._mainAction)
    }

    _mainAction = (err, values) => {
        if (!err) {
            values.frontProperty = this.state.frontProperty
            values.backProperty = this.state.backProperty
            this.props.submit(values)
        }
    }

    handleChangeFront = info => {
        if (info.file.status === 'uploading') {
            this.setState({loaderFront: true})
            return
        }
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    frontProperty: imageUrl,
                }),
            )
        }
        this.setState({loaderFront: false})

    }

    handleChangeBack = info => {
        if (info.file.status === 'uploading') {
            this.setState({loaderBack: true})
            return
        }
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    backProperty: imageUrl,
                }),
            )
        }
        this.setState({loaderBack: false})
    }

    render() {
        const {getFieldDecorator} = this.props.form
        const {frontProperty, backProperty, loaderFront, loaderBack} = this.state

        return (
            <Form onSubmit={this._handleSubmit}>
                <Item label='Foto delantera Tarjeta de propiedad'>
                    {getFieldDecorator('frontProperty', {
                        valuePropName: 'file',
                        getValueFromEvent: e => e.file,
                        rules: [
                            {
                                required: true,
                                message: 'Selecciona una imagen'
                            }
                        ]
                    })(
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="https://api.tuikit.com/v1/user/image/upload/data"
                            beforeUpload={beforeUpload}
                            onChange={this.handleChangeFront}
                        >
                            {loaderFront ?
                                <Icon type="loading" style={{fontSize: '16px', color: '#08c'}}
                                      theme="outlined"/> : ''}
                            {!loaderFront && frontProperty == undefined ?
                                <Icon type="camera" style={{fontSize: '16px', color: '#08c'}}
                                      theme="outlined"/> : ''}
                            {frontProperty ? <img src={frontProperty} alt="avatar" style={{width: '100%'}}/> : ''}
                        </Upload>
                    )}
                </Item>
                <Item label='Fecha de expedición'>
                    {getFieldDecorator('dateInitProperty', {
                        rules: [
                            {
                                type: 'object',
                                required: true,
                                message: 'La fecha de expedición es obligatoria'
                            }
                        ],
                    })(
                        <DatePicker
                            format='YYYY-MM-DD'
                            className='w-100'
                        />
                    )}
                </Item>
                <Item label='Foto trasera Tarjeta de propiedad'>
                    {getFieldDecorator('backProperty', {
                        valuePropName: 'file',
                        getValueFromEvent: e => e.file,
                        rules: [
                            {
                                required: true,
                                message: 'Selecciona una imagen'
                            }
                        ]
                    })(
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="https://api.tuikit.com/v1/user/image/upload/data"
                            beforeUpload={beforeUpload}
                            onChange={this.handleChangeBack}
                        >
                            {loaderBack ?
                                <Icon type="loading" style={{fontSize: '16px', color: '#08c'}} theme="outlined"/> : ''}
                            {!loaderBack && backProperty == undefined ?
                                <Icon type="camera" style={{fontSize: '16px', color: '#08c'}} theme="outlined"/> : ''}
                            {backProperty ? <img src={backProperty} alt="avatar" style={{width: '100%'}}/> : ''}
                        </Upload>
                    )}
                </Item>
                <Button type='primary' htmlType='submit' block>
                    Siguiente
                </Button>
            </Form>
        )
    }
}

const CreatedForm = Form.create({name: 'vehicle_property_form'})(
    VehicleProperty
)
export default connect(mapStateToProps)(CreatedForm)
