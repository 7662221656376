import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'

class MainHeader extends Component {
  render () {
    return (
      <>
        <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'>
          <Container fluid>
            <div className='header-body'>
              {this.props.lines &&
                <Row>
                  <Col />
                </Row>
              }
            </div>
          </Container>
        </div>
      </>
    )
  }
}

export default MainHeader
