import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import AdminNavbar from 'components/Navbars/AdminNavbar.jsx'
import Sidebar from 'components/Sidebar/Sidebar.jsx'
import { Container } from 'reactstrap'
import AdminFooter from '../components/Footers/AdminFooter'
import NotFound from '../views/NotFound'
import { mainRoutes as routes } from 'routes.js'
import { connect } from 'react-redux'

function mapStateToProps({ User, Profile }) {
  return {
    token: User.token,
    profile: Profile
  }
}

class Admin extends Component {
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    this.refs.mainContent.scrollTop = 0
  }

  getRoutes = () => {
    const accessRoutes = this.getAccessRoutes() || []
    return accessRoutes.map((prop, key) =>
      (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      )
    )
  }

  getAccessRoutes = () => {
    let role = this.props.profile ? this.props.profile.rol : ''
    if (role) {
      let formattedRole = role.toLowerCase().replace(/\s/g, '');
      return routes[formattedRole]
    } else {
      return []
    }
  }

  getBrandText = () => {
    let accessRoutes = this.getAccessRoutes() || []
    let routeGetted = accessRoutes.filter(route => this.props.location.pathname.indexOf(
      route.layout + route.path
    ) !== -1)
    return (
      <div className='d-flex align-items-center'>
        <span className={`${routeGetted[0] ? routeGetted[0].icon : ''} text-white`}></span>
        &nbsp;
       <span className='align-self-end'>{routeGetted[0] ? routeGetted[0].name : ''}</span>
      </div>
    )
  }

  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={this.getAccessRoutes()}
          logo={{
            innerLink: '/admin/index',
            imgSrc: require('assets/img/brand/tuikit/logo.png'),
            imgAlt: 'TuiKit'
          }}
        />
        <div className='main-content' ref='mainContent'>
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText()}
          />
          <Switch>
            {this.getRoutes()}
            <Route component={NotFound} />
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    )
  }
}

export default connect(mapStateToProps)(Admin)
