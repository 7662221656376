import Axios from 'axios'
import { URL_LEGALDOCUMENTS } from './Constant'

class LegalDocuments {
  async register(token, document) {
    const response = await Axios.post(
      `${URL_LEGALDOCUMENTS}/create`,
      document,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    return response
  }

  async update(token, document) {
    const response = await Axios.put(
      `${URL_LEGALDOCUMENTS}/update/${document._id}`,
      document,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    return response
  }

  async findAll(token) {
    const response = await Axios.get(`${URL_LEGALDOCUMENTS}/byrol`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response
  }
}

export default new LegalDocuments()
