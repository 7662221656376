import React from 'react'
import { Row, Col } from 'reactstrap'
import { Card, Button } from 'antd'
import IsActiveBadge from '../Badges/IsActiveBadge'
import FeatureOverviewer from './FeatureOverviewer'
import { Link } from 'react-router-dom'

const VehiclesOverviewer = ({ vehicles, isEnterprise }) => {
  return (
    <>
      {!isEnterprise && <h4 className='mt-5'>Vehiculos</h4>}
      <Row>
        {vehicles.map((vehicle, index) => (
          <Col xs='12' sm='6' md='6' lg='4' xl='3' key={index} className='mb-4'>
            <Card
              cover={
                <img
                  alt='...'
                  src={
                    vehicle.images[0]
                      ? vehicle.images[0]
                      : require('./../../assets/img/brand/tuikit/cut-camion.png')
                  }
                />
              }
              actions={[
                <div className='px-4'>
                  <Link to={`/main/vehiculo/${vehicle.licensePlate}`}>
                    <Button block>
                      <i className='fas fa-search' />
                    </Button>
                  </Link>
                </div>
              ]}
            >
              <div className='reset-padding'>
                <Row>
                  <Col>
                    <h4>{vehicle.licensePlate}</h4>
                    <IsActiveBadge isActive={vehicle.isActive} />
                  </Col>
                  <Col>
                    <FeatureOverviewer name='Marca' value={vehicle.brand} />
                    <br />
                    <FeatureOverviewer
                      name='Carrocería'
                      value={
                        vehicle.bodyWork
                          ? vehicle.bodyWork.name
                          : ''
                      }
                      icon='fa-truck-loading'
                    />
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  )
}

VehiclesOverviewer.defaultProps = {
  vehicles: []
}

export default VehiclesOverviewer
