import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Form, Button, Upload, message, DatePicker, Icon} from 'antd'
const { Item } = Form

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

function mapStateToProps({ User }) {
    return {
        token: User.token
    }
}

class VehicleTech extends Component {
    constructor() {
        super()
        this.state = {
            imageTec: undefined,
            loader : undefined
        }
    }

    _handleSubmit = e => {
        e.preventDefault()
        this.props.form.validateFields(this._mainAction)
    }

    _mainAction = (err, values) => {
        if (!err) {
            values.imgTec = this.state.imageTec
            this.props.submit(values)
        }
    }

    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({loader: true})
            return
        }
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageTec: imageUrl,
                }),
            )
        }
        this.setState({loader: false})

    }

    render() {
        const { getFieldDecorator } = this.props.form
        const { imageTec , loader} = this.state

        return (
            <Form onSubmit={this._handleSubmit}>
                <Item label='Foto Revisión Tecnicomecánica'>
                    {getFieldDecorator('imgTec', {
                        valuePropName: 'file',
                        getValueFromEvent: e => e.file,
                        rules: [
                            {
                                required: true,
                                message: 'Selecciona una imagen'
                            }
                        ]
                    })(
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="https://api.tuikit.com/v1/user/image/upload/data"
                            beforeUpload={beforeUpload}
                            onChange={this.handleChange}
                        >
                            {loader ?
                                <Icon type="loading" style={{fontSize: '16px', color: '#08c'}} theme="outlined"/> : ''}
                            {!loader && imageTec == undefined ?
                                <Icon type="camera" style={{fontSize: '16px', color: '#08c'}} theme="outlined"/> : ''}
                            {imageTec ? <img src={imageTec} alt="avatar" style={{width: '100%'}}/> : ''}
                        </Upload>

                    )}
                </Item>
                <Item label='Fecha de vencimiento'>
                    {getFieldDecorator('dateEndTec', {
                        rules: [
                            {
                                type: 'object',
                                required: true,
                                message: 'La fecha de vencimiento es obligatoria'
                            }
                        ],
                    })(
                        <DatePicker
                            format='YYYY-MM-DD'
                            className='w-100'
                        />
                    )}
                </Item>
                <Button type='primary' htmlType='submit' block >
                    {this.props.item ? 'Editar' : 'Registrar'}
                </Button>
            </Form>
        )
    }
}

const CreatedForm = Form.create({ name: 'vehicle_tech_form' })(
    VehicleTech
)
export default connect(mapStateToProps)(CreatedForm)
