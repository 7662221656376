import Index from 'views/Index.jsx'
import Login from 'views/Login.jsx'
import Drivers from 'views/Drivers'
import Profile from 'views/Profile'
import ResetPassword from 'views/ResetPassword'
import Vehicle from './views/Vehicle'
import Clients from './views/Clients'
import Services from './views/Services'
import Accountancy from './views/Accountancy'
import Users from './views/Users'
import Enterprises from './views/Enterprises'
import LegalDocuments from './views/LegalDocuments'
import Pqr from './views/Pqr'
import EnterpriseDrivers from './views/EnterpriseDrivers'
import EnterpriseVehicles from './views/EnterpriseVehicles'
import ClientServices from './views/ClientServices'
import Canceled from './views/Canceled'
import Percentage from './views/Percentage'
import TermsConditions from './views/TermsConditions'


const mainRoutes = {
  administrator: [
    {
      path: '/index',
      name: 'Inicio',
      icon: 'fas fa-home text-primary',
      component: Index,
      layout: '/main',
      hidden: false
    },
    {
      path: '/usuarios',
      name: 'Usuarios',
      icon: 'fas fa-user-tie text-primary',
      component: Users,
      layout: '/main',
      hidden: false
    },
    {
      path: '/empresas',
      name: 'Empresa transportadora',
      icon: 'fas fa-industry text-primary',
      component: Enterprises,
      layout: '/main',
      hidden: false
    },
    {
      path: '/transportadores',
      name: 'Transportadores',
      icon: 'fas fa-truck text-primary',
      component: Drivers,
      layout: '/main',
      hidden: false
    },
    {
      path: '/duenodecarga',
      name: 'Dueños de carga',
      icon: 'fas fa-boxes text-primary',
      component: Clients,
      layout: '/main',
      hidden: false
    },
    {
      path: '/servicios',
      name: 'Servicios',
      icon: 'fas fa-clipboard-list text-primary',
      component: Services,
      layout: '/main',
      hidden: false
    },
    {
      path: '/cancelados',
      name: 'Cancelados',
      icon: 'fas fa-ban text-primary',
      component: Canceled,
      layout: '/main',
      hidden: false
    },
    {
      path: '/contabilidad',
      name: 'Contabilidad',
      icon: 'fas fa-chart-area text-primary',
      component: Accountancy,
      layout: '/main',
      hidden: false
    },
    {
      path: '/documentacion',
      name: 'Documentación',
      icon: 'fas fa-file text-primary',
      component: LegalDocuments,
      layout: '/main',
      hidden: false
    },
    {
      path: '/porcentajes',
      name: 'Porcentajes',
      icon: 'fas fa-percentage text-primary',
      component: Percentage,
      layout: '/main',
      hidden: false
    },
    {
      path: '/pqrs',
      name: 'Pqr',
      icon: 'fas fa-question text-primary',
      component: Pqr,
      layout: '/main',
      hidden: false
    },
    {
      path: '/detalle/:_id',
      name: 'Perfil',
      component: Profile,
      layout: '/main',
      hidden: true
    },
    {
      path: '/vehiculo/:licensePlate',
      name: 'Vehiculo',
      component: Vehicle,
      layout: '/main',
      hidden: true
    }
  ],
  accountancy: [
    {
      path: '/index',
      name: 'Inicio',
      icon: 'fas fa-home text-primary',
      component: Index,
      layout: '/main',
      hidden: false
    },
    {
      path: '/contabilidad',
      name: 'Contabilidad',
      icon: 'fas fa-chart-area text-primary',
      component: Accountancy,
      layout: '/main',
      hidden: false
    },
    {
      path: '/cancelados',
      name: 'Cancelados',
      icon: 'fas fa-ban text-primary',
      component: Canceled,
      layout: '/main',
      hidden: false
    }
  ],
  enterprise: [
    {
      path: '/index',
      name: 'Inicio',
      icon: 'fas fa-home text-primary',
      component: Index,
      layout: '/main',
      hidden: false
    },
    {
      path: '/transportadores',
      name: 'Transportadores',
      icon: 'fas fa-user text-primary',
      component: EnterpriseDrivers,
      layout: '/main',
      hidden: false
    },
    {
      path: '/vehiculos',
      name: 'Vehiculos',
      icon: 'fas fa-truck text-primary',
      layout: '/main',
      component: EnterpriseVehicles,
      hidden: false
    },
    {
      path: '/enterprise-services',
      name: 'Servicios',
      icon: 'fas fa-clipboard-list text-primary',
      component: Services,
      layout: '/main',
      hidden: false
    },
    {
      path: '/documentacion',
      name: 'Documentación',
      icon: 'fas fa-file text-primary',
      component: LegalDocuments,
      layout: '/main',
      hidden: false
    },
    {
      path: '/detalle/:_id',
      name: 'Perfil',
      component: Profile,
      layout: '/main',
      hidden: true
    },
    {
      path: '/vehiculo/:licensePlate',
      name: 'Vehiculo',
      component: Vehicle,
      layout: '/main',
      hidden: true
    }
  ],
  cliententerprise: [
    {
      path: '/index',
      name: 'Inicio',
      icon: 'fas fa-home text-primary',
      component: Index,
      layout: '/main',
      hidden: false
    },
    {
      path: '/cliente-servicios',
      name: 'Servicios',
      icon: 'fas fa-clock text-primary',
      component: ClientServices,
      layout: '/main',
      hidden: false
    },
    {
      path: '/cliente-ayuda',
      name: 'Ayuda',
      icon: 'fas fa-question-circle text-primary',
      component: Index,
      layout: '/main',
      hidden: false
    },
    {
      path: '/documentacion',
      name: 'Documentación',
      icon: 'fas fa-file text-primary',
      component: LegalDocuments,
      layout: '/main',
      hidden: false
    }
  ],
  soport : [
    {
      path: '/index',
      name: 'Inicio',
      icon: 'fas fa-home text-primary',
      component: Index,
      layout: '/main',
      hidden: false
    },
    {
      path: '/pqrs',
      name: 'Pqr',
      icon: 'fas fa-question text-primary',
      component: Pqr,
      layout: '/main',
      hidden: false
    },
  ]
}

const routesAuth = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    layout: '/auth'
  }
]

const routesSecurity = [
  {
    path: '/resetPassword',
    name: 'Cambiar contraseña',
    component: ResetPassword,
    layout: '/reset'
  }
]

const termsConditionRoute = [
  {
    path: '/conditions',
    name: 'Politicas Privacidad',
    component: TermsConditions,
    layout: '/term'
  }
]

export { routesAuth, routesSecurity, mainRoutes,termsConditionRoute }
