import React from 'react'
import { Tag } from 'antd'

const UserIsActiveTag = ({ isActive }) => {
  return (
    <Tag color={isActive ? 'green' : 'red'}>
      {isActive ? 'Activo' : 'Inactivo'}
    </Tag>
  )
}

export default UserIsActiveTag
