import Axios from 'axios'
import {URL_ORDER, URL_OFFER, URL_CANCELATION, URL_INCREASE} from './Constant'

class Order {
    async createOffer(token, offer) {
        const response = await Axios.post(`${URL_OFFER}/create`, offer, {
            headers: {Authorization: `Bearer ${token}`}
        })
        return response
    }

    async create(token, order) {
        const response = await Axios.post(`${URL_ORDER}/create`, order, {
            headers: {Authorization: `Bearer ${token}`}
        })
        return response
    }

    async update(token, order) {
        const response = await Axios.put(
            `${URL_ORDER}/update/${order._id}`,
            order,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        return response
    }

    async getAvaliableOrders(token) {
        const response = await Axios.get(`${URL_ORDER}/active/type`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return response
    }

    async findAll(token, userType, _id) {
        const response = await Axios.post(
            `${URL_ORDER}/type`,
            {userType, _id},
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        return response
    }

    async countStatusOrders(token) {
        const response = await Axios.get(`${URL_ORDER}/count`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return response
        // return {data : [10,25,1,3,40]}
    }

    async getAccountancy(token) {
        const response = await Axios.get(`${URL_ORDER}/accounting`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return response
    }

    async getCanceled(token) {
        const response = await Axios.get(`${URL_CANCELATION}/all`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return response
    }

    async suggestedPrice(data) {
        const response = await Axios.post(`${URL_ORDER}/suggested/price`, data, {
            headers: {
                Authorization: `Bearer ${data.token}`
            }
        })
        return response
    }

    async priceConvert(data, token) {
        const response = await Axios.post(`${URL_INCREASE}/calculate`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return response
    }

    async getSignature(data) {
        const response = await Axios.post(`${URL_ORDER}/crypto`, data, {
            headers: {
                'Authorization': `Bearer ${data.token}`
            }
        })
        return response.data
    }
}

export default new Order()
