import React, {Component} from 'react'
import {Container, Row, Col} from 'reactstrap'
import {Card, Button, Input} from 'antd'
import MainHeader from 'components/Headers/MainHeader.jsx'
import {connect} from 'react-redux'
import {API} from '../api'
import {errorAlert, successAlert} from '../components/Forms/formUtil'

function mapStateToProps({User, Profile}) {
    return {
        token: User.token,
        rol: Profile.rol
    }
}

class Percentage extends Component {
    state = {
        tuikit: undefined,
        secure: undefined,
        _id: undefined,
        editable: false
    }

    componentDidMount() {
        this.allPercentage()
    }

    allPercentage = async () => {
        const data = await API.Insurance.allPercentage(this.props.token)
        this.setState({
            tuikit: data.profit,
            secure: data.insurance,
            percent: data.percent,
            percentTuikitInsurance: data.percentTuikitInsurance,
            _id: data._id
        })
    }

    changeEditable = async () => {
        this.setState({editable: true})
    }

    changeEditableFalse = async () => {
        this.setState({editable: false})
        const data = await API.Insurance.allPercentage()
        this.setState({
            tuikit: data.profit,
            secure: data.insurance,
            percent: data.percent,
            percentTuikitInsurance: data.percentTuikitInsurance,
        })
    }

    changePercentage = async () => {
        const tuikit = this.state.tuikit
        if (tuikit) {
            const secure = this.state.secure
            if (secure) {
                const validateTuikit = parseInt(this.state.tuikit)
                if (validateTuikit < 101) {
                    const validateSecure = parseInt(this.state.secure)
                    if (validateSecure < 101) {
                        const data = await API.Insurance.updatePercentage({
                            _id: this.state._id,
                            profit: parseInt(this.state.tuikit),
                            insurance: parseFloat(this.state.secure),
                            percent: parseFloat(this.state.percent),
                            percentTuikitInsurance: parseFloat(this.state.percentTuikitInsurance),
                            token: this.props.token
                        })
                        if (!data.error) {
                            successAlert('formulario')
                            this.setState({
                                tuikit: this.state.tuikit,
                                secure: this.state.secure,
                                percent: this.state.percent,
                                percentTuikitInsurance: this.state.percentTuikitInsurance,
                                editable: false
                            })
                        } else {
                            errorAlert('Ocurrió un error al actualizar los datos. Por favor, intenta nuevamente')
                        }
                    } else {
                        errorAlert('El valor no puede ser mayor a 100')
                    }
                } else {
                    errorAlert('El valor no puede ser mayor a 100')
                }
            } else {
                errorAlert('Ingresa un valor')
            }
        } else {
            errorAlert('Ingresa un valor')
        }
    }

    render() {
        return (
            <>
                <MainHeader/>
                <Container className='mt--7' fluid>
                    <Row>
                        <Col>
                            <div className="site-card-wrapper">
                                <Row gutter={16}>
                                    <Col span={4}>
                                        <Card title="Porcentaje Ganancia Tuikit">
                                            {
                                                this.state.editable ?
                                                    <div style={{paddingInline: 25, paddingBlock: 25}}>
                                                        <Input defaultValue={this.state.tuikit} onChange={(e) => {
                                                            this.setState({tuikit: e.target.value})
                                                        }} maxLength={3}/>
                                                    </div> :
                                                    <h3 style={{paddingInline: 25, paddingBlock: 25}}>
                                                        {this.state.tuikit}%
                                                    </h3>
                                            }
                                        </Card>
                                    </Col>
                                    <Col span={4}>
                                        <Card title="Porcentaje Póliza">
                                            {
                                                this.state.editable ?
                                                    <div style={{paddingInline: 25, paddingBlock: 25}}>
                                                        <Input defaultValue={this.state.secure} onChange={(e) => {
                                                            this.setState({secure: e.target.value})
                                                        }} maxLength={4}/>
                                                    </div> :
                                                    <h3 style={{paddingInline: 25, paddingBlock: 25}}>
                                                        {this.state.secure}%
                                                    </h3>
                                            }
                                        </Card>
                                    </Col>
                                    <Col span={4}>
                                        <Card title="Porcentaje póliza cobro tuikit">
                                            {
                                                this.state.editable ?
                                                    <div style={{paddingInline: 25, paddingBlock: 25}}>
                                                        <Input defaultValue={this.state.percentTuikitInsurance} onChange={(e) => {
                                                            this.setState({percentTuikitInsurance: e.target.value})
                                                        }} maxLength={4}/>
                                                    </div> :
                                                    <h3 style={{paddingInline: 25, paddingBlock: 25}}>
                                                        {this.state.percentTuikitInsurance}%
                                                    </h3>
                                            }
                                        </Card>
                                    </Col>
                                    <Col span={8}>
                                        <Card title="Iva">
                                            {
                                                this.state.editable ?
                                                    <div style={{paddingInline: 25, paddingBlock: 25}}>
                                                        <Input defaultValue={this.state.percent} onChange={(e) => {
                                                            this.setState({percent: e.target.value})
                                                        }} maxLength={3}/>
                                                    </div> :
                                                    <h3 style={{paddingInline: 25, paddingBlock: 25}}>
                                                        {this.state.percent}%
                                                    </h3>
                                            }
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <div style={{marginBlock: 25}}>
                        {
                            this.state.editable ?
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <Button style={{backgroundColor: '#24286E'}} onClick={this.changePercentage}>
                                            <p style={{color: '#FFFFFF', fontSize: 14, paddingBlock: 5}}>
                                                Confirmar porcentajes
                                            </p>
                                        </Button>
                                        <Button style={{
                                            backgroundColor: '#FFFFFF',
                                            borderColor: '#24286E',
                                            borderRadius: 5,
                                            borderWidth: 1,
                                            marginInline: 25
                                        }} onClick={this.changeEditableFalse}>
                                            <p style={{color: '#24286E', fontSize: 14, paddingBlock: 5}}>
                                                Cancelar
                                            </p>
                                        </Button>
                                    </Col>
                                </Row> :
                                <Button style={{backgroundColor: '#24286E'}} onClick={this.changeEditable}>
                                    <p style={{color: '#FFFFFF', fontSize: 14, paddingBlock: 5}}>
                                        Editar porcentajes
                                    </p>
                                </Button>
                        }
                    </div>
                </Container>
            </>
        )
    }
}

export default connect(mapStateToProps)(Percentage)
