import React, { Component } from 'react'
import {
  Container,
  Row,
  Col
} from 'reactstrap'
import { connect } from 'react-redux'
import { API } from '../api'
import MainHeader from '../components/Headers/MainHeader'
import PqrsTable from '../components/Tables/PqrsTable'
import { errorAlert } from '../components/Forms/formUtil'

function mapStateToProps({ User }) {
  return {
    token: User.token
  }
}

class Pqr extends Component {
  state = {
    data: []
  }

  componentDidMount() {
    this._setData()
  }

  updateData = () => {
    this._setData()
  }

  _setData = async () => {
    const { data } = await API.Pqr.findAll(this.props.token)
    if (data.error) {
      errorAlert(data.error)
    } else {
      this.setState({
        data
      })
    }
  }

  render() {
    return (
      <>
        <MainHeader />
        <Container className='mt--7' fluid>
          <Row>
            <Col>
              <PqrsTable
                updateData={this.updateData}
                data={this.state.data}
              />
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default connect(mapStateToProps)(Pqr)