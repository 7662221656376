import React, { Component } from 'react'
import { Form, Icon, Input, Button } from 'antd'

class SendTokenForm extends Component {

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        if (this.props.submitToken) {
          this.props.submitToken(values)
        }
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'El correo es obligatorio' }, { type: 'email', message: 'La entrada no es un email válido' }]
          })(
            <Input
              prefix={<Icon type='mail' style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder='Email'
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button htmlType='submit' type='primary' block>
            Siguiente
            </Button>
        </Form.Item>
      </Form>
    )
  }
}

const FormToken = Form.create({ name: 'form_token' })(SendTokenForm)

export default FormToken
