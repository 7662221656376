import React, { Component } from 'react'
import getColumnSearchProps from '../../utils/filterTable'
import { Button, Tag } from 'antd'
import TableList from './TableList'
import { connect } from 'react-redux'
import LegalDocumentsForm from '../Forms/LegalDocumentsForm'
import IsActiveBadge from '../Badges/IsActiveBadge'
import ModalForm from '../Modals/ModalForm'
import { getRoleTraduction } from '../../utils/RoleConditions'
import { isEnterprise } from '../../utils/RoleConditions'

function mapStateToProps({ User, Profile }) {
  return {
    token: User.token,
    rol: Profile.rol
  }
}

const sectionName = 'Documentos'

class DocumentsTable extends Component {

  state = {
    data: [],
    searchText: '',
    filter: null
  }

  componentWillMount() {
    this.setData()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setData(nextProps.data)
    }
  }

  setData = async data => {
    if (data) {
      this.setState({
        data
      })
    } else {
      this.setState({
        data: this.props.data
      })
    }
  }

  _updateState = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  handleChange = filters => {
    this.setState({
      filter: filters
    })
  }

  _download = item => (
    <Button target='_blank' href={item.document} block>
      <i className='fas fa-file-download'></i>
    </Button>
  )

  _edit = item => (
    <ModalForm
      modalId='editDocument'
      icon='fas fa-edit'
      title={`Editar ${sectionName}`}
      padding
      block
      isForm
    >
      <LegalDocumentsForm
        item={item}
        updateData={this.props.updateData}
      />
    </ModalForm>
  )

  render() {

    const isEnter = isEnterprise(this.props.rol)

    const columns = [{
      key: 'name',
      title: 'Nombre',
      dataIndex: 'name',
      ...getColumnSearchProps('name', this.state.searchText, this._updateState),
    },
    {
      key: 'visible',
      title: 'Visible para',
      dataIndex: 'visible',
      ...getColumnSearchProps('visible', this.state.searchText, this._updateState),
      render: visible => {
        return visible.map((item, index) => (
          <Tag key={index} className='m-1'>
            {getRoleTraduction(item)}
          </Tag>
        ))
      },
    },
    {
      key: 'isActive',
      title: 'Estado',
      dataIndex: 'isActive',
      filters: [{ text: 'Activo', value: true }, { text: 'Inactivo', value: false }],
      onFilter: (value, record) => record.isActive.toString().includes(value),
      render: isActive => {
        return (
          <IsActiveBadge isActive={isActive} />
        )
      },
    },
    {
      key: 'operation',
      title: 'Descargar',
      fixed: 'right',
      width: 100,
      render: item => this._download(item)
    },
    ]

    if (!isEnter) {
      columns.push(
        {
          key: 'edit',
          title: 'Editar',
          fixed: 'right',
          width: 100,
          render: item => this._edit(item)
        }
      )
    }

    return (
      <TableList
        title={sectionName}
        data={this.state.data}
        columns={columns}
        filter={this.handleChange}
        notRegister={isEnter}
      >
        <LegalDocumentsForm
          updateData={this.props.updateData}
        />
      </TableList>
    )
  }
}

export default connect(mapStateToProps)(DocumentsTable)