import React, {Component} from 'react'
import {connect} from 'react-redux'
import TableList from './TableList'
import {Button} from 'antd'
import getColumnSearchProps from '../../utils/filterTable'
import IdTooltip from '../Tags/IdTooltip'
import Status from '../Tags/StatusTag'
import moment from 'moment'
import {decode, encode} from 'base-64'


function mapStateToProps({User}) {
    return {
        token: User.token
    }
}

const sectionName = 'Contabilidad'

class AccountancyTable extends Component {
    state = {
        searchText: '',
        filter: null
    }

    _updateState = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    handleChange = filters => {
        this.setState({
            filter: filters
        })
    }
    reportData = () => {
        let newData = []
        for (let order of this.props.data) {
            let texto = ""
            switch (order.statusOrder) {
                case 0:
                    texto = 'Pendiente'
                    break
                case 1:

                    texto = 'Asignado'
                    break
                case 2:

                    texto = 'En proceso'
                    break
                case 3:

                    texto = 'Finalizado'
                    break
                case 4:
                    texto = 'Cancelado'
                    break
                default:
                    break
            }

            newData.push({
                ...order,
                statusOrder: texto,
                _id: order._id.substr(18),
                "driver.name": order.driver.name,
                "driver.pay.bank": order.driver.pay.bank,
                "driver.pay.accountType": order.driver.pay.accountType,
                "driver.pay.accountNumber": decode(order.driver.pay.accountNumber),
            })
        }
        return newData
    }


    render() {
        const columns = [
            {
                key: 'statusOrder',
                title: 'Estado de la orden',
                dataIndex: 'statusOrder',
                width: 100,
                ...getColumnSearchProps(
                    'statusOrder',
                    this.state.searchText,
                    this._updateState
                ),
                render: statusOrder => {
                    return <Status status={statusOrder}></Status>
                }
            }, {
                key: 'transaction',
                title: 'Numero transacción',
                dataIndex: '_id',
                width: 100,
                ...getColumnSearchProps(
                    '_id',
                    this.state.searchText,
                    this._updateState
                ),
                render: _id => {
                    return <IdTooltip id={_id}/>
                }
            },
            {
                key: 'identification',
                title: 'Identificación',
                dataIndex: 'identification',
                width: 100,
                ...getColumnSearchProps(
                    'identification',
                    this.state.searchText,
                    this._updateState
                ),
            },
            {
                key: 'status',
                title: 'Estado transacción',
                dataIndex: 'status',
                width: 100,
                ...getColumnSearchProps(
                    'status',
                    this.state.searchText,
                    this._updateState
                ),
            },
            {
                key: 'date',
                title: 'Fecha',
                dataIndex: 'date',
                width: 100,
                render: date => moment(date).format('DD/MM/YYYY')
            },
            {
                key: 'driver',
                title: 'Transportador',
                width: 100,
                dataIndex: 'driver',
                width: 100,
                ...getColumnSearchProps(
                    'driver',
                    this.state.searchText,
                    this._updateState
                ),
                render: driver => driver.name
            },
            {
                key: 'bank',
                title: 'Banco',
                dataIndex: 'driver',
                width: 100,
                ...getColumnSearchProps(
                    'driver',
                    this.state.searchText,
                    this._updateState
                ),
                render: driver => driver.pay ? driver.pay.bank : ''
            },
            {
                key: 'accountType',
                title: 'Tipo de cuenta',
                dataIndex: 'driver',
                width: 100,
                ...getColumnSearchProps(
                    'driver',
                    this.state.searchText,
                    this._updateState
                ),
                render: driver => driver.pay ? driver.pay.accountType : ''
            },
            {
                key: 'accountNumber',
                title: 'Número de cuenta',
                dataIndex: 'driver',
                width: 100,
                ...getColumnSearchProps(
                    'driver',
                    this.state.searchText,
                    this._updateState
                ),
                render: driver => driver.pay ? decode(driver.pay.accountNumber) : ''
            },
            {
                key: 'travel',
                title: 'Viaje',
                dataIndex: 'travel',
                width: 100,
                ...getColumnSearchProps(
                    'travel',
                    this.state.searchText,
                    this._updateState
                ),
                render: travel => <IdTooltip id={travel}/>
            },
            {
                key: 'profitsDriver',
                title: 'Ganancias transportador',
                dataIndex: 'profitsDriver',
                width: 100,
                ...getColumnSearchProps(
                    'profitsDriver',
                    this.state.searchText,
                    this._updateState
                ),
                render: profitsDriver => profitsDriver ? `$${profitsDriver}` : 'N/A'
            },
            {
                key: 'profits',
                title: 'Ganancias tuikit',
                dataIndex: 'profits',
                width: 100,
                ...getColumnSearchProps(
                    'profits',
                    this.state.searchText,
                    this._updateState
                ),
                render: profits => profits ? `$${profits}` : 'N/A'
            },
            {
                key: 'totalCost',
                title: 'Costo',
                dataIndex: 'totalCost',
                width: 100,
                ...getColumnSearchProps(
                    'totalCost',
                    this.state.searchText,
                    this._updateState
                ),
                render: totalCost => totalCost ? `$${totalCost}` : 'N/A'
            },
            {
                key: 'priceCommodity',
                title: 'Valor declarado',
                dataIndex: 'priceCommodity',
                width: 100,
                ...getColumnSearchProps(
                    'priceCommodity',
                    this.state.searchText,
                    this._updateState
                ),
                render: priceCommodity => priceCommodity ? `$${priceCommodity}` : 'N/A'
            },
            {
                key: 'description',
                title: 'Detalle de pago',
                dataIndex: 'description',
                width: 100,
                ...getColumnSearchProps(
                    'description',
                    this.state.searchText,
                    this._updateState
                ),
                render: description => description
            },
            {
                key: 'ivaCost',
                title: 'Iva',
                dataIndex: 'ivaCost',
                width: 100,
                ...getColumnSearchProps(
                    'ivaCost',
                    this.state.searchText,
                    this._updateState
                ),
                render: ivaCost => ivaCost ? `$${ivaCost}` : 'N/A'
            },
            {
                key: 'priceInsurance',
                title: 'Venta Póliza',
                dataIndex: 'priceInsurance',
                width: 100,
                ...getColumnSearchProps(
                    'priceInsurance',
                    this.state.searchText,
                    this._updateState
                ),
                render: priceInsurance => priceInsurance ? `$${priceInsurance}` : 'N/A'
            },
            {
                key: 'priceTuikitInsurance',
                title: 'Costo Póliza ',
                dataIndex: 'priceTuikitInsurance',
                width: 100,
                ...getColumnSearchProps(
                    'priceTuikitInsurance',
                    this.state.searchText,
                    this._updateState
                ),
                render: priceTuikitInsurance => priceTuikitInsurance ? `$${priceTuikitInsurance}` : 'N/A'
            },

            {
                key: 'priceTuikitInsurance',
                title: 'Iva de poliza ',
                dataIndex: 'percentTuikitInsurance',
                width: 100,
                ...getColumnSearchProps(
                    'percentTuikitInsurance',
                    this.state.searchText,
                    this._updateState
                ),
                render: percentTuikitInsurance => percentTuikitInsurance ? `$${percentTuikitInsurance}` : 'N/A'
            },

            {
                key: 'total',
                title: 'Costo',
                dataIndex: 'total',
                width: 100,
                ...getColumnSearchProps(
                    'total',
                    this.state.searchText,
                    this._updateState
                ),
                render: total => total ? `$${total}` : 'N/A'
            },
        ]

        return (
            <>
                <TableList
                    title={sectionName}
                    data={this.props.data}
                    columns={columns}
                    filter={this.handleChange}
                    notRegister
                    dataReport={this.reportData}
                />
            </>

        )
    }
}

export default connect(mapStateToProps)(AccountancyTable)
