import React from 'react'

const FeatureOverviewer = ({ name, value, icon }) => {
  return (
    <div className='d-flex'>
      <div className='d-flex align-items-center'>
        <i className={`fas ${icon} p-2 m-2`} />
      </div>
      <div>
        <h4 className='m-0'>{name}</h4>
        {value}
      </div>
    </div>
  )
}

FeatureOverviewer.defaultProps = {
  icon: 'fa-cog'
}

export default FeatureOverviewer
