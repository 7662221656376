import React, {Component} from 'react'
import getColumnSearchProps from '../../utils/filterTable'
import {Avatar, Tag, Button} from 'antd'
import TableList from './TableList'
import ReactStars from 'react-stars'
import {Link} from 'react-router-dom'
import UserIsActiveTag from '../Tags/UserIsActiveTag'
import UserStatusTag from '../Tags/UserStatusTag'

class DriversTable extends Component {

    state = {
        data: [],
        searchText: '',
        filter: null
    }

    componentWillMount() {
        this.setData()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data) {
            this.setData(nextProps.data)
        }
    }

    setData = async data => {
        if (data) {
            this.setState({
                data
            })
        } else {
            this.setState({
                data: this.props.data
            })
        }
    }

    _updateState = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    handleChange = filters => {
        this.setState({
            filter: filters
        })
    }

    mainAction = item => {
        return (
            <Link
                to={`/main/detalle/${item._id}`}
            >
                <Button block>
                    <i className='fas fa-search'/>
                </Button>
            </Link>
        )
    }

    render() {
        const columns = [
            {
                key: '_id',
                title: 'Id',
                dataIndex: '_id',
                ...getColumnSearchProps('_id', this.state.searchText, this._updateState),
                render: item => item.substr(18)
            },
            {
                key: 'identification',
                title: 'Cédula',
                dataIndex: 'identification',
                ...getColumnSearchProps('identification', this.state.searchText, this._updateState),
            },
            {
                key: 'image',
                dataIndex: 'image',
                width: 50,
                render: image => {
                    return (
                        <Avatar
                            icon='user'
                            src={image}
                        />
                    )
                },
            },
            {
                key: 'name',
                title: 'Nombre',
                dataIndex: 'name',
                ...getColumnSearchProps('name', this.state.searchText, this._updateState),
            },
            {
                key: 'email',
                title: 'Email',
                dataIndex: 'email',
                ...getColumnSearchProps('email', this.state.searchText, this._updateState),
            },
            {
                key: 'countOrder',
                title: 'Servicios',
                dataIndex: 'countOrder',
                ...getColumnSearchProps('countOrder', this.state.searchText, this._updateState),
                render: countOrder => countOrder
            },
            {
                key: 'isActive',
                title: 'Activo',
                dataIndex: 'isActive',
                filters: [
                    {text: 'Activo', value: true},
                    {text: 'Inactivo', value: false},
                ],
                onFilter: (value, record) => record.isActive === value,
                render: isActive => {
                    return (
                        <UserIsActiveTag isActive={isActive}/>
                    )
                },
            },
            {
                key: 'status',
                title: 'Estado',
                dataIndex: 'status',
                filters: [
                    {text: 'Disponible', value: true},
                    {text: 'Ocupado', value: false},
                ],
                onFilter: (value, record) => record.status === value,
                render: status => {
                    return (
                        <UserStatusTag status={status}/>
                    )
                },
            },
            {
                key: 'score',
                title: 'Puntaje',
                dataIndex: 'score',
                render: score => {
                    return (
                        <ReactStars
                            count={5}
                            value={score}
                            edit={false}
                        />
                    )
                },
            },
            {
                title: 'Detalle',
                key: 'operation',
                fixed: 'right',
                width: 100,
                render: item => this.mainAction(item),
            }
        ]

        if (this.props.isEnterprise) {
            columns.unshift(
                {
                    key: '_id',
                    title: 'Id',
                    dataIndex: '_id',
                    ...getColumnSearchProps('_id', this.state.searchText, this._updateState),
                    render: item => item.substr(18)
                },
                {
                    key: 'image',
                    dataIndex: 'enterprise',
                    width: 50,
                    render: enterprise => {
                        return (
                            <Avatar
                                icon='user'
                                src={enterprise.image}
                            />
                        )
                    },
                },
                {
                    key: 'enterprise',
                    title: 'Empresa',
                    dataIndex: 'enterprise',
                    ...getColumnSearchProps('enterprise', this.state.searchText, this._updateState, 'name'),
                    render: enterprise => {
                        return enterprise.name
                    }
                }
            )
        } else {
            columns.splice(
                3,
                0,
                {
                    key: 'vehicles',
                    title: 'Vehiculos',
                    dataIndex: 'vehicles',
                    ...getColumnSearchProps('vehicles', this.state.searchText, this._updateState),
                    render: vehicles => {
                        if (vehicles.length > 0) {
                            return (
                                vehicles.map((vehicle, index) => (
                                    <Link
                                        to={`/main/vehiculo/${vehicle.licensePlate}`}
                                        key={index}
                                    >
                                        <Tag key={vehicle._id} className='m-1'>
                                            {vehicle.licensePlate}
                                        </Tag>
                                    </Link>
                                ))
                            )
                        } else {
                            return (
                                <Tag className='m-1'>
                                    No registrado
                                </Tag>
                            )
                        }
                    }
                }
            )
        }

        return (
            <TableList
                title={this.props.title || 'Transportadores'}
                data={this.state.data}
                columns={columns}
                filter={this.handleChange}
                notRegister
            >
            </TableList>
        )
    }
}

export default DriversTable
