import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import MainHeader from 'components/Headers/MainHeader'
import { connect } from 'react-redux'
import { API } from '../api'
import { errorAlert } from '../components/Forms/formUtil'
import VehiclesOverviewer from '../components/Overviewers/VehiclesOverviewer'
import { Card } from 'antd'
import ModalForm from '../components/Modals/ModalForm'
import VehicleForm from '../components/Forms/VehicleForm'

function mapStateToProps({ User, Profile }) {
  return {
    token: User.token,
    id: Profile._id
  }
}

class EnterpriseVehicles extends Component {
  state = {
    data: []
  }

  componentDidMount() {
    this._setData()
  }

  updateData = () => {
    this._setData()
  }

  _setData = async () => {
    const { data } = await API.Vehicle.getEnterpriseVehicles(
      this.props.token,
      this.props.id
    )
    if (data.error) {
      errorAlert(data.error)
    } else {
      this.setState({
        data
      })
    }
  }

  render() {
    return (
      <>
        <MainHeader />
        <Container className='mt--7' fluid>
          <Row>
            <Col>
            <Card 
              title='Vehiculos'
              extra={
                <ModalForm
                  modalId='createVehicle'
                  icon='fas fa-plus'
                  title='Crear vehiculo'
                  padding
                  isForm
                  type='primary'
                >
                  <VehicleForm updateData={this.updateData} />
                </ModalForm>
              }
              className='mb-5'
            />
              <VehiclesOverviewer vehicles={this.state.data} isEnterprise />
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default connect(mapStateToProps)(EnterpriseVehicles)
