import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Button, Select } from 'antd'
import API from '../../api/Vehicle'
const { Item } = Form
const { Option, OptGroup } = Select;

function mapStateToProps({ User }) {
  return {
    token: User.token
  }
}

class VehicleImagesForm extends Component {
  constructor() {
    super()
    this.state = {
      typetruckOptions: undefined,
      truckSelect: undefined,
      truckTitle: undefined,
      brandOptions: undefined,
      brandTitle: undefined,
      brandSelect: undefined,
      bodyWorkOptions: undefined,
      bodyWorkTitle: undefined,
      bodyWorkSelect: undefined
    }
  }

  async componentDidMount() {
    const typeTruck = await API.typeTruck(this.props.token)
    let titleTruck = []
    for (const truck of typeTruck) {
      for (const optionsTruck of truck.children) {
        this.setState({ typetruckOptions: optionsTruck, truckTitle: truck.name })
        titleTruck.push(
          <Option value={optionsTruck.id}>{optionsTruck.name}</Option>
        )
      }
    }
    this.setState({ truckSelect: titleTruck })
    const brand = await API.typeBrand(this.props.token)
    let titleBrand = []
    for (const value of brand) {
      this.setState({ brandOptions: value, brandTitle: 'Marca' })
      titleBrand.push(
        <Option value={value._id}>{value.name}</Option>
      )
    }
    this.setState({ brandSelect: titleBrand })
    const bodyWork = await API.bodyWork(this.props.token)
    let titleBodywork = []
    for (const optionsBodywork of bodyWork) {
      for (const value of optionsBodywork.children) {
        this.setState({ bodyWorkOptions: value, bodyWorkTitle: optionsBodywork.name })
        titleBodywork.push(
          <Option value={value.id}>{value.name}</Option>
        )
      }
    }
    this.setState({ bodyWorkSelect: titleBodywork })
  }

  handleChangeTypeTruck(value) {
    console.log(value)
  }

  handleChangeBrand(value) {
    console.log(value)
  }

  handleChangeBodyWork(value) {
    console.log(value)
  }

  _handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields(this._mainAction)
  }

  _mainAction = (err, values) => {
    if (!err) {
      this.props.submit(values)
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Form onSubmit={this._handleSubmit}>
        <Item label='Marca'>
          {getFieldDecorator('brand', {
            rules: [{ required: true, message: 'Selecciona una marca' }]
          })(
            <Select placeholder='Selecciona una marca' onChange={this.handleChangeBrand}>
              <OptGroup label={this.state.brandTitle}>
                {this.state.brandSelect}
              </OptGroup>
            </Select>
          )}
        </Item>
        <Item label='Tipo de camión'>
          {getFieldDecorator('_idTypeVehicle', {
            rules: [{ required: true, message: 'Selecciona un tipo de camión' }]
          })(
            <Select placeholder='Selecciona un tipo de camión' onChange={this.handleChangeTypeTruck}>
              <OptGroup label={this.state.truckTitle}>
                {this.state.truckSelect}
              </OptGroup>
            </Select>
          )}
        </Item>
        <Item label='Tipo de carrocería'>
          {getFieldDecorator('bodyWork', {
            rules: [{ required: true, message: 'Selecciona un tipo de carrocería' }]
          })(
            <Select placeholder='Selecciona un tipo de carrocería' onChange={this.handleChangeBodyWork}>
              <OptGroup label={this.state.bodyWorkTitle}>
                {this.state.bodyWorkSelect}
              </OptGroup>
            </Select>
          )}
        </Item>
        <Button type='primary' htmlType='submit' block>
          Siguiente
        </Button>
      </Form>
    )
  }
}

const CreatedForm = Form.create({ name: 'vehicle_images_form' })(
  VehicleImagesForm
)
export default connect(mapStateToProps)(CreatedForm)
