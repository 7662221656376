import React, { useEffect, useReducer } from 'react'
import { connect } from 'react-redux'
import io from 'socket.io-client'

import { getUrl } from '../api/Constant'
import { API } from '../api'
import MainHeader from '../components/Headers/MainHeader'
import { Container, Row, Col } from 'reactstrap'
import { Card, Avatar, Menu, Dropdown, Button } from 'antd'
import StatusTag from '../components/Tags/StatusTag'
import IdTooltip from '../components/Tags/IdTooltip'
import ModalForm from '../components/Modals/ModalForm'
import ServiceOverviewer from '../components/Overviewers/ServiceOverviewer'
import ServiceInfo from '../components/Overviewers/ServiceInfo'
import moment from 'moment'
import ServiceClientButton from '../components/Actions/ServiceClientButton'
import {errorAlert, infoAlert} from '../components/Forms/formUtil'

import produce from 'immer'
import CancelServiceForm from '../components/Forms/CancelServiceForm'
import ImagesOverviewer from '../components/Overviewers/ImagesOverviewer'
import VehiclesOverviewer from "../components/Overviewers/VehiclesOverviewer";
import OrderForm from '../components/Forms/OrderForm'

function mapStateToProps({ Profile, User }) {
  return {
    profile: Profile,
    user: User,
    data: []
  }
}

const reducer = (state, action) =>
  produce(state, draft => {
    switch (action.type) {
      case 'SET_ORDERS':
        draft.orders = action.orders
        break
      case 'ADD_OFFER':
        let index = state.orders.findIndex(
          order => order._id === action.offer.idOrder
        )
        draft.orders[index].offers.push(action.offer)
        break
      case 'SET_ORDER':
        let findIndex = state.orders.findIndex(
          order => order._id === action.order._id
        )
        draft.orders[findIndex] = action.order
        break
      default:
        break
    }
  })

const ClientServices = ({ profile, user, data }) => {
  const [state, dispatch] = useReducer(reducer, { orders: [] })

  useEffect(() => {
    getOrders()
    const socket = io(getUrl())
    socket.on('orderStatusBackoffice', data => listenOrderChange(data))
    socket.on('offerBackoffice', data => listenNewOffer(data))
    return () => {
      socket.close()
      // cleanup
    }
  }, [])

  const getOrders = async () => {
    const { data } = await API.Order.findAll(
      user.token,
      profile.rol,
      profile._id
    )
    dispatch({
      type: 'SET_ORDERS',
      orders: data
    })
  }

  const listenOrderChange = data => {
    dispatch({
      type: 'SET_ORDER',
      order: data
    })
    dataInfo('Se ha actualizado el servicio', data._id)
  }

  const listenNewOffer = data => {
    dispatch({
      type: 'ADD_OFFER',
      offer: data
    })
    dataInfo('Se ha creado una nueva oferta del servicio', data.idOrder)
  }

  const dataInfo = (text, orderId) => {
    infoAlert(
      <>
        {text}&nbsp;
        <IdTooltip id={orderId} />
      </>
    )
  }

  const _seeDetail = item => (
    <ModalForm
      modalId='serviceDetail'
      icon='fas fa-search'
      title={`Detalle servicio`}
      type='link'
    >
      <ServiceOverviewer item={item} profile={profile} />
    </ModalForm>
  )

  const _seePhotos = item => {
    return (
      <ModalForm
        modalId='servicePhotos'
        icon='fas fa-image'
        title='Imagenes'
        padding
      >
        <ImagesOverviewer
          images={item.images}
          supportDocument={item.supportDocument}
        />
      </ModalForm>
    )
  }

  const getCancel = item => (
    <ModalForm
      modalId='cancelModal'
      icon='fas fa-ban'
      title='Cancelar servicio'
      type='danger'
      ghost
      padding
      isForm
    >
      <CancelServiceForm order={item} />
    </ModalForm>
  )

  const getMenu = item => (
    <Menu>
      <Menu.Item key='1'>{_seeDetail(item)}</Menu.Item>
      <Menu.Item key='2'>{_seePhotos(item)}</Menu.Item>
      {!item.inPosition && (item.status === 0 || item.status === 1) && (
        <Menu.Item key='3'>{getCancel(item)}</Menu.Item>
      )}
    </Menu>
  )

  const getMainAction = item => {
    return <ServiceClientButton order={item} updateData={getOrders} />
  }
  const updateData = () => {
    this._setData()
  }

  const _setData = async () => {
    const { data } = await API.Vehicle.getEnterpriseVehicles(
        this.props.token,
        this.props.id
    )
    if (data.error) {
      errorAlert(data.error)
    } else {
      this.setState({
        data
      })
    }
  }

  return (
    <>
      <MainHeader />
      <Container className='mt--7' fluid>
        <Row>
          <Col>
            <Card
                title='Crear'
                extra={
                  <ModalForm
                      modalId='createVehicle'
                      icon='fas fa-plus'
                      title='Crear servicio'
                      padding
                      isForm
                      type='primary'
                  >
                    <OrderForm updateData={getOrders} />
                  </ModalForm>
                }
                className='mb-5'
            />
            <VehiclesOverviewer vehicles={data} isEnterprise />
          </Col>
        </Row>
        <Row>
          {state.orders.map(order => (
            <Col
              key={order._id}
              xs='12'
              sm='12'
              md='12'
              lg='6'
              xl='4'
              className='mb-5'
            >
              <Card
                title={
                  <div className='d-flex align-items-center'>
                    <Avatar
                      icon='user'
                      src={
                        order.images[0]
                          ? order.images[0]
                          : require('./../assets/img/brand/tuikit/boxes.png')
                      }
                      size='large'
                    />
                    <div className='mx-2'>
                      <h4 className='mb-0'>Código</h4>
                      <small className='text-muted'>
                        {<IdTooltip id={order._id.substr(18)} />}
                      </small>
                    </div>
                  </div>
                }
                extra={
                  <>
                    <StatusTag status={order.status} className='mr-2' />
                    <Dropdown overlay={getMenu(order)}>
                      <Button>
                        <i className='fas fa-caret-down'></i>
                      </Button>
                    </Dropdown>
                  </>
                }
                actions={[<div className='mx-4'>{getMainAction(order)}</div>]}
              >
                <div className='reset-padding'>
                  <div className='row'>
                    <ServiceInfo
                      icon='fa-calendar'
                      title='Fecha'
                      value={moment(order.reservationDate).format('lll')}
                    />
                    <ServiceInfo
                      icon='fa-box-open'
                      title='Carga'
                      value={order.typeLoad ? order.typeLoad.name : ''}
                    />
                  </div>
                  <div className='row mt-4'>
                    <ServiceInfo
                      icon='fa-map-marker-alt'
                      title='Origen'
                      value={<IdTooltip id={order.origin.name} />}
                    />
                    <ServiceInfo
                      icon='fa-flag'
                      title='Destino'
                      value={<IdTooltip id={order.destination.name} />}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  )
}

export default connect(mapStateToProps)(ClientServices)
