import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import Header from 'components/Headers/Header.jsx'
import { connect } from 'react-redux'
import { API } from '../api'
import { errorAlert } from '../components/Forms/formUtil'
import OrderStatus from '../components/Charts/OrderStatus'
import AccountancyTable from '../components/Tables/AccountancyTable'

function mapStateToProps({ User, Profile }) {
  return {
    token: User.token,
    rol: Profile.rol
  }
}

class Accountancy extends Component {
  state = {
    data: [],
    accountancy: []
  }

  componentDidMount() {
    this._setData()
  }

  updateData = () => {
    this.setState(
      {
        data: []
      },
      () => this._setData()
    )
  }

  _setData = () => {
    this._setOrdersData()
    this._setAccountancyData()
  }

  _setOrdersData = async () => {
    const { data } = await API.Order.findAll(this.props.token, this.props.rol)
    if (data.error) {
      errorAlert(data.error)
    } else {
      this.setState({ data })
    }
  }

  _setAccountancyData = async () => {
    const { data } = await API.Order.getAccountancy(this.props.token)
    if (data.error) {
      errorAlert(data.error)
    } else {
      this.setState({ accountancy: data })
    }
  }

  render() {
    return (
      <>
        <Header />
        <Container className='mt--7' fluid>
          <Row>
            <Col>
              <OrderStatus />
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col>
              <AccountancyTable data={this.state.accountancy} />
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default connect(mapStateToProps)(Accountancy)
