import Axios from 'axios'
import {URL_ORDER, URL_INCREASE} from './Constant'

class Insurance {

    async getCommodity(token) {
        const response = await Axios.get(`${URL_ORDER}/insurance/commodity/movili`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return response.data
    }

    async createInsurance(data, token) {
        const response = await Axios.post(`${URL_ORDER}/create/insurance`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return response.data
    }

    async allPercentage(token) {
        const response = await Axios.get(`${URL_INCREASE}/all`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        return response.data
    }

    async updatePercentage(data) {
        const response = await Axios.put(`${URL_INCREASE}/update/${data._id}`, data, {
            headers: {
                Authorization: `Bearer ${data.token}`
            }
        })
        return response.data
    }
}

export default new Insurance()
