import React, { Component } from 'react'
import {
  Col,
  Card,
  CardBody
} from 'reactstrap'
import MainResetForm from '../components/Forms/MainResetForm'

class ResetPassword extends Component {
  render () {
    return (
      <>
        <Col sm='8' lg='10' xl='8'>
          <Card className='shadow border-0 mt-7'>
            <CardBody className='px-lg-5'>
              <img
                src={require('assets/img/brand/tuikit/card-restaurar.png')}
                alt='...'
                className='img-fluid mt--7'
              />
              <div className='text-primary text-center pb-4'>
                <h1>Recuperar contraseña</h1>
                <p className='text-lead text-light'>
                  Enviaremos un codigo a tu correo, al digitarlo podrás cambiar la contraseña.
                </p>
              </div>
              <MainResetForm />
            </CardBody>
          </Card>
        </Col>
      </>
    )
  }
}

export default ResetPassword
