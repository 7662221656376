import React, { Component } from 'react'
import { connect } from 'react-redux'
import TableList from './TableList'
import getColumnSearchProps from '../../utils/filterTable'
import IdTooltip from '../Tags/IdTooltip'
import IsInsuranceTag from '../Tags/isInsuranceTooltip'
import moment from 'moment'
import ModalForm from '../Modals/ModalForm'
import { Avatar, Button } from 'antd'
import ServiceOverviewer from '../Overviewers/ServiceOverviewer'
import ImagesOverviewer from '../Overviewers/ImagesOverviewer'
import StatusTag from '../Tags/StatusTag'
import AssignDriverForm from '../Forms/AssignDriverForm'
import TakeServiceForm from '../Forms/TakeServiceForm'
import Map from '../Maps/Map'

function mapStateToProps({ User, Profile }) {
  return {
    profile: Profile,
    token: User.token,
    _id: Profile._id
  }
}

const sectionName = 'Servicios'

class ServicesTable extends Component {
  state = {
    searchText: '',
    filter: null
  }

  _updateState = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  handleChange = filters => {
    this.setState({
      filter: filters
    })
  }

  _mainAction = item => {
    return (
      <ModalForm
        modalId='serviceDetail'
        icon='fas fa-search'
        title={`Detalle ${sectionName}`}
        block
      >
        <ServiceOverviewer item={item} profile={this.props.profile}/>
      </ModalForm>
    )
  }

  _seePhotos = item => {
    return (
      <ModalForm
        modalId='servicePhotos'
        icon='fas fa-image'
        title='Imagenes'
        padding
        block
      >
        <ImagesOverviewer
          images={item.images}
          supportDocument={item.supportDocument}
        />
      </ModalForm>
    )
  }

  _seeMap = item => {
    return (
      <ModalForm
        modalId='serviceMap'
        icon='fas fa-map-marker-alt'
        title='Mapa'
        block
      >
        <Map
          driver={item.driver}
          origin={item.origin}
          destination={item.destination}
          idOrder={item._id}
        />
      </ModalForm>
    )
  }

  _update = item => {
    const isDisabled = item.status >= 2 || this.props._id !== item.driver._id
    return (
      <ModalForm
        modalId='enterpriseUpdate'
        icon='fas fa-edit'
        title='Asignar transportador y vehiculo'
        type='primary'
        block
        padding
        isForm
        disabled={isDisabled}
      >
        <AssignDriverForm item={item} updateData={this.props.updateData} />
      </ModalForm>
    )
  }

  _take = item => {
    const isDisabled = item.status !== 0
    return (
      <ModalForm
        modalId='takeService'
        icon='far fa-handshake'
        title='Tomar o ofertar'
        type='primary'
        block
        padding
        isForm
        disabled={isDisabled}
      >
        <TakeServiceForm item={item} updateData={this.props.updateData} />
      </ModalForm>
    )
  }

  render() {
    const columns = [
      {
        key: '_id',
        title: 'Id',
        dataIndex: '_id',
        width: 90,
        ...getColumnSearchProps(
          '_id',
          this.state.searchText,
          this._updateState
        ),
        render: _id => {
          return <IdTooltip id={_id} />
        }
      },
      {
        key: 'clientImage',
        width: 50,
        dataIndex: 'client',
        render: client => {
          return <Avatar icon='user' src={client.image} />
        }
      },
      {
        key: 'clientName',
        title: 'Dueño de carga',
        dataIndex: 'client',
        render: client => client.name
      },
      {
        key: 'createAt',
        title: 'Fecha creación',
        dataIndex: 'createdAt',
        defaultSortOrder: 'descend',
        sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        render: createdAt => {
          return <span>{moment(createdAt).format('LLL')}</span>
        }
      },
      {
        key: 'reservationDate',
        title: 'Fecha apartada',
        dataIndex: 'reservationDate',
        render: reservationDate => {
          return <span>{moment(reservationDate).format('LLL')}</span>
        }
      },
      {
        key: 'origin',
        title: 'Origen',
        dataIndex: 'origin',
        ...getColumnSearchProps(
          'origin',
          this.state.searchText,
          this._updateState,
          'name'
        ),
        render: origin => <IdTooltip id={origin.name} />
      },
      {
        key: 'destination',
        title: 'Destino',
        dataIndex: 'destination',
        ...getColumnSearchProps(
          'destination',
          this.state.searchText,
          this._updateState,
          'name'
        ),
        render: destination => <IdTooltip id={destination.name} />
      },
      {
        key: 'status',
        title: 'Estado',
        dataIndex: 'status',
        filters: [
          { text: 'Pendiente', value: 0 },
          { text: 'Asignado', value: 1 },
          { text: 'En proceso', value: 2 },
          { text: 'Finalizado', value: 3 },
          { text: 'Cancelado', value: 4 }
        ],
        onFilter: (value, record) => record.status.toString().includes(value),
        render: status => {
          return <StatusTag status={status} />
        }
      },
      {
        key: 'isInsurance',
        title: 'Asegurado',
        dataIndex: 'isInsurance',
        render: isInsurance => {
          return <IsInsuranceTag isInsurance={isInsurance} />
        }
      },
      {
        title: 'Fotos',
        key: 'photos',
        width: 100,
        render: item => this._seePhotos(item)
      },
      {
        key: 'map',
        title: 'Mapa',
        width: 100,
        render: item => this._seeMap(item)
      },
      {
        title: 'Detalle',
        key: 'operation',
        fixed: 'right',
        width: 100,
        render: item => this._mainAction(item)
      }
    ]

    if (this.props.canAssign) {
      columns.push({
        title: 'Asignar',
        key: 'assign',
        fixed: 'right',
        width: 100,
        render: item => this._update(item)
      })
    }

    if (this.props.canTake) {
      columns.push({
        title: 'Tomar',
        key: 'take',
        fixed: 'right',
        width: 100,
        render: item => this._take(item)
      })
    }

    return (
      <TableList
        title={sectionName}
        data={this.props.data}
        columns={columns}
        filter={this.handleChange}
        notRegister
        buttonDonwload={
          <Button
            type='primary'
            icon='reload'
            onClick={this.props.updateData}
          />
        }
      />
    )
  }
}

export default connect(mapStateToProps)(ServicesTable)
