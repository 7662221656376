import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Form, Button, Input} from 'antd'
import {Container, Row, Col} from 'reactstrap'
import API from '../../../api/Insurance'
import {errorAlert, successAlert} from "../formUtil"
import Select from 'react-select'


function mapStateToProps({User}) {
    return {
        token: User.token
    }
}

const {Item} = Form

class DetailPay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            insuranceCheck: false,
            commodity: [],
            commodityValues: [{label: 'Option', value: 1, codTipmer: 1}],
            commoditySelected: null,
            mobily: [],
            mobilyValues: [{label: 'Option', value: 1}],
            mobilySelected: null,
            box: [],
            priceCommodity: 0,
            boxValues: [{label: 'Option', value: 1}],
            boxSelected: null,
            insurance: this.props.order.isInsurance,
            pdfButton: this.props.order.isInsurance ? <a target="_blank" href={this.props.order.dataInsurance.link}>
                <Button
                    type='primary'
                    onClick={this.makeInsurance}
                    block
                    className='mt-4'
                >
                    Descargar PDF
                </Button>
            </a> : undefined,
            finalPrice: this.props.order.isInsurance ? this.props.order.priceInsurance : undefined
        }
    }

    componentDidMount() {
        this.getCommodity()
    }

    _handleSubmit = e => {
        e.preventDefault()
    }

    getCommodity = async () => {
        const data = await API.getCommodity(this.props.token)
        this.setState({commodity: data.responseMercancias.data})
        this.setState({box: data.responseEmpaque.data})
        this.setState({movili: data.responseMovili.data})

        let pickerItem = []
        for (const commodityItems of this.state.commodity) {
            pickerItem.push({
                label: commodityItems.nom_mercan,
                value: commodityItems.cod_mercan,
                codTipmer: commodityItems.cod_tipmer
            })
        }
        let pickerMovili = []
        for (const moviliItems of this.state.movili) {
            pickerMovili.push({
                label: moviliItems.nom_movili,
                value: moviliItems.cod_movili
            })
        }
        let pickerBox = []
        for (const boxItems of this.state.box) {
            pickerBox.push({
                label: boxItems.nom_tipemp,
                value: boxItems.cod_tipemp
            })
        }
        this.setState({boxValues: pickerBox})
        this.setState({mobilyValues: pickerMovili})
        this.setState({commodityValues: pickerItem})
    }



    handleChangeInput = selectedOption => {
        this.setState({commoditySelected: selectedOption})
    }

    handleChangeInputCommodity = event => {

        if (parseInt(event.target.value) > 500000000) {
            errorAlert("No puede superar la cantidad de $500'000.000")
            this.setState({priceCommodity: 0})
        } else {
            this.setState({priceCommodity: parseInt(event.target.value)})
        }


    }

    handleChangeMobily = selectedOption => {
        this.setState({mobilySelected: selectedOption})
    }

    handleChangeBox = selectedOption => {
        this.setState({boxSelected: selectedOption})
    }

    toggleChange = () => {
        this.setState({
            insuranceCheck: !this.state.insuranceCheck,
        });
    }

    makeInsurance = async () => {
        const validate = this.state.commoditySelected && this.state.mobilySelected && this.state.boxSelected && this.state.priceCommodity > 0
        if (validate) {
            const priceInsurance = (this.state.priceCommodity * 0.15) / 100


            const insurance = await API.createInsurance({
                responseMovili: this.state.mobilySelected.value,
                responseMercancias: {
                    id: this.state.commoditySelected.value,
                    name: this.state.commoditySelected.label,
                    codTipmer: this.state.commoditySelected.codTipmer
                },
                responseEmpaque: this.state.boxSelected.value,
                orderId: this.props.order._id,
                priceCommodity: this.state.priceCommodity,
                priceInsurance
            }, this.props.token)
            // const increase = await Api
            if (insurance) {
                this.setState({
                    insurance: true,
                    pdfButton: <a target="_blank" href={insurance.data.link}>
                        <Button
                            type='primary'
                            onClick={this.makeInsurance}
                            block
                            className='mt-4'
                        >
                            Descargar PDF
                        </Button>
                    </a>,
                    finalPrice: priceInsurance
                })
                const finalPrice = this.props.order.offer != undefined ? this.props.order.offer.valueProfit + this.state.finalPrice : this.props.order.clientPrice + this.state.finalPrice
                successAlert('Se creó la póliza correctamente')
                localStorage.setItem('finalPrice', finalPrice)
            } else {
                errorAlert('Ocurrió un error al generar la póliza')
            }
        } else {
            errorAlert('Completa todos los campos')
        }
    }

    render() {
        return (
            <Form onSubmit={this._handleSubmit}>
                <Item label="El precio de tu carga es:">
                    <div>${this.props.order.offer != undefined ? this.props.order.offer.valueProfit : this.props.order.clientPrice}</div>
                </Item>
                {this.state.insurance === false ?
                    <Item label="¿Quieres asegurar tu carga?">
                        <Container fluid>
                            <Row>
                                <Col>
                                    <Input type="checkbox"
                                           checked={this.state.insuranceCheck}
                                           onChange={this.toggleChange}
                                    />
                                </Col>
                                <Col>
                                    <div>
                                        Asegurar mi carga
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Item> :
                    <>
                        <Item label="El precio de la póliza es:">
                            <div>
                                ${this.state.finalPrice}
                            </div>
                        </Item>
                        {this.state.pdfButton}
                    </>
                }
                {this.state.insuranceCheck ?
                    <>
                        {this.state.isInsurance === false ?
                            <>
                                <Item label="Precio neto de la mercancia">
                                    <Input
                                        type={'number'}
                                        value={this.state.priceCommodity}
                                        onChange={(event) => this.handleChangeInputCommodity(event)}
                                    />
                                </Item>
                                <Item label="Seleccione tipo de mercancia">
                                    <Select
                                        value={this.state.commoditySelected}
                                        onChange={this.handleChangeInput}
                                        options={this.state.commodityValues}
                                    />
                                </Item>
                                <Item label="Seleccione tipo de movilización">
                                    <Select
                                        value={this.state.mobilySelected}
                                        onChange={this.handleChangeMobily}
                                        options={this.state.mobilyValues}
                                    />
                                </Item>
                                <Item label="Seleccione tipo de empaque">
                                    <Select
                                        value={this.state.boxSelected}
                                        onChange={this.handleChangeBox}
                                        options={this.state.boxValues}
                                    />
                                </Item>
                                <Button
                                    type='primary'
                                    onClick={this.makeInsurance}
                                    block
                                    className='mt-4'
                                >
                                    Crear póliza
                                </Button>
                            </> : null
                        }
                    </> : null
                }
            </Form>
        )
    }
}

const CreatedForm = Form.create({name: 'detail_pay'})(
    DetailPay
)
export default connect(mapStateToProps)(CreatedForm)
