import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import MainHeader from 'components/Headers/MainHeader.jsx'
import { connect } from 'react-redux'
import { API } from '../api'
import ServicesTable from '../components/Tables/ServicesTable'
import { errorAlert } from '../components/Forms/formUtil'
import { isEnterprise } from '../utils/RoleConditions'
import { Tabs } from 'antd'
const { TabPane } = Tabs

function mapStateToProps({ User, Profile }) {
  return {
    token: User.token,
    profile: Profile
  }
}

class Services extends Component {
  state = {
    data: [],
    avaliableServices: []
  }

  componentDidMount() {
    this._setData()
  }

  updateData = async () => {
    this.setState(
      {
        data: []
      },
      () => this._setData()
    )
  }

  _setData = async () => {
    const { rol } = this.props.profile
    this._getServices()
    if (isEnterprise(rol) === true) {
      this._getAvaliableServices()
    }
  }

  _getServices = async () => {
    const { _id, rol } = this.props.profile
    const { data } = await API.Order.findAll(this.props.token, rol, _id)
    if (data.error) {
      errorAlert(data.error)
    } else {
      this.setState({ data })
    }
  }

  _getAvaliableServices = async () => {
    const { data } = await API.Order.getAvaliableOrders(this.props.token)
    if (data.error) {
      errorAlert(data.error)
    } else {
      this.setState({ avaliableServices: data })
    }
  }

  render() {
    const isEnter = isEnterprise(this.props.profile.rol)
    return (
      <>
        <MainHeader lines />
        <Container className='mt--7' fluid>
          <Row>
            <Col>
              {isEnter ? (
                <Tabs type='card' tabBarStyle={{ margin: '0', border: '0' }}>
                  <TabPane tab='Mis servicios' key='1'>
                    <ServicesTable
                      updateData={this.updateData}
                      data={this.state.data}
                      canAssign
                    />
                  </TabPane>
                  <TabPane tab='Servicios disponibles' key='2'>
                    <ServicesTable
                      updateData={this.updateData}
                      data={this.state.avaliableServices}
                      canTake
                    />
                  </TabPane>
                </Tabs>
              ) : (
                <ServicesTable
                  updateData={this.updateData}
                  data={this.state.data}
                />
              )}
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default connect(mapStateToProps)(Services)
