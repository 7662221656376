import Axios from 'axios'
import { URL_USER } from './Constant'

class Client {
  async getAll(token, isEnterprise = false) {
    const response = await Axios.get(`${URL_USER}/clients/${isEnterprise}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response
  }
}

export default new Client()
