const URL_PRODUCTION = 'https://api.tuikit.com'
const URL_DEVELOPMENT = 'https://api.tuikit.com'

const constructUrl = second => {
  return `${getUrl()}/v1${second}`
}

export const getUrl = () =>
  process.env.NODE_ENV === 'production' ? URL_PRODUCTION : URL_DEVELOPMENT

export const URL_AUTH = constructUrl('/auth')
export const URL_USER = constructUrl('/user')
export const URL_ORDER = constructUrl('/order')
export const URL_INCREASE = constructUrl('/increase')
export const URL_CALIFICATION = constructUrl('/calification')
export const URL_LEGALDOCUMENTS = constructUrl('/document')
export const URL_PQR = constructUrl('/pqr')
export const URL_VEHICLE = constructUrl('/vehicle')
export const URL_TYPELOAD = constructUrl('/typeload')
export const URL_OFFER = constructUrl('/offer')
export const URL_VEHICLE_TYPE = constructUrl('/typevehicle')
export const URL_CANCELATION = constructUrl('/cancellation')

export const URL_API = 'https://api.tuikit.com'

//Variables orden de servicio
const order = {
  price: 'La ganancia del transportador, aplica en el momento en que el cliente crea el servicio',
  clientPrice: 'El valor que el cliente realmente debe pagar si un transportador acepta el precio sugerido por el cliente.',
  //Aplica cuando ya un transportador o empresa transportador tomó el servicio
  offer : {
    value: 'La ganancia del transportador (El valor sin porcentaje de ganancia de tuikit)',
    valueProfit: 'El valor que paga el cliente (Ganancia transportador + ganancia tuikit)'
  }
}