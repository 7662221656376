import React, { Component } from 'react'
import {
  Container,
  Row,
  Col
} from 'reactstrap'
import MainHeader from 'components/Headers/MainHeader.jsx'

class NotFound extends Component {

  render() {
    return (
      <>
        <MainHeader lines />
        <Container className='mt--5' fluid>
          <Row>
            <Col className='mt--7 px-lg-7 text-center'>
              <img
                src={require('assets/img/brand/tuikit/camion-barado.png')}
                alt='...'
                className='img-fluid'
              />
              <h1 className='mt-4'>Has perdido tu ruta</h1>
              <p>No hemos encontrado lo que estás buscando, prueba las opciones del menú lateral</p>
            </Col>
          </Row>
        </Container >
      </>
    )
  }
}

export default NotFound
