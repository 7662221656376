import React, {Component, useState, useEffect} from 'react'
import {Form, Button, Input, DatePicker} from 'antd'
import MapOrder from '../../Maps/MapOrder'
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {geocodeByAddress, getLatLng} from 'react-google-places-autocomplete';
import {connect} from "react-redux";

const {Item} = Form


function mapStateToProps({User}) {
    return {
        token: User.token
    }
}

const _handleSubmit = () => {


}

const Destination = () => {
    const [origin, setOrigin] = useState({lat: 4.65, lng: -74.1});
    const [originData, setOriginData] = useState(null);
    const [destination, setDestination] = useState({lat: 4.68, lng: -74.1});
    const [destinationData, setDestinationData] = useState(null);

    useEffect(() => {
        if (originData && origin.lat == 4.65) {
            let name = ""
            geocodeByAddress(`Bogotá, ${originData.value.structured_formatting.main_text}, Colombia`)
                .then(results => {
                    name = results[0].formatted_address
                    return getLatLng(results[0])
                })
                .then(({lat, lng}) => {
                        setOrigin({latitude: lat, longitude: lng, name})
                        localStorage.setItem('origin', JSON.stringify({latitude: lat, longitude: lng, name}))
                    }
                )

        }
        if (destinationData && destination.lat == 4.68) {
            let name = ""
            geocodeByAddress(`Bogotá, ${destinationData.value.structured_formatting.main_text}, Colombia`)
                .then(results => {
                    name = results[0].formatted_address
                    return getLatLng(results[0])
                })
                .then(({lat, lng}) => {
                        setDestination({latitude: lat, longitude: lng, name})
                        localStorage.setItem('destination', JSON.stringify({latitude: lat, longitude: lng, name}))
                    }
                )
        }
        if ((originData && origin.lat != 4.65) && (destinationData && destination.lat != 4.68)) {
            //KILOMETROS
            MapOrder(origin, destination)
        }
    })

    return (
        <Form onSubmit={_handleSubmit}>

            <Item label="Origen:">
                <GooglePlacesAutocomplete
                    apiKey="AIzaSyCPaJZ9c3nvSP6KR1qYyaAZ2XyYZmhPLM8"
                    selectProps={{
                        placeholder: 'Ingresa el origen',
                        origin,
                        onChange: setOriginData,
                    }}
                    autocompletionRequest={{
                        componentRestrictions: {
                            country: ['col']
                        }
                    }}
                />
            </Item>

            <Item label="Destino:">
                <GooglePlacesAutocomplete
                    apiKey="AIzaSyCPaJZ9c3nvSP6KR1qYyaAZ2XyYZmhPLM8"
                    selectProps={{
                        placeholder: 'Ingresa el destino',
                        destination,
                        onChange: setDestinationData,
                    }}
                    autocompletionRequest={{
                        componentRestrictions: {
                            country: ['col']
                        }
                    }}
                />
            </Item>

        </Form>
    )
}
const CreatedForm = Form.create({name: 'destination_basics_form'})(
    Destination
)
export default connect(mapStateToProps)(CreatedForm)
