import React, { Component } from 'react'
import { API } from '../../api'
import { Form, Input, Icon, Button, Select, Upload } from 'antd'
import { connect } from 'react-redux'
import { getPrefix, successAlert, errorAlert } from './formUtil'
import { convertBase64 } from '../../utils/image'
import { getRoleTraduction } from '../../utils/RoleConditions'
const { Option } = Select
const { Item } = Form

function mapStateToProps({ User }) {
  return {
    token: User.token
  }
}

const permitedRoles = ['driver', 'enterprise', 'client', 'cliententerprise']
const itemName = 'documento'

class LegalDocumentsForm extends Component {
  componentDidMount() {
    if (this.props.item) {
      this._loadEdit(this.props.item)
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.item._id !== this.props.item._id) {
  //     this._loadEdit(nextProps.item)
  //   }
  // }

  _loadEdit(obj) {
    let jsonValues = {}
    for (let key in obj) {
      jsonValues[key] = { value: obj[key] }
    }
    this.props.form.setFields(jsonValues)
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields(this._mainAction)
  }

  _mainAction = async (err, values) => {
    if (!err) {
      try {
        convertBase64(values.file.originFileObj, document => {
          this._executeAction({ ...values, document })
        })
      } catch (error) {
        this._executeAction(values)
      }
    }
  }

  _executeAction = document => {
    if (this.props.item) {
      const { _id } = this.props.item
      this._update({ _id, ...document })
    } else {
      this._register(document)
    }
  }

  _register = async document => {
    const { data } = await API.LegalDocuments.register(
      this.props.token,
      document
    )
    if (data._id) {
      successAlert(itemName)
      this._success(true)
    } else {
      errorAlert(data.error)
    }
  }

  _update = async document => {
    const { data } = await API.LegalDocuments.update(this.props.token, document)
    if (data.updated) {
      successAlert(itemName, true)
      this._success()
    } else {
      errorAlert(data.error)
    }
  }

  _success = resetFields => {
    this.props.onOk()
    if (resetFields) {
      this.props.form.resetFields()
    }
    if (this.props.updateData) {
      this.props.updateData()
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Form onSubmit={this.handleSubmit}>
        <Item>
          {getFieldDecorator('name', {
            rules: [{ required: true, message: 'El nombre es obligatorio' }]
          })(<Input prefix={getPrefix('edit')} placeholder='Nombre' />)}
        </Item>
        <Item>
          {getFieldDecorator('visible', {
            rules: [
              {
                required: true,
                message: 'Selecciona un rol',
                type: 'array'
              }
            ]
          })(
            <Select mode='multiple' placeholder='Visible para'>
              {permitedRoles.map((item, index) => (
                <Option key={index} value={item}>
                  {getRoleTraduction(item)}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        <Item>
          {getFieldDecorator('file', {
            getValueFromEvent: e => e.file,
            rules: [
              {
                required: this.props.item ? false : true,
                message: 'Selecciona un archivo'
              }
            ]
          })(
            <Upload.Dragger
              accept='application/pdf'
              type='picture'
              action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
            >
              <p className='ant-upload-drag-icon'>
                <Icon type='inbox' />
              </p>
              <p className='ant-upload-text'>Click o arrastra para subir</p>
              <p className='ant-upload-hint'>
                Unicamente se guarda el último documento subido.
              </p>
            </Upload.Dragger>
          )}
        </Item>
        <Button type='primary' htmlType='submit' block>
          {this.props.item ? 'Editar' : 'Registrar'}
        </Button>
      </Form>
    )
  }
}

const DocumentForm = Form.create({ name: 'document_form' })(LegalDocumentsForm)
export default connect(mapStateToProps)(DocumentForm)
