import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Button, Upload, Icon } from 'antd'
import { API } from '../../../api'
import { errorAlert, successAlert } from "../formUtil";
import Select from 'react-select';
import { convertBase64 } from "../../../utils/image";


function mapStateToProps({ User }) {
    return {
        token: User.token
    }
}

const { Item } = Form

class OrderDocumentsForm extends Component {
    constructor() {
        super();
        this.state = {
            typeloads: [{ value: 1, label: "aca" }],
            typeLoad: null,
            selectedOption: null
        }
    }

    componentDidMount() {
        this._list()
    }

    _handleSubmit = e => {
        e.preventDefault()
        this.props.form.validateFields(this._mainAction)
    }

    _mainAction = (err, values) => {
        if (!err) {
            this.props.submit(values)

            convertBase64(values.file.originFileObj, document => {
                localStorage.setItem('images', JSON.stringify([document]))
            })
            console.log(values)
        }
    }

    _list = async () => {
        const { data } = await API.Vehicle.getAllTypeLoad(this.props.token)
        if (data.length > 0) {

            let newData = []
            for (const typeLoad of data) {
                newData.push({
                    value: typeLoad.id,
                    label: typeLoad.name
                })
            }
            this.setState({ typeloads: newData })
            successAlert('Se han cargado los datos correctamente')
        } else {
            errorAlert(data.error)
        }
    }

    handleChangeInput = selectedOption => {
        this.setState({ typeload: selectedOption })
        localStorage.setItem('typeload', selectedOption.value)
    }
    componentWillMount() {

    }
    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <Form onSubmit={this._handleSubmit}>

                <Item label="Seleccione tipo de carga">
                    <Select
                        value={this.state.typeload}
                        onChange={this.handleChangeInput}
                        options={this.state.typeloads}
                    />
                </Item>
                <Item label="Subir imagenes">
                    <Item>
                        {getFieldDecorator('file', {
                            getValueFromEvent: e => e.file,
                            rules: [
                                {
                                    required: false,
                                    message: 'Selecciona un archivo'
                                }
                            ]
                        })(
                            <Upload.Dragger
                                accept='application/jpg;png'
                                type='picture'
                                action='https://api.tuikit.com/v1/user/image/upload/data'
                            >
                                <p className='ant-upload-drag-icon'>
                                    <Icon type='inbox' />
                                </p>
                                <p className='ant-upload-text'>Click o arrastra para subir</p>
                                <p className='ant-upload-hint'>
                                    Unicamente se guarda el último documento subido.
                                </p>
                            </Upload.Dragger>
                        )}
                    </Item>
                </Item>
                <Button type='primary' htmlType='submit' block>
                    {this.props.item ? 'Editar' : 'Registrar'}
                </Button>
            </Form>
        )
    }
}

const CreatedForm = Form.create({ name: 'order_documents_form' })(
    OrderDocumentsForm
)
export default connect(mapStateToProps)(CreatedForm)
