import React, { Component } from 'react'
import { Card } from 'antd'
import { API } from '../../api/index'
import { connect } from 'react-redux'
import { Pie } from 'react-chartjs-2'

function mapStateToProps({ User }) {
  return {
    token: User.token
  }
}

const labels = [
  'Pendiente',
  'Asignado',
  'En proceso',
  'Finalizado',
  'Cancelado'
]
const dataSetsConfig = {
  backgroundColor: [
    '#ffd591',
    '#91d5ff',
    '#ffe58f',
    '#b7eb8f',
    '#ffa39e',
  ],
  hoverBackgroundColor: [
    '#fa8c16',
    '#1890ff',
    '#faad14',
    '#52c41a',
    '#f5222d',
  ]
}

class OrderStatus extends Component {

  state = {
    data: {}
  }

  componentWillMount() {
    this.getData()
  }

  getData = async () => {
    const { data } = await API.Order.countStatusOrders(this.props.token)
    this.setState({
      data: {
        labels,
        datasets: [{
          data,
          ...dataSetsConfig
        }]
      }
    })
  }

  render() {
    return (
      <>
        <Card
          title='Estado pedidos'
          extra={
            <small className='text-muted'>Info extra</small>
          }
        >
          <div className='reset-padding d-flex justify-content-center'>
            <div className='w-50'>
              {this.state.data.labels &&
                <Pie
                  data={this.state.data}
                  options={{
                    maintainAspectRatio: true
                  }}
                />
              }
            </div>
          </div>
        </Card>
      </>
    )
  }
}

export default connect(mapStateToProps)(OrderStatus)
