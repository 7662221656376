import React from 'react'
import { Tooltip } from 'antd'

const IdTooltip = ({ id }) => {
  return (
    <Tooltip title={id}>
      {id.substr(18)}
    </Tooltip>
  )
}

IdTooltip.defaultProps = {
  id: ''
}

export default IdTooltip
