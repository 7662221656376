import React from 'react'
import { Tag } from 'antd'

const StatusTag = ({ status }) => {
  let color
  let texto
  switch (status) {
    case 0:
      color = 'orange'
      texto = 'Pendiente'
      break
    case 1:
      color = 'blue'
      texto = 'Asignado'
      break
    case 2:
      color = 'gold'
      texto = 'En proceso'
      break
    case 3:
      color = 'green'
      texto = 'Finalizado'
      break
    case 4:
      color = 'red'
      texto = 'Cancelado'
      break
    default:
      break
  }
  return <Tag color={color}>{texto}</Tag>
}

StatusTag.defaultProps = {
  status: ''
}

export default StatusTag
