import React, { Component } from 'react'
import getColumnSearchProps from '../../utils/filterTable'
import { Avatar, Button, Tag } from 'antd'
import TableList from './TableList'
import { Link } from 'react-router-dom'
import UserIsActiveTag from '../Tags/UserIsActiveTag'
import UserForm from '../Forms/UserForm'


const sectionName = 'Empresas'

class EnterprisesTable extends Component {

  state = {
    searchText: '',
    filter: null
  }

  _updateState = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  handleChange = filters => {
    this.setState({
      filter: filters
    })
  }

  _mainAction = item => {
    return (
      <Link
        to={`/main/detalle/${item._id}`}
      >
        <Button block>
          <i className='fas fa-search' />
        </Button>
      </Link>
    )
  }

  render() {
    const columns = [
      {
        key: 'identification',
        title: 'Nit',
        dataIndex: 'identification',
        ...getColumnSearchProps('identification', this.state.searchText, this._updateState),
      },
      {
        key: 'image',
        dataIndex: 'image',
        width: 50,
        render: image => {
          return (
            <Avatar
              icon='user'
              src={image}
            />
          )
        },
      },
      {
        key: 'name',
        title: 'Nombre',
        dataIndex: 'name',
        ...getColumnSearchProps('name', this.state.searchText, this._updateState),
      },
      {
        key: 'cellPhone',
        title: 'Telefono',
        dataIndex: 'cellPhone',
        ...getColumnSearchProps('cellPhone', this.state.searchText, this._updateState)
      },
      {
        key: 'email',
        title: 'Email',
        dataIndex: 'email',
        ...getColumnSearchProps('email', this.state.searchText, this._updateState)
      },
      {
        key: 'vehicles',
        title: 'Vehiculos',
        dataIndex: 'vehicles',
        ...getColumnSearchProps('vehicles', this.state.searchText, this._updateState),
        render: vehicles => {
          if (vehicles.length > 0) {
            return (
              vehicles.map((vehicle, index) => (
                <Link
                  to={`/main/vehiculo/${vehicle.licensePlate}`}
                  key={index}
                >
                  <Tag key={vehicle._id} className='m-1'>
                    {vehicle.licensePlate}
                  </Tag>
                </Link>
              ))
            )
          } else {
            return (
              <Tag className='m-1'>
                No registrado
            </Tag>
            )
          }
        }
      },
      {
        key: 'isActive',
        title: 'Activo',
        dataIndex: 'isActive',
        filters: [
          { text: 'Activo', value: true },
          { text: 'Inactivo', value: false }
        ],
        onFilter: (value, record) => record.isActive === value,
        render: isActive => {
          return (
            <UserIsActiveTag isActive={isActive} />
          )
        }
      },
      {
        title: 'Detalle',
        key: 'operation',
        fixed: 'right',
        width: 100,
        render: item => this._mainAction(item),
      }
    ]

    return (
      <TableList
        title={sectionName}
        data={this.props.data}
        columns={columns}
        filter={this.handleChange}
      >
        <UserForm rol='enterprise' updateData={this.props.updateData} />
      </TableList>
    )
  }
}

export default EnterprisesTable