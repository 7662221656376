import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Row,
  Col,
  Container,
  Card,
  CardBody
} from 'reactstrap'
import { API } from '../api'
import { Actions } from '../actions'
import IndexNavbar from 'components/Navbars/IndexNavbar'
import '../../node_modules/aos/dist/aos.css'
import AOS from 'aos'

function mapStateToProps({ User }) {
  return {
    username: User.email,
    password: User.password
  }
}

class Splash extends Component {
  componentDidMount() {
    this._getCredentials()
    AOS.init()
  }


  async _getCredentials() {
    if (this.props.username && this.props.password) {
      const { data } = await API.Account.createToken(this.props.username, this.props.password)
      if (data.token) {
        const user = {
          username: this.props.username,
          password: this.props.password,
          token: data.token
        }
        Actions.User(user, this.props.dispatch, 'SET')
        Actions.Profile(data.user, this.props.dispatch, 'SET')
      } else {
        this.props.dispatch({
          type: 'LOGOUT'
        })
        this.props.history.replace('/auth/login')
      }
    } else {
      this.props.history.replace('/')
    }
  }

  render() {
    // AOS.refresh()
    return (
      <div className='main-content bg-white'>
        <IndexNavbar />
        <div className='bg-gradient-primary py-9'>
          <Container>
            <div className='header-body text-center'>
              <Row>
                <Col md='6' className='mt-4 d-flex flex-column justify-content-center'>
                  <img
                    style={{ maxWidth: '35%', margin: 'auto' }}
                    alt='...'
                    className='img-fluid my-0'
                    src={require('assets/img/brand/tuikit/logo-inverso.png')}
                    data-aos='fade-right'
                    data-aos-duration='1500'
                    data-aos-anchor-placement='center-center'
                  />
                  <div
                    data-aos='fade-zoom-in'
                    data-aos-duration='1500'
                  >
                    <p className='text-light mt-4'>
                      Somos una plataforma dedicada al servicio de transporte de carga terrestre en todo el territorio nacional, garantizamos el movimiento de tu mercancía hasta el destino final.
                        <br />
                      Contamos con tecnología avanzada para transportadores y generadores de carga, quienes tendrán facilidad y confianza al utilizar nuestra App.
                      </p>
                    <div className='text-center'>
                      <Button color='dark'>
                        <i className='fab fa-google-play' />{' '}
                        Google play
                        </Button>{' '}
                      <Button color='dark'>
                        <i className='fab fa-apple' />{' '}
                        App store
                        </Button>
                    </div>
                  </div>
                </Col>
                <Col md='6' className='mt-4'>
                  <img alt='...' className='img-fluid' src={require('assets/img/theme/img1.png')} />
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className='pt-9 text-dark'>
          <Row>
            <Col md='8' className='mt-4 d-flex flex-column justify-content-center px-md-5'>
              <img
                alt='...'
                className='img-fluid'
                src={require('assets/img/theme/img2.png')}
                data-aos='fade-up'
                data-aos-duration='1500'
                data-aos-anchor-placement='center-center'
              />
            </Col>
            <Col
              md='4'
              className='mt-6 mt-md-4 d-flex flex-column justify-content-center text-justify'
            >
              <div
                data-aos='fade-zoom-in'
                data-aos-anchor-placement='bottom-bottom'
                data-aos-duration='1500'
              >
                <h3 className='text-primary text-center'>La app gratuita que llega a facilitar tu tiempo</h3>
                <p>Tú como transportador con Tuikit podrás agendar, ofertar y localizar tus viajes desde cualquier lugar del país en solo 3 pasos. </p>
                <div className='mt-4'>
                  <div className='icon icon-shape bg-primary text-white rounded-circle'>
                    1
                    </div>
                  &nbsp;
                  Descarga la app
                  </div>
                <div className='mt-4'>
                  <div className='icon icon-shape bg-primary text-white rounded-circle'>
                    2
                    </div>
                  &nbsp;
                  Registrate
                  </div>
                <div className='mt-4'>
                  <div className='icon icon-shape bg-primary text-white rounded-circle'>
                    3
                    </div>
                  &nbsp;
                  Carga y gana
                  </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className='py-9 text-dark'>
          <Row>
            <Col
              data-aos='fade-zoom-in'
              data-aos-anchor-placement='bottom-bottom'
              data-aos-duration='1500'
              sm='12'
              md='12'
              lg='4'
              className='mt-4 d-flex flex-column justify-content-center align-items-center text-justify'
            >
              <h3 className='text-primary text-center'>Nos adecuamos a tu necesidad</h3>
              <p>Queremos que tengas la confianza y la facilidad al solicitar un servicio de carga en el menor tiempo posible, ofreciendo así mismo el monitoreo de tu carga en tiempo real.</p>
              <p>Contamos con póliza de transporte con el objetivo de que la mercancía esté amparada de principio a fin.</p>
              <p>Aquí puedes elegir el tipo de mercancía que desees transportar a nivel nacional y urbano.</p>
            </Col>
            <Col sm='5' md='5' lg='4' style={{ zIndex: '2' }} className='mt-4 d-flex justify-content-center align-items-center'>
              <img
                alt='...'
                style={{ position: 'relative', left: '50px' }}
                className='img-fluid d-none d-sm-block'
                src={require('assets/img/theme/img3.png')}
                data-aos='fade-right'
                data-aos-duration='1500'
                data-aos-anchor-placement='center-center'
              />
            </Col>
            <Col
              data-aos='fade-zoom-in'
              data-aos-anchor-placement='bottom-bottom'
              data-aos-duration='1500'
              className='mt-4 d-flex justify-content-center align-items-center'
            >
              <div>
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        Carga a granel
                        </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <CardBody>
                        Carga especial
                        </CardBody>
                    </Card>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        Carga peligrosa
                        </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <CardBody>
                        Mudanzas
                        </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        <div className='bg-gradient-primary pt-6 overflow-hidden'>
          <Container>
            <Row
            >
              <Col
                md='12' lg='8'
                className='mt-4 d-flex align-items-end order-2 order-lg-0'
              >
                <div>
                  <img
                    data-aos='fade-right'
                    data-aos-duration='1500'
                    data-aos-anchor-placement='bottom-bottom'
                    alt='...'
                    className='img-fluid'
                    src={require('assets/img/theme/bg-img4.png')}
                  />
                  <img style={{ width: '35%', position: 'absolute', left: '25%' }} alt='...' className='img-fluid' src={require('assets/img/theme/img4.png')} />
                </div>
              </Col>
              <Col className='mt-4 d-flex flex-column justify-content-center'>
                <h3 className='text-white text-center'>Ventajas de preferir tuikit</h3>
                <ul className='text-light'>
                  <li> Te evitas salir en búsqueda de un vehículo que se acomode a tu necesidad.</li>
                  <li> Tienes la facilidad para agendar la recogida y entrega de la mercancía.</li>
                  <li> Podrás tomar varios servicios en un mismo día.</li>
                  <li> Contarás con una póliza con el respaldo de Seguros del Estado.</li>
                  <li> Siempre tendrás la posibilidad de solicitar ayuda por medio de nuestra App.</li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className='py-9 text-dark'>
          <Row>
            <Col md='6' className='mt-4 d-flex flex-column justify-content-center px-md-5'>
              <img alt='...' className='img-fluid' src={require('assets/img/theme/bg-img5.png')} />
              <img
                alt='...'
                style={{ width: '40%', position: 'absolute', left: '30%' }}
                className='img-fluid'
                src={require('assets/img/theme/img5.png')}
                data-aos='fade-up'
                data-aos-duration='1500'
                data-aos-anchor-placement='center-center'
              />
            </Col>
            <Col
              data-aos='fade-zoom-in'
              data-aos-duration='1500'
              data-aos-anchor-placement='bottom-bottom'
              md='6'
              className='mt-6 mt-md-4 d-flex flex-column justify-content-center text-justify'
            >
              <h3 className='text-primary text-center'>Origen</h3>
              <p>
                  Nace ante la necesidad del incremento en el transporte de carga en Colombia. Según la Encuesta Empresarial Trimestral (EET), realizada por Colfecar, entre Enero y Septiembre de 2018 se registró un alza en las toneladas movilizadas del 2,27% y en la facturación este aumento fue de 1,90% respecto al año anterior.
                <br/>Nuestro conocimiento y experiencia nos permitió desarrollar esta plataforma de última generación que por medio de algoritmos brinda eficiencia tecnológica y operativa a los usuarios en el transporte de carga.
              </p>
            </Col>
          </Row>
        </Container>
        <div className='bg-gradient-primary py-6 overflow-hidden'>
          <Container>
            <Row>
              <Col className='mt-4 d-flex flex-column justify-content-center'>
                <h3 className='text-white text-center'>Nuestro portafolio de vehiculos</h3>
                <ul className='text-light'>
                  <li>Camioneta pickup y carry</li>
                  <li>Camioneta sencilla</li>
                  <li>Camioneta</li>
                  <li>Camioneta turbo</li>
                  <li>Turbo camion</li>
                  <li>Camión sencillo</li>
                  <li>Camión dobletroque 3 ejes</li>
                  <li>Mini tractocamión 2S1</li>
                  <li>Mini tractocamión 2S2</li>
                  <li>Mini tractocamión 2S3</li>
                  <li>Tractocamión 3S1</li>
                  <li>Tractocamión 3S2</li>
                  <li>Tractocamión 3S3</li>
                </ul>
              </Col>
              <Col
                md='12'
                lg='8'
                className='mt-9 mt-lg-4 d-flex align-items-end'
              >
                <div>
                  <img
                    data-aos='fade-left'
                    data-aos-duration='1500'
                    alt='...'
                    className='img-fluid'
                    src={require('assets/img/theme/bg-img6.png')}
                  />
                  <img style={{ height: '160%', position: 'absolute', left: '35%', top: '-32%' }} alt='...' className='img-fluid' src={require('assets/img/theme/img6.png')} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className='text-center text-dark pt-9'>
          <Row>
            <Col md='6' className='mt-4 px-md-5'>
              <img
                alt='...'
                className='img-fluid'
                src={require('assets/img/theme/img7.png')}
                data-aos='fade-up'
                data-aos-duration='1500'
                data-aos-anchor-placement='center-center'
              />
            </Col>
            <Col
              data-aos='fade-zoom-in'
              data-aos-duration='1500'
              data-aos-anchor-placement='bottom-bottom'
              md='6'
              className='mt-6 mt-md-4 d-flex flex-column justify-content-center'
            >
              <img alt='...' style={{ maxWidth: '35%', margin: 'auto' }} className='img-fluid my-2' src={require('assets/img/brand/tuikit/logo.png')} />
              <h3 className='text-primary mt-4'>Trabaja con nosotros</h3>
              <p>
                Queremos que como transportador trabajes tranquilamente desde tu celular... Nuestra plataforma te facilita programar cargas para el siguiente día o la posibilidad de ofertar tus vehículos sin ningún costo.
              </p>
              <p>
                ¡Registrate y animate a ganar más!
                </p>
              <div>
                <Button color='dark'>
                  <i className='fab fa-google-play' />{' '}
                  Google play
                  </Button>{' '}
                <Button color='dark'>
                  <i className='fab fa-apple' />{' '}
                  App store
                  </Button>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className='text-center text-dark py-9'>
          <Row>
            <Col xs='4' md='2' className='mt-4 d-flex flex-column align-items-center'>
              <a
                className='font-weight-bold ml-1'
                href='https://wa.link/s4olxx'
                target='_blank'
              >
                <i className='mb-3 fa-2x text-light fas fa-phone' />
                <br />
                3138053222
                </a>
            </Col>
            <Col xs='4' md='2' className='mt-4 d-flex flex-column align-items-center'>
              <a
                className='font-weight-bold ml-1'
                href='mailto: contacto@tuikit.com'
                target='_blank'
              >
                <i className='mb-3 fa-2x text-light fas fa-mobile' />
                <br />

                Soporte
                </a>
            </Col>
            <Col xs='4' md='2' className='mt-4 d-flex flex-column align-items-center'>
              <a
                className='font-weight-bold ml-1'
                href='https://www.facebook.com/tuikit.tuikit'
                target='_blank'
              >
                <i className='mb-3 fa-2x text-light fab fa-facebook-f' />
                <br />
                Facebook
                </a>
            </Col>
            <Col xs='4' md='2' className='mt-4 d-flex flex-column align-items-center'>
              <a
                className='font-weight-bold ml-1'
                href='https://www.instagram.com/p/CBlK1mqp1hN/?igshid=1vrb3t7ek7th2'
                target='_blank'
              >
                <i className='mb-3 fa-2x text-light fab fa-instagram' />
                <br />
                Instagram
                </a>
            </Col>
            <Col xs='4' md='2' className='mt-4 d-flex flex-column align-items-center'>
              <a
                className='font-weight-bold ml-1'
                href='https://twitter.com/TUIKIT2'
                target='_blank'
              >
                <i className='mb-3 fa-2x text-light fab fa-twitter' />
                <br />
                Twitter
                </a>
            </Col>
            <Col xs='4' md='2' className='mt-4 d-flex flex-column align-items-center'>
              <a
                className='font-weight-bold ml-1'
                href='https://www.youtube.com/channel/UCh6T5WHO-A5sSyd9fY-ChzQ'
                target='_blank'
              >
                <i className='mb-3 fa-2x text-light fab fa-youtube' />
                <br />
                Youtube
                </a>
            </Col>
          </Row>
        </Container>
      </div >
    )
  }
}

export default connect(mapStateToProps)(Splash)
