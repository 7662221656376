import React, { Component } from 'react'
import { connect } from 'react-redux'
import TableList from './TableList'
import getColumnSearchProps from '../../utils/filterTable'
import IdTooltip from '../Tags/IdTooltip'
import moment from 'moment'
import ModalForm from '../Modals/ModalForm'
import PqrForm from '../Forms/PqrForm'
import PqrStatusBadge from '../Badges/PrqStatusBadge'
import CreatePqrForm from '../Forms/CreatePqrForm'

function mapStateToProps({ User }) {
  return {
    token: User.token
  }
}

const sectionName = 'Pqr'

class PqrsTable extends Component {

  state = {
    searchText: '',
    filter: null
  }

  _updateState = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  handleChange = filters => {
    this.setState({
      filter: filters
    })
  }

  _mainAction = item => {
    return (
      <ModalForm
        modalId='editarPeticion'
        icon='fas fa-reply'
        title={`Responder ${sectionName}`}
        disabled={item.status === 'Solucionado'}
        block
        padding
        isForm
      >
        <PqrForm
          item={item}
          updateData={this.props.updateData}
        />
      </ModalForm>
    )
  }

  render() {
    const columns = [
      {
        key: '_id',
        title: 'Id',
        dataIndex: '_id',
        width: 100,
        ...getColumnSearchProps('_id', this.state.searchText, this._updateState, '_id'),
        render: _id => {
          return (
              _id
              ? <IdTooltip id={_id} />
              : 'N/A'
          )
        }
      },
      {
        key: 'createDate',
        title: 'Fecha de creacion',
        dataIndex: 'createDate',
        render: createDate => (
          <span>
            {moment(createDate).format('LLL')}
          </span>
        )
      },
      {
        key: 'name',
        title: 'Agente Soporte',
        dataIndex: 'userId',
        ...getColumnSearchProps('name', this.state.searchText, this._updateState, 'name'),
        render: user => user.name
      },
      {
        key: 'description',
        title: 'Descripción',
        dataIndex: 'description',
        ...getColumnSearchProps('description', this.state.searchText, this._updateState),
      },
      {
        key: 'response',
        title: 'Respuesta',
        dataIndex: 'response',
        ...getColumnSearchProps('response', this.state.searchText, this._updateState),
      },

      {
        key: 'createUpdate',
        title: 'Fecha de actualización',
        dataIndex: 'createUpdate',
        render: createUpdate => (
            <span>
            {moment(createUpdate).format('LLL')}
          </span>
        )
      },
      {
        key: 'status',
        title: 'Estado',
        dataIndex: 'status',
        filters: [{ text: 'En proceso', value: 'EnProceso' }, { text: 'Pendiente', value: 'Pendiente' }, { text: 'Solucionado', value: 'Solucionado' }],
        onFilter: (value, record) => record.status.toString().includes(value),
        render: status => {
          return (
            <PqrStatusBadge status={status} />
          )
        }
      },
      {
        title: 'Acción',
        key: 'operation',
        fixed: 'right',
        width: 100,
        render: item => this._mainAction(item),
      }
    ]
    return (
      <TableList
        title={sectionName}
        data={this.props.data}
        columns={columns}
        filter={this.handleChange}
      >
        <CreatePqrForm updateData={this.props.updateData} />
      </TableList>
    )
  }
}

export default connect(mapStateToProps)(PqrsTable)