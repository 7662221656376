import React, { Component } from 'react'
import { API } from '../../api'
import { connect } from 'react-redux'
import { Form, Input, Button, Radio, Select } from 'antd'
import { getPrefix, successAlert, errorAlert } from './formUtil'
const { TextArea } = Input
const { Item } = Form
const { Option } = Select

function mapStateToProps({ User }) {
  return {
    token: User.token
  }
}

const itemName = 'pqr'

class CreatePqrForm extends Component {

  state = {
    users: []
  }

  componentWillMount() {
    this.setUsers()
  }

  setUsers = async () => {
    const { data } = await API.User.getAllWithoutRole(this.props.token)
    this.setState({
      users: data
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields(this.register)
  }

  register = async (err, values) => {
    if (!err) {
      const { data } = await API.Pqr.register(this.props.token, values)
      if (data._id) {
        successAlert(itemName)
        this.success(true)
      } else {
        errorAlert(data.errors)
      }
    }
  }

  success = resetFields => {
    this.props.onOk()
    if (resetFields) {
      this.props.form.resetFields()
    }
    if (this.props.updateData) {
      this.props.updateData()
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { users } = this.state
    return (
      <Form onSubmit={this.handleSubmit}>
        <Item>
          {getFieldDecorator('subject', {
            rules: [{ required: true, message: 'El asunto es obligatorio' }]
          })(
            <Input
              prefix={getPrefix('mail')}
              placeholder='Asunto'
            />
          )}
        </Item>
        <Item>
          {getFieldDecorator('description', {
            rules: [{ required: true, message: 'La descripción es obligatoria' }]
          })(
            <TextArea
              prefix={getPrefix('edit')}
              placeholder='Descripción'
            />
          )}
        </Item>
        <Item>
          {getFieldDecorator('userId', {
            rules: [{ required: true, message: 'El usuario es obligatorio' }]
          })(
            <Select
              prefix={getPrefix('user')}
              placeholder='Usuario'
              showSearch
              optionFilterProp='children'
            >
              {users.map((user, index) => (
                <Option key={`${user._id}${index}`} value={user._id}>
                  {`${user.identification} - ${user.name}`}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        <Item>
          {getFieldDecorator('status', {
            rules: [{ required: true, message: 'El estado es obligatorio' }]
          })(
            <Radio.Group>
              <Radio.Button value='Solucionado'>Solucionado</Radio.Button>
              <Radio.Button value='EnProceso'>En proceso</Radio.Button>
              <Radio.Button value='Pendiente'>Pendiente</Radio.Button>
            </Radio.Group>
          )}
        </Item>
        <Button type='primary' htmlType='submit' block>
          Responder
        </Button>
      </Form>
    )
  }
}

const createdForm = Form.create({ name: 'pqr_create_form' })(CreatePqrForm)
export default connect(mapStateToProps)(createdForm)