import { notification } from 'antd'
export const convertBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export const beforeUpload = file => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    notification.open({
      type: 'error',
      message: 'Error',
      description: 'El archivo debe ser formato PNG o JPG'
    })
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    notification.open({
      type: 'error',
      message: 'Error',
      description: 'El tamaño de la imagen supera los 2MB'
    })
  }
  return isJpgOrPng && isLt2M
}

export const beforeUploadPdf = file => {
  const isPdf = file.type === 'application/pdf'
  if (!isPdf) {
    notification.open({
      type: 'warning',
      message: 'Advertencia',
      description: 'El archivo debe ser formato PDF, el documento anterior no será cargado'
    })
  }
  return isPdf
}