import React from 'react'

const ImagesOverviewer = ({ images, supportDocument }) => {
  return (
    <div className='card-columns'>
      {supportDocument && (
        <div className='card mb-4'>
          <img
            className='card-img-top'
            alt='Documento de soporte'
            src={supportDocument}
          />
          <div className='card-body'>
            <h4 className='card-title'>Documento de soporte</h4>
          </div>
        </div>
      )}
      {images.map((img, index) => {
        if (img.length > 0) {
          return (
            <div className='card' key={index}>
              <img className='card-img-top' alt='...' src={img} />
            </div>
          )
        } else {
          return undefined
        }
      })}
    </div>
  )
}

ImagesOverviewer.defaultProps = {
  images: []
}

export default ImagesOverviewer
