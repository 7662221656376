import React, { Component } from 'react'
import { Form, Icon, Input, Button } from 'antd'

class SendTokenForm extends Component {

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        if (this.props.changePassword) {
          this.props.changePassword(values)
        }
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item>
          {getFieldDecorator('token', {
            rules: [{ required: true, message: 'El token enviado al correo es obligatorio' }]
          })(
            <Input
              prefix={<Icon type='safety' style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder='Token'
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('newPassword', {
            rules: [{ required: true, message: 'La nueva contraseña es obligatoria' }]
          })(
            <Input
              prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder='Nueva contraseña'
              type='password'
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button htmlType='submit' type='primary' block>
            Restaurar
             </Button>
        </Form.Item>
      </Form>
    )
  }
}

const ChangePassword = Form.create({ name: 'form_change_password' })(SendTokenForm)

export default ChangePassword
