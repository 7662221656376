import Axios from 'axios'
import { URL_USER } from './Constant'

class Driver {
  async getDrivers(token, haveEnterprise = false) {
    const response = await Axios.get(
      `${URL_USER}/withenterprise/${haveEnterprise}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    return response
  }

  async getEnterpriseDrivers(token, enterpriseId) {
    const response = await Axios.get(
      `${URL_USER}/withenterpriseid/${enterpriseId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    return response
  }
}

export default new Driver()
