import React from 'react'
import { connect } from 'react-redux'
import { List, Avatar, Button, notification } from 'antd'
import { API } from '../../api'
import { errorAlert } from './formUtil'

const { Item } = List
const { Meta } = Item

function mapStateToProps({ User }) {
  return {
    user: User
  }
}

const ClientAcceptOfferForm = ({ user, onOk, order }) => {
  const handleClick = async idOffer => {
    const { _id } = order
    const { data } = await API.ClientOrder.takeOffer(_id, idOffer, user.token)
    if (data.updated) {
      onOk()
      _successAlert()
    } else {
      errorAlert(data.error)
    }
  }

  const _successAlert = () => {
    notification.open({
      type: 'success',
      message: 'Hecho',
      description:
        'Has aceptado la oferta de un transportador, continua el proceso realizando el pago del mismo'
    })
  }

  return (
    <>
      <div className='text-muted mb-4'>
        <small>
          Aquí recibe las contraofertas de los conductores, si algún
          transportador acepta el precio sugerido por usted, el servicio se
          actualizará automaticamente.
        </small>
      </div>
      <List
        itemLayout='horizontal'
        dataSource={order.offers}
        renderItem={item => (
          <Item
            actions={[
              <Button type='primary' onClick={() => handleClick(item._id)}>
                Aceptar
              </Button>
            ]}
          >
            <Meta
              avatar={<Avatar src={item.driver.image} />}
              title={<a href='https://ant.design'>{item.driver.name}</a>}
              description={item.description}
            />
            <div className='text-success'>${item.valueProfit}</div>
          </Item>
        )}
      ></List>
    </>
  )
}

export default connect(mapStateToProps)(ClientAcceptOfferForm)
