import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Form, Button, Input, Upload, message, Radio, Icon} from 'antd'

const {Item} = Form

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

function mapStateToProps({User}) {
    return {
        token: User.token
    }
}

class VehicleBasicsForm extends Component {
    constructor() {
        super()
        this.state = {
            numLoadingCapacity: undefined,
            imageUrl: undefined,
            loader: false
        }
    }

    _handleSubmit = e => {
        e.preventDefault()
        this.props.form.validateFields(this._mainAction)
    }

    _mainAction = (err, values) => {
        if (!err) {
            values.images = this.state.imageUrl
            this.props.submit(values)
        }
    }

    onChange = e => {
        this.setState({numLoadingCapacity: e.target.value})
    }

    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({loader: true})
            return
        }
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageUrl: imageUrl,
                }),
            )
        }
        this.setState({loader: false})
    }

    render() {
        const {getFieldDecorator} = this.props.form
        const {imageUrl, loader} = this.state

        return (
            <Form onSubmit={this._handleSubmit}>
                <Item label='Imágen principal'>
                    {getFieldDecorator('images', {
                        rules: [
                            {
                                required: true,
                                message: 'Selecciona una imagen',
                            }
                        ]
                    })(
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="https://api.tuikit.com/v1/user/image/upload/data"
                            beforeUpload={beforeUpload}
                            onChange={this.handleChange}
                        >
                            {loader ?
                                <Icon type="loading" style={{fontSize: '16px', color: '#08c'}} theme="outlined"/> : ''}
                            {!loader && imageUrl == undefined ?
                                <Icon type="camera" style={{fontSize: '16px', color: '#08c'}} theme="outlined"/> : ''}
                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{width: '100%'}}/> : ''}
                        </Upload>
                    )}
                </Item>
                <Item label='Placa'>
                    {getFieldDecorator('licensePlate', {
                        rules: [{required: true, message: 'La placa es obligatoria'}]
                    })(<Input placeholder='ABC123' maxLength={6}/>)}
                </Item>
                <Item label='Modelo'>
                    {getFieldDecorator('model', {
                        rules: [{required: true, message: 'EL modelo es obligatorio'}]
                    })(<Input placeholder='1985' maxLength={4}/>)}
                </Item>
                <Item label='Capacidad de carga'>
                    {getFieldDecorator('loadingCapacity', {
                        rules: [{required: true, message: 'La capacidad de carga es obligatoria'}]
                    })(<Input placeholder='2000'/>)}
                </Item>
                <Item>
                    {getFieldDecorator('loadingCapacityNum', {
                        rules: [{required: true, message: 'Selecciona una unidad de medida'}]
                    })(
                        <Radio.Group onChange={this.onChange} value={this.state.numLoadingCapacity}>
                            <Radio value={' Kg'}>KG</Radio>
                            <Radio value={' Tn'}>TN</Radio>
                        </Radio.Group>
                    )}
                </Item>
                <Button type='primary' htmlType='submit' block>
                    Siguiente
                </Button>
            </Form>
        )
    }
}

const CreatedForm = Form.create({name: 'vehicle_basics_form'})(
    VehicleBasicsForm
)
export default connect(mapStateToProps)(CreatedForm)
