/*global google*/
import React from 'react'

export default function (dataOrigin, dataDestination) {

    const getDirections = async (origin, destination) => {
        const directionsService = new google.maps.DirectionsService()
        const response = await directionsService.route(
            {
                origin: {lat: origin.latitude, lng: origin.longitude},
                destination: {lat: destination.latitude, lng: destination.longitude},
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC
            }, (result,status,callback) => {
                if (status == 'OK') {
                    let km = result.routes[0].legs[0].distance.value
                    km = km / 1000
                    localStorage.setItem('km', `${km}`)
                } else {
                    console.error(result)
                }
            }
        )
    }

    return getDirections(dataOrigin, dataDestination)
}
