import Axios from 'axios'
import { URL_USER } from './Constant'

class Dashboard {
  async getCardCounts (token) {
    const response = await Axios.get(`${URL_USER}/detailscount`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response
  }

}

export default new Dashboard()
