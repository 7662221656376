import React, { Component } from 'react'
import {
  Card,
  CardBody,
  Col
} from 'reactstrap'
import { connect } from 'react-redux'
import { API } from '../api'
import { Actions } from '../actions'
import { Form, Icon, Input, notification, Button } from 'antd'

class LoginForm extends Component {
  state = {
    username: '',
    password: '',
    errors: {}
  }

  _updateState = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  _changeText = (event) => {
    const { name, value } = event.target
    this._updateState(name, value)
  }

  _redirect = (route) => {
    this.props.history.replace(route)
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const { data } = await API.Account.createToken(values.username, values.password)
        if (data.token) {
          const user = {
            username: values.username,
            password: values.password,
            token: data.token,
          }
          Actions.User(user, this.props.dispatch, 'SET')
          Actions.Profile(data.user, this.props.dispatch, 'SET')
        } else {
          this.props.dispatch({
            type: 'LOGOUT'
          })
          notification.open({
            type: 'error',
            message: 'Error',
            description: data.error
          })
        }
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <>
        <Col sm='8' lg='10' xl='8'>
          <Card className='shadow border-0'>
            <CardBody className='px-lg-5 py-lg-5'>
              <div className='text-primary text-center pb-4'>
                <h1 className=''>Bienvenido!</h1>
                <p className='text-lead text-light'>
                  Bienvenido al portal administrativo de Tuikit.
                </p>
              </div>
              <Form onSubmit={this.handleSubmit} role='form'>
                <Form.Item>
                  {getFieldDecorator('username', {
                    rules: [{ required: true, message: 'El usuario es obligatorio!' }],
                  })(
                    <Input
                      prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder='Usuario'
                    />,
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'La contraseña es obligatoria!' }],
                  })(
                    <Input
                      type='password'
                      placeholder='Contraseña'
                      prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                    />,
                  )}
                </Form.Item>
                <Form.Item>
                  <Button htmlType='submit' type='primary' block>
                    Iniciar sesión
                  </Button>
                </Form.Item>
                <Button onClick={this._redirect.bind(this, '/reset/resetPassword')} block>
                  Recuperar contraseña
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    )
  }
}

const Login = Form.create({ name: 'normal_login' })(connect(null)(LoginForm))

export default Login
