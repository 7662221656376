import Axios from 'axios'
import { URL_VEHICLE, URL_TYPELOAD, URL_VEHICLE_TYPE, URL_API } from './Constant'

class Vehicle {

  async getEnterpriseVehicles(token, enterpriseId) {
    const response = await Axios.get(`${URL_VEHICLE}/all/${enterpriseId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response
  }

  async getDetail(token, licensePlate) {
    const response = await Axios.get(`${URL_VEHICLE}/detail/${licensePlate}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response
  }

  async getAllTypeLoad(token) {
    const response = await Axios.get(`${URL_TYPELOAD}/backoffice/all`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response
  }

  async getAllTypeVehicle(token) {
    const response = await Axios.get(`${URL_VEHICLE_TYPE}/all`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response
  }


  async update(token, vehicle) {
    const response = await Axios.put(
      `${URL_VEHICLE}/update/${vehicle._id}`,
      vehicle,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    return response
  }

  async create(token, vehicle) {
    const response = await Axios.post(`${URL_VEHICLE}/create`, vehicle, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response
  }

  async typeTruck(token) {
    const response = await Axios.get(`${URL_API}/v1/typevehicle/all`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response.data
  }

  async typeBrand(token) {
    const response = await Axios.get(`${URL_API}/v1/brand/vehicle/all`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response.data
  }

  async bodyWork(token) {
    const response = await Axios.get(`${URL_API}/v1/bodywork/all`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response.data
  }
}

export default new Vehicle()
