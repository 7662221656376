import React from 'react'
import { Radio } from 'antd'

const UserIsActiveSelector = ({ isActive, handleIsActive }) => {
  return (
    <>
      <h4>Estado del usuario</h4>
      <Radio.Group defaultValue={isActive} onChange={handleIsActive} buttonStyle='solid'>
        <Radio.Button value={true}>Activo</Radio.Button>
        <Radio.Button value={false}>Inactivo</Radio.Button>
      </Radio.Group>
    </>
  )
}

export default UserIsActiveSelector
