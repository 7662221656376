import React from 'react'
import {Tag} from 'antd'

const IsInsuranceTag = ({isInsurance}) => {

    console.log(isInsurance)
    if (isInsurance) {
        return (
            <Tag color={'green'}>
                {'Asegurada'}
            </Tag>
        )
    } else {
        return (
            <Tag color={'blue'}>
                {'No Asegurada'}
            </Tag>
        )
    }

}

IsInsuranceTag.defaultProps = {
    isInsurance: ''
}

export default IsInsuranceTag
