import Axios from 'axios'
import { URL_OFFER, URL_ORDER,URL_CALIFICATION } from './Constant'

class ClientOrder {
  async takeOffer(idOrder, idOffer, token) {
    const response = await Axios.put(
      `${URL_OFFER}/take/${idOrder}`,
      { idOffer },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )
    return response
  }

  async payOrder(pay, token) {
    const response = await Axios.post(`${URL_ORDER}/pay`, pay, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return response
  }

  async loadSupportDoc(orderId, doc, token) {
    const response = await Axios.put(
      `${URL_ORDER}/loadsupportdoc/${orderId}`,
      doc,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    return response
  }

  async rateService(rate, token) {
    const response = await Axios.post(`${URL_CALIFICATION}/create/backoffice`, rate, {
      heders: {
        Authorization: `Bearer ${token}`
      }
    })
    return response
  }

  async cancelService(orderId, token) {
    const response = await Axios.post(`${URL_ORDER}/cancellation/client`, orderId, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response
  }
}

export default new ClientOrder()
