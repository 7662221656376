import React, { Component } from 'react'
import {
  Col,
  Card,
  CardBody
} from 'reactstrap'
import MainResetForm from '../components/Forms/MainResetForm'

class TermsConditions extends Component {
  render () {
    return (
      <>

      </>
    )
  }
}

export default TermsConditions
