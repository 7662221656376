export const roleTraduction = {
  administrator: 'Administrador',
  accountancy: 'Contabilidad',
  enterprise: 'Empresa transportadora',
  driver: 'Conductor',
  client: 'Cliente',
  cliententerprise: 'Empresa cliente',
  enterprisedriver: 'Transportador empresa',
  soport: 'Soporte',
}

export const getRoleTraduction = rol => {
  return roleTraduction[rol]
}

export const isAdmin = rol => {
  return rol === 'administrator'
}
export const isAccountancy = rol => {
  return rol === 'accountancy'
}
export const isEnterprise = rol => {
  return rol === 'enterprise'
}
export const isDriver = rol => {
  return rol === 'driver'
}
export const isClient = rol => {
  return rol === 'client'
}
export const isClientEnterprise = rol => {
  return rol === 'cliententerprise'
}

export const isDriverEnterprise = rol => {
  return rol === 'enterprisedriver'
}
export const isSoport = rol => {
  return rol === 'soport'
}
