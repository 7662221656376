import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom'
import {Row, Col} from 'reactstrap'
import '../../node_modules/aos/dist/aos.css'
import AOS from 'aos'
import {NavbarBrand} from 'reactstrap'
import {Link} from 'react-router-dom'
import {Card} from 'antd';

import {termsConditionRoute as routes} from 'routes.js'

class Terms extends Component {
    componentDidMount() {
        AOS.init()
        document.body.classList.add('bg-default')
    }

    componentWillUnmount() {
        document.body.classList.remove('bg-default')
    }

    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.layout === '/reset') {
                return (<Route path={prop.layout + prop.path} component={prop.component} key={key}/>)
            } else {
                return null
            }
        })
    }

    render() {
        return (
            <>
                <Col xs='12' lg='12'
                     className='col-auto d-flex py-8 flex-column justify-content-center bg-gradient-primary'>
                    <div className='d-flex justify-content-center'>
                        <NavbarBrand to='/' tag={Link} className='text-center'>
                            <img
                                style={{maxWidth: '35%', margin: 'auto'}}
                                alt='...'
                                className='img-fluid'
                                src={require('assets/img/brand/tuikit/logo-inverso.png')}
                            />
                        </NavbarBrand>
                    </div>
                    <div className='mt-12 text-center d-flex justify-content-center'>
                        <Card title='TÉRMINOS, CONDICIONES Y POLITICA DE PRIVACIDAD DE TUKIT'
                              style={{width: '100%', padding: 40, 'text-align': 'justify', 'margin-top': 81}}>

                            <h2 style={{'margin-top': '3em', 'margin-bottom': '3em', 'text-align': 'center'}}>TUIKIT
                                COMO CORREDOR EN TRANSPORTE DE CARGA</h2>

                            <h2 style={{'margin-top': '3em', 'margin-bottom': '3em', 'text-align': 'center'}}>CAPITULO
                                I. TERMINOS Y CONDICIONES</h2>

                            <p style={{'font-size': 18, 'text-align': 'center'}}>
                                RELACIÓN CONTRACTUAL
                            </p> <br/>
                            <p>
                                Los presentes términos y condiciones de uso de la plataforma, regulan el acceso o uso
                                que haga el USUARIO DE CARGA y/o TRANSPORTADOR y/o PROPIETARIO DEL VEHICULO – estos dos
                                últimos denominados TERCEROS PROVEEDORES-, como persona natural y/o jurídica, desde
                                cualquier parte de Colombia, lo cual se puede extender a cualquier país del mundo ( Sin
                                excepción hasta la fecha) de aplicaciones, plataformas y subplataformas, páginas web,
                                contenido, pasarelas de pagos, y todo otro producto, bien y servicio sea cual fuere sin
                                distinción alguna, puestos a disposición por TUIKIT, plataforma perteneciente a la
                                sociedad TUIKIT S.A.S, con Nit No. 901378840-1, constituida en la ciudad de Bogotá,
                                Colombia y con domicilio en la misma ciudad, inscrita en la Cámara de Comercio de Bogotá
                                con el No. 03236234. TUIKIT SAS, se denominará para todo efecto EL PROVEEDOR – quien
                                funge como corredor o intermediario-, siendo parte de este, en su momento histórico, sus
                                futuros socios, accionistas, inversionistas, filiales, etc.
                            </p><br/>

                            <h2 style={{'margin-top': '2em', 'margin-bottom': '2em', 'text-align': 'center'}}>POR FAVOR,
                                LEA ESTOS TERMINOS, CONDICIONES Y POLITICA DE PRIVACIDAD DETENIDAMENTE ANTES DE ACCEDER
                                O USAR LOS SERVICIOS DE TUIKIT </h2>

                            <br/>
                            <p>
                                Mediante su acceso y uso de los bienes y servicios de la plataforma TUIKIT, el usuario y
                                los terceros proveedores manifiestan voluntariamente aceptar estos términos, condiciones
                                y política de privacidad, los cuales generan una relación contractual entre los
                                nombrados y EL PROVEEDOR, de lo contrario se entiende no estar de acuerdo y por ende no
                                podrá acceder a ningún contenido de la plataforma de TUIKIT. El proveedor podrá dar por
                                terminado el vínculo con respecto a estos términos y condiciones, de manera parcial o
                                total, sin dar aviso al usuario o a los terceros proveedores, para lo cual dan su
                                consentimiento.
                                <br/>Se podrán incluir y/o modificar y/o actualizar los existentes o nuevos términos y
                                condiciones adicionales, como la política de privacidad o cambiar total o parcialmente
                                los existentes, para lo cual los usuarios y terceros proveedores dan su consentimiento
                                en el presente y con efectos hacia el futuro. Estos cambios se darán a conocer en la
                                plataforma de TUIKIT.
                                <br/>La recolección, acopio, almacenamiento o archivo, como el uso que el proveedor haga
                                de la información de las personas naturales y jurídicas en relación con los datos
                                aportados para acceder a la plataforma, es conforme se dispone en la política de
                                privacidad de EL PROVEEDOR, disponible en su página Web. EL PROVEEDOR está facultado por
                                el usuario y el tercero proveedor para suministrar a cualquier autoridad, aseguradora u
                                otras entidades públicas o privadas, nacionales o extranjeras, cualquier información
                                necesaria si hubiera quejas, delitos, reclamos, siniestros, riesgos, demandas,
                                denuncias, disputas o conflictos, que pudieran incluir accidentes, pérdidas, hurtos,
                                daños y cualquier otro imprevisto que involucre al usuario y/o a los terceros
                                proveedores con respecto a un tercero ajeno a la plataforma TUIKIT, siempre y cuando sea
                                indispensable y necesaria para resolver una problemática y no esté con reserva legal.
                                Cada caso, TUIKIT SAS lo analizará y mirará la conveniencia o no de suministrar dicha
                                información, lo cual es de su resorte sin que exista limitación ni obligación alguna que
                                comprometa a TUIKIT para ningún fin.


                            </p><br/>

                            <p style={{'font-size': 18}}>
                                1. Los Servicios de la plataforma TUIKIT
                            </p>
                            <br/>
                            <p>
                                Estos servicios hacen parte de la plataforma de tecnología de transporte de carga, que
                                permite a través de aplicaciones móviles o fijas o páginas web, entre otras,
                                proporcionadas como parte del PROVEEDOR; Este podrá intermediar como corretaje o
                                intermediario, organizar, planear, interconectar, suministrar información entrelazada,
                                etc, para satisfacer el transporte y/o servicios de CARGA, sea esta cual fuere, entre el
                                USUARIO y EL TRANSPORTADOR y/o PROPIETARIO DEL VEHICULO, sea independiente o pertenezca
                                a una persona natural o jurídica, de índole privada o pública, incluidos terceros
                                transportistas independientes y terceros subproveedores, conforme a un acuerdo con EL
                                PROVEEDOR o algunos afiliados AL PROVEEDOR. Los Servicios del PROVEEDOR se ponen a
                                disposición para uso personal, civil, comercial, industrial y de cualquier otro tipo que
                                requiera de carga, sea nacional e internacional por parte del
                                USUARIO, EL TRANSPORTADOR y/o PROPIETARIO DEL VEHICULO. EL USUARIO Y LOS TERCEROS
                                PROVEEDORES RECONOCEN QUE EL PROVEEDOR NO PRESTA SERVICIOS DE TRANSPORTE O DE LOGÍSTICA
                                O FUNCIONA COMO UNA EMPRESA DE TRANSPORTES Y QUE DICHOS SERVICIOS DE TRANSPORTE DE CARGA
                                Y SU LOGÍSTICA SE PRESTAN POR TERCEROS PROVEEDORES, LOS CUALES SON INDEPENDIENTES, YA
                                SEAN NATURALES O JURIDICAS, Y QUE NINGUNO DE ELLOS SON EMPLEADOS NI FUNCIONARIOS, NI
                                TIENEN NINGUNA RELACION LABORAL CON EL PROVEEDOR NI CON SUS
                                AFILIADOS.

                            </p><br/>

                            <p style={{'font-size': 18}}>
                                2. Licencia.
                            </p>
                            <br/>
                            <p>
                                De acuerdo a estos términos y condiciones, TUIKIT le otorga un permiso limitado, no
                                exclusivo, revocable, no transferible, reservándose cualquier derecho fuera de este, a
                                los usuarios y terceros proveedores para: (i) Tener el acceso y uso adecuado y legal de
                                las aplicaciones de TUIKIT en su dispositivo personal, móvil o fijo, para los fines
                                exclusivos diseñados en la plataforma y (ii) el acceso y uso de cualquier contenido,
                                información, material, bienes, servicios, etc, sean de TUIKIT o de terceros, que estén a
                                disposición a través de la plataforma.
                            </p><br/>

                            <p style={{'font-size': 18}}>
                                3. Restricciones.
                            </p>
                            <br/>
                            <p>
                                El usuario y el tercero proveedor están sujetos a estos términos y condiciones y por
                                ende no podrán: (i) Modificar, alterar, copiar, suplantar, retirar cualquier frase,
                                párrafo, letras, contenido visual o gráfico, logos, colores, dibujos, derechos de autor,
                                marca registrada u otra circunstancia, modo, acción que sea de la plataforma y de
                                propiedad de TUIKIT; (ii) Tampoco podrán, reproducir, extraer, modificar, distribuir,
                                licenciar, arrendar, revender, transferir, exhibir públicamente, presentar públicamente,
                                transmitir, retransmitir o explotar de alguna forma los contenidos de la plataforma de
                                TUIKIT, excepto que lo permita expresamente EL PROVEEEDOR; (iii) En igual sentido,
                                causar o lanzar cualquier programa o script con el objeto de extraer, indexar, analizar
                                o de otro modo realizar prospección de datos de cualquier parte de la plataforma o
                                sobrecargar o bloquear indebidamente la operación y/o funcionalidad de cualquier aspecto
                                de la plataforma; o (iv) intentar obtener un acceso no autorizado o dañar cualquier
                                aspecto de la plataforma o sus sistemas o redes relacionados.
                            </p><br/>

                            <p style={{'font-size': 18}}>
                                4. Prestación de los Servicios de la plataforma.
                            </p>
                            <br/>
                            <p>
                                El usuario y los terceros proveedores aceptan que los servicios de la plataforma TUIKIT,
                                podrán ponerse a disposición bajo varias modalidades asociadas con el transporte de
                                carga, ya sea con: (i) subsidiarias o afiliados de El proveedor; o (ii) Terceros
                                proveedores independientes, formales o informales, incluidos conductores de empresas de
                                red de transportes, titulares del permiso de carga de transporte o titulares de
                                permisos, autorizaciones o licencias de transporte similares. La plataforma hace la
                                intermediación entre el usuario y los terceros proveedores de acuerdo a la necesidad de
                                carga del usuario, entendiéndose, trayecto, volumen, peso, distancia, etc; para lo cual
                                los terceros proveedores se postulan siguiendo estos parámetros y fijando un valor o
                                costo, dentro del cual deben ir los seguros, comisiones, peajes, combustible, auxiliares
                                de carga, etc.
                            </p><br/>

                            <p style={{'font-size': 18}}>
                                5. Servicios y contenido de Terceros.
                            </p>
                            <br/>
                            <p>
                                Cualquier contenido de terceros ajenos – otras plataformas, Web, etc- a TUIKIT y no
                                autorizados por este, no vincula ni lo hace responsable de ninguna manera a TUIKIT con
                                el usuario o los terceros proveedores. TUIKIT podrá ampliamente, asociarse, fusionarse,
                                o realizar cualquier vínculo jurídico, civil o comercial con Apple Inc., Google, Inc.,
                                Microsoft Corporation, Facebook o BlackBerry Limited y/o sus correspondientes
                                subsidiarias o afiliados nacionales e internacionales, como cualquier otra marca,
                                sistema operativo o de otra naturaleza, de índole nacional o extranjera que sirva para
                                mejorar la plataforma y la Web u otro sistema de TUIKIT.
                            </p><br/>

                            <p style={{'font-size': 18}}>
                                6. Titularidad.
                            </p>
                            <br/>
                            <p>
                                TUIKIT SAS es el único y absoluto titular de todos los derechos, usos y desarrollos de
                                los contenidos de la o las plataformas de TUIKIT, por ende, su uso por parte de los
                                usuarios o terceros proveedores, no les transfieren u otorgan ningún derecho sobre la
                                plataforma TUIKIT en ningún sentido, por ende, no podrán utilizar o mencionar en
                                cualquier modo el nombre de empresa TUIKIT SAS, logotipos, nombres de producto y
                                servicio, marcas comerciales o marcas de servicio de TUIKIT o de sus licenciantes.
                            </p><br/>


                            <p style={{'font-size': 18}}>
                                7. Uso de los Servicios y bienes
                            </p><br/>

                            <p style={{'font-size': 18}}>
                                a- Cuentas de usuario.
                            </p>
                            <br/>
                            <p>
                                Cada usuario y tercero proveedor debe registrarse para obtener una cuenta, dicho
                                registro debe ser completo, exacto, actualizado, veraz, sin fraudes ni falsedad en la
                                información, engaño en la misma, con anexo de los soportes respectivos; lo contrario
                                acarrea su desvinculación. Para obtener una Cuenta se debe tener como mínimo 18 años, o
                                tener la mayoría de edad legal en su jurisdicción estatal o que cumpla los requisitos si
                                es en un país extranjero. El registro de la cuenta le requiere que comunique a TUIKIT
                                determinada información personal, como su nombre, dirección, Rut, cámara de comercio,
                                fotocopia cédula del representante legal, número de teléfono móvil, datos del vehículo,
                                como seguros, tarjeta de propiedad, fotografías, revisión tecnomecánica, número de
                                cuenta bancaria, etc, así como por lo menos un método de pago válido (bien una tarjeta
                                de crédito, débito u otro sistema). Esta información será amplia o directa según sea un
                                usuario o un tercero proveedor; su incumplimiento impedirá la continuidad o acceso a la
                                plataforma. Cuando se asigne un nombre de usuario y contraseña, los
                                <br/>USUARIOS Y TERCEROS PROVEEDORES se comprometen a mantenerlo de manera segura y
                                secreta, de tal manera que, si un tercero ajeno a ellos lo utiliza, el usuario y tercero
                                proveedor se hacen responsables de cualquier acto irregular o ilícito que genere un daño
                                a TUIKIT o un tercero, siendo desvinculado inmediatamente de la plataforma y poniendo en
                                conocimiento el hecho a las autoridades respectivas, exonerando a TUIKIT de toda
                                responsabilidad. La información de los pagos por concepto del servicio de carga solo la
                                conocerá la pasarela de pagos respectiva, sin que TUIKIT tenga acceso a ella.

                            </p><br/>

                            <p style={{'font-size': 18}}>
                                b- USO DE INFORMACIÓN
                            </p>
                            <br/>
                            <p>
                                Al ingresar y enviar información en los formularios o registro, el usuario y tercero
                                proveedor aceptan y autorizan el envío de datos personales, de manera expresa y
                                voluntaria al PROVEEDOR o a quien represente sus derechos para que directamente o a
                                través de terceros, el PROVEEDOR, su casa matriz, las filiales, subordinadas y/o
                                asociadas, así como todo tipo de entidades con las que el PROVEEDOR tenga convenios o
                                acuerdos, puedan en cualquier tiempo acceder, almacenar, suministrar, reportar,
                                procesar, divulgar, consultar, utilizar, compilar, tratar y compartir, sea de forma
                                verbal, escrita o a través de cualquier medio, información personal, de carácter
                                comercial, financiero, crediticio y de servicios aquí consignada, la cual se recauda con
                                fines comerciales, operativos y de seguridad y con el fin de que se pueda brindar en la
                                debida forma y con la adecuada diligencia información sobre los productos comerciales y
                                operativos del PROVEEDOR.
                                <br/>
                                El acceso, almacenamiento, suministro, reporte, procesamiento, divulgación, consulta,
                                utilización, compilamiento y tratamiento de los datos personales del usuario se hace con
                                arreglo a la Ley de Habeas Data -Ley 1266 de 2008- y todas las demás disposiciones del
                                ordenamiento jurídico Colombiano que la complementen, modifiquen o reemplacen.
                                <br/>
                                El usuario y tercero proveedor autorizan el envío de información comercial, de productos
                                y servicios del Proveedor o sus asociados, afiliados, entidades privadas o públicas de
                                índole nacional o extranjera, que promocionen productos, servicios, artículos u otros
                                medios, noticias, beneficios, bonificaciones, etc, a su terminal móvil, correo
                                electrónico, aplicaciones de mensajería para teléfono inteligente y/o cualquier otro
                                mecanismo de contacto.
                            </p><br/>

                            <p style={{'font-size': 18}}>
                                8. Requisitos y conducta del usuario.
                            </p>
                            <br/>
                            <p>
                                El usuario y tercero proveedor no podrán ceder o transferir cuenta de registro personal
                                a cualquier otra persona o entidad. Se comprometen a cumplir con todas las leyes
                                aplicables en Colombia y en el exterior al utilizar la plataforma TUIKIT, la que se
                                usará únicamente con fines legítimos, si contraviene esta disposición, se enfrentará a
                                la justicia de acuerdo a la ley, exonerando a TUIKIT de cualquier responsabilidad civil,
                                comercial, penal o de otra naturaleza, ya que se presume la buena fe del usuario y
                                tercero proveedor en todos sus actos. En la ejecución del servicio, el usuario y el
                                tercero proveedor no causarán estorbos, molestias, incomodidades o daños a la propiedad,
                                de ninguno de ellos entre sí, ni a terceros ajenos al servicio de la plataforma. Se
                                exigirá a los terceros proveedores que faciliten un documento de identidad, ARL, EPS,
                                Certificado de sanidad, tanto del conductor, del vehículo o de los auxiliares de carga u
                                otro elemento de verificación de identidad, salud, bienestar, para el acceso a alguna
                                entidad pública o privada destino de la carga y aceptan que se le podrá denegar el
                                acceso si se niega a facilitar estos documentos, por lo que responderán directa y
                                solidariamente el conductor y propietario del vehículo por todos los perjuicios que le
                                causen al usuario de la carga. El conductor y propietario del vehículo permitirán la
                                revisión y control de la carga por parte del usuario o su autorizado durante el trayecto
                                de la misma, o de las autoridades públicas si así se requiere, comprometiéndose el
                                conductor y propietario del vehículo a proteger la totalidad de la carga en su calidad,
                                cantidad, volumen, etc, de una manera extrema, como si fuera suya, respondiendo por
                                cualquier falla o irregularidad de manera solidaria. El usuario y los terceros
                                proveedores deberán cumplir con todas la normas que impongan las autoridades con
                                respecto a pandemias, epidemias, actos de seguridad nacional o extranjera y cualquier
                                otro que implique un compromiso social, estatal o universal, con Colombia u otra nación
                                extranjera.
                            </p><br/>

                            <p style={{'font-size': 18}}>
                                9. Mensajes de texto.
                            </p>
                            <br/>
                            <p>
                                El usuario y el tercero proveedor autorizan para que se les envíen mensajes de texto a
                                sus móviles o fijos y correos aportados. Estos contendrán información de los servicios
                                solicitados y en ejecución, con detalle de nombres, placas, datos del vehículo,
                                direcciones, rutas, ubicación, etc; como también contenido comercial de todo tipo
                                relacionado con el sector de transporte de carga, seguros de toda índole, beneficios,
                                repuestos, llantas, montallantas, hoteles, estaciones de servicio, suministros, precios,
                                informe del clima, accidentes, cierres de vías, y casos fortuitos en carreteras,
                                servicios de toda índole, y en general cualquier contenido que sea benéfico para todas
                                las partes.

                            </p><br/>

                            <p style={{'font-size': 18}}>
                                10. Códigos promocionales.
                            </p>
                            <br/>
                            <p>
                                TUIKIT podrá, a su sola discreción, crear códigos promocionales que podrán ser canjeados
                                por crédito de Cuenta u otros elementos o beneficios relacionados con los Servicios y/o
                                los servicios de un Tercero proveedor – como Soat, repuestos, combustible, llantas,
                                etc-, con sujeción a cualquier condición adicional que TUIKIT establezca sobre la base
                                de cada código promocional. Se acuerda que los Códigos promocionales: (i) deben usarse
                                para el propósito indicado; (ii) no podrán duplicarse, venderse o transferirse de
                                ninguna manera, o ponerse a disposición del público general (tanto si se publican en un
                                foro público como de otra forma), a menos que sea con el permiso de TUIKIT; (iii) podrán
                                ser invalidados por TUIKIT en cualquier momento por cualquier motivo sin responsabilidad
                                para este; (iv) podrán usarse solo conforme a las condiciones específicas que TUIKIT
                                establezca para dicho Código promocional; (v) no son válidos como efectivo; y (vi)
                                podrán caducar según tiempo estipulado. TUIKIT se reserva el derecho de retener o
                                deducir el crédito u otros elementos o beneficios obtenidos a través de la utilización
                                de los Códigos promocionales en el caso de que TUIKIT determine o crea que el uso o el
                                canje de los Códigos promocionales fue de modo erróneo, fraudulento, ilegal o
                                infringiendo las condiciones del Código promocional o las presentes Condiciones.


                            </p><br/>

                            <p style={{'font-size': 18}}>
                                11. Contenido proporcionado por el Usuario.
                            </p>
                            <br/>
                            <p>
                                El usuario y tercero proveedor al proporcionar Contenido de usuario – entiéndase como
                                datos e información personal, civil, comercial, policiva y judicial, sea de carácter
                                natural o jurídica, de la persona, del vehículo, de la carga, seguros, etc-, a TUIKIT,
                                otorga automáticamente una licencia mundial, perpetua, irrevocable, transferible, libre
                                de regalías, con derecho a sublicenciar, usar, copiar, modificar, crear obras derivadas,
                                distribuir, exhibir públicamente, presentar públicamente o de otro modo explotar de
                                cualquier manera dicho Contenido de usuario en todos los formatos y canales de
                                publicidad, almacenamiento, distribución, etc, conocidos ahora o ideados en un futuro
                                (incluidos en relación con los Servicios y el negocio de TUIKIT y en sitios y servicios
                                de terceros), sin más aviso o consentimiento que el dado por la presente aceptación, sin
                                requerirse el pago al usuario o tercero proveedor o a cualquier otra persona o entidad
                                por concepto alguno.
                                <br/>El usuario y el tercero proveedor declaran y garantizan que: (i) es el único y
                                exclusivo propietario de todo el Contenido de usuario o que tiene todos los derechos,
                                licencias, consentimientos y permisos necesarios para otorgar a TUIKIT la licencia al
                                Contenido de usuario como establecido anteriormente; y (ii) ni el Contenido de usuario
                                ni su presentación, carga, publicación o puesta a disposición de otro modo de dicho
                                Contenido de usuario, ni el uso por parte de TUIKIT del Contenido de usuario como está
                                aquí permitido, infringirán, malversarán o violarán la propiedad intelectual o los
                                derechos de propiedad de un tercero o los derechos de publicidad o privacidad o
                                resultarán en la violación de cualquier ley o reglamento aplicable.
                                <br/>Estos afirman no proporcionar Contenido de usuario que sea difamatorio, calumnioso,
                                odioso, violento, obsceno, pornográfico, ilícito o de otro modo ofensivo, como determine
                                TUIKIT, a su sola discreción, tanto si dicho material pueda estar protegido o no por la
                                ley. TUIKIT podrá, a su sola discreción y en cualquier momento y por cualquier motivo,
                                sin avisarle previamente, revisar, controlar, modificar, actualizar o eliminar Contenido
                                de usuario, pero sin estar obligado a ello, cuando las circunstancias lo ameriten de
                                acuerdo a lo estipulado en este contrato de términos y condiciones.
                            </p><br/>

                            <p style={{'font-size': 18}}>
                                12. Acceso a la red y dispositivos.
                            </p>
                            <br/>
                            <p>
                                El usuario y el tercero proveedor es responsable de obtener el acceso a la red de datos
                                necesario para utilizar los Servicios de la plataforma TUIKIT. Podrán aplicarse las
                                tarifas y tasas de datos y mensajes de su red móvil si usted accede o utiliza los
                                Servicios desde un dispositivo inalámbrico, siendo responsable de dichas tarifas, tasas
                                y cualquier otro costo. De igual manera son responsables de adquirir y actualizar el
                                hardware compatible o los dispositivos necesarios para acceder y utilizar los Servicios
                                y Aplicaciones y cualquier actualización de estos. TUIKIT no garantiza que su sistema
                                operativo y los servicios y demás integraciones de su plataforma que presta a través de
                                él, o cualquier parte de estos, funcionen en cualquier software, hardware o dispositivo
                                particular, social, colectivo, sea nacional o mundial. Además, todo lo anterior podrá
                                ser objeto de retrasos, caídas del sistema propio y de terceros, como es el caso de las
                                entidades bancarias o pasarelas de pago, entre otros, problemas de conexión, inherentes
                                al uso de Internet y de las comunicaciones electrónicas, para lo cual se exonera a
                                TUIKIT de toda responsabilidad.

                            </p><br/>


                            <br/>
                            <p style={{'font-size': 18}}>
                                PARAGRAFO: PERMISO DE LOCALIZACION EN PRIMERO Y SEGUNDO PLANO
                            </p>

                            <br/>
                            <p>
                                El USUARIO DE CARGA y EL TERCERO PROVEEDOR, aceptan y permiten de manera amplia el
                                acceso a la localización y/o ubicación de su teléfono móvil y demás dispositivos
                                tecnológicos registrados en la app de Tuikit, con el objetivo de hacer el seguimiento
                                constante de la mercancía en tiempo real, a fin que se obtenga fácilmente por medio del
                                mapa de geolocalización de Googlemaps, conocer por donde marcha y/o transita el vehículo
                                asignado al servicio, garantizando incluso, cuando están activos los dispositivos
                                tecnológicos y la plataforma y/o aplicación de TUIKIT (PRIMER PLANO) y/o cuando estos se
                                encuentren en modo cerrado, suspenso, bloqueado, o no esta en uso (SEGUNDO PLANO).
                                Para este efecto, es obligatorio que el TERCERO PROVEEDOR (TRANSPORTADOR), porte el
                                dispositivo electrónico de manera constante junto con el vehículo donde se transporta la
                                carga, debiendo tener DATOS MOVILES PERMANENTES en el celular u otro dispositivo.
                                Se aclara, que TUIKIT recopila datos para habilitar la ubicación en primero y segundo
                                plano, y así lo acepta el usuario de carga y el tercero proveedor.

                            </p>

                            <br/>
                            <br/>

                            <p style={{'font-size': 18}}>
                                13. Pago
                            </p>
                            <br/>
                            <p>
                                El usuario entiende que el uso de la plataforma, sus servicios y demás integraciones de
                                TUIKIT, deriva en cargos monetarios, sea en moneda local o extranjera, por los servicios
                                o bienes que reciba de un transportador y/o tercero proveedor, aseguradoras, servicios y
                                bienes adicionales proporcionados al usuario, cuyo pago debe hacerse con anticipación al
                                servicio a favor de TUIKIT. Después de que haya recibido los servicios de carga y demás,
                                dando el usuario el visto bueno, TUIKIT facilitará el pago de los Cargos aplicables en
                                nombre del transportador, ya que TUIKIT es solo corredor o intermediario y cobrará por
                                este concepto. El transportador desde ya acepta que TUIKIT descuente del valor
                                consignado por el usuario lo correspondiente al servicio por corretaje o intermediación,
                                seguros, impuestos, repuestos, combustible, llantas y demás bienes o servicios que haya
                                recibido a través de la plataforma de TUIKIT, consignándosele la diferencia en una
                                cuenta aportada por el transportador o por convenios realizados por TUIKIT con entidades
                                financieras, bancarias, cooperativas, etc. El pago de los Cargos de dicha manera se
                                considerará como el pago efectuado directamente por el usuario al transportador. Los
                                Cargos incluirán los impuestos aplicables cuando se requiera por ley, el valor del
                                servicio que corresponda a TUIKIT y demás cargos inmersos por bienes o servicios, que
                                provienen del pago realizado por el usuario. Los Cargos pagados por el usuario son
                                definitivos y no reembolsables, a menos que TUIKIT determine lo contrario. TUIKIT
                                responderá en consecuencia a cualquier solicitud de un Tercero proveedor para modificar
                                los Cargos por un servicio en particular si acepta prestar el servicio al usuario acorde
                                con la necesidad de este y el pago efectuado. Los cargos pueden incluir otros aspectos,
                                tales como: peajes y/o recargos aplicables, cargos municipales, estatales o nacionales,
                                cargos por demoras, recargos aeroportuarios o tarifas de procesamiento por pagos
                                fraccionados, entre otros que sean aplicables. Visite www.tuikit.com para obtener más
                                información.
                                <br/>Todos los Cargos son pagaderos inmediatamente por el usuario de carga y el pago se
                                facilitará por TUIKIT utilizando el método de pago preferido por TUIKIT, y de acuerdo a
                                los convenios de pago con bancos, entidades financieras de toda índole, cooperativas, y
                                pasarelas, sean nacionales o extranjeras, sin limitación alguna, y las que elija TUIKIT
                                a su criterio y voluntad, iniciando con PAYZEN, pero pudiendo continuar con cualquier
                                otra pasarela a nivel mundial; y después de ello TUIKIT le enviará un recibo por correo
                                electrónico u otro medio disponible al usuario y al tercero proveedor. Si se determina
                                que el método de pago de su Cuenta principal ha caducado, es inválido o de otro modo no
                                sirve para cobrarle, El usuario acepta que TUIKIT, como intermediario entre el usuario y
                                el Tercero proveedor, utilice un método de pago secundario si estuviera disponible.
                                <br/>TUIKIT, en cualquier momento y a su sola discreción, se reserva el derecho de
                                establecer, modificar, eliminar y/o revisar los Cargos para alguno o todos los servicios
                                o bienes obtenidos a través del uso de la plataforma. Además, El usuario de carga
                                reconoce y acepta que los Cargos aplicables en determinadas zonas geográficas podrán
                                incrementar sustancialmente durante los periodos de alta demanda, por hechos ajenos al
                                transportador, por derrumbes, obras, caída de puentes, actos de la naturaleza, actos
                                terroristas, por hechos fortuitos o de fuerza mayor, etc. TUKIT usará los esfuerzos
                                razonables para informarle de los Cargos que podrán aplicarse, siempre y cuando el
                                usuario sea responsable de los Cargos incurridos en su Cuenta, independientemente que el
                                usuario conozca dichos Cargos o los importes de estos. TUIKIT podrá, cuando lo considere
                                oportuno, proporcionar a determinados usuarios ofertas promocionales, descuentos de los
                                bienes y servicios, los cuales pueden causar afectación en el cobro de diferentes
                                importes por estos o similares obtenidos a través del uso de la plataforma, y el usuario
                                acepta esta disposición. El usuario podrá optar por cancelar su solicitud para los
                                servicios o bienes de un Tercero proveedor antes de las dos horas una vez se haya pagado
                                el servicio o el bien solicitado, en cuyo caso se le podrá cobrar una tasa de
                                cancelación del 5% del valor total ( flete, seguros y otros) como reconocimiento por el
                                perjuicio causado, el cual se distribuirá entre TUIKIT y el tercero proveedor, por la
                                visita fallida, según lo dispuesto a continuación.

                                <b style={{'font-weight': 'bold'}}> PARAGRAFO UNO: Visita Fallida</b> se le denominará a
                                aquella circunstancia en la cual el tercero proveedor se encuentre presto a realizar el
                                servicio y por cualquier motivo el usuario tenga impedimentos voluntarios o
                                involuntarios para que este sea ejecutado. En dicho caso, no habrá penalidad por
                                cancelación siempre que el usuario utilice la plataforma tecnológica para reportar dicha
                                cancelación máximo dos (2) horas antes de programado el servicio y haber realizado el
                                pago. En un tiempo menor se considerará que el tercer proveedor ya se encuentra en
                                desplazamiento hacia el lugar de recogida y parte de la penalidad será destinada a
                                compensar los gastos incurridos. La penalidad por cancelación y retribución a terceros
                                proveedores será del 5% del valor total (flete, seguros y otros), más los impuestos o
                                tasas y demás gastos que este genere, los cuales se descontarán del valor pagado y se
                                devolverá el remanente al usuario a la cuenta u otro medio que él disponga.

                                <b style={{'font-weight': 'bold'}}> DOS: Stand-By:</b> En los casos en los que
                                no se dé cumplimiento al tiempo inicialmente pactado en la App de TUIKIT para el cargue
                                y descargue de la mercancía, se solicitará al dueño de la carga y transportador que se
                                realicen los pasos correspondientes para el ajuste en el flete pues este se
                                incrementaría teniendo en cuenta el tiempo adicional de espera, Estos valores
                                adicionales se ajustarán y se le comunicarán al usuario de carga quien desde ya acepta
                                dicho ajuste y el cobro respectivo aplicable al sistema de pago que haya realizado,
                                teniendo en cuenta que el tiempo stand by no es responsabilidad del tercero proveedor ni
                                de TUIKIT sino del usuario de carga por si o por el hecho de un tercero relacionado con
                                este directa o indirectamente.
                                Esta estructura de pago está destinada para compensar plenamente al Tercero proveedor
                                por los servicios o bienes proporcionados. TUIKIT no designa ninguna parte de su pago
                                como propina o gratificación al transportador o Tercero proveedor. Cualquier
                                manifestación por parte del usuario en el sentido de dar una propina, esta es voluntaria
                                y por ende no es incluida en los pagos que realiza para los servicios o bienes
                                proporcionados. El usuario entiende y acepta que, mientras es libre de proporcionar un
                                pago adicional como gratificación a cualquier Tercero proveedor que le proporcione
                                servicios o bienes obtenidos mediante la plataforma, no tiene obligación de ello. Las
                                gratificaciones son voluntarias. Después de que haya recibido los bienes o servicios
                                tendrá la oportunidad de calificar su experiencia y dejar comentarios adicionales sobre
                                el transportador.

                            </p><br/>

                            <p style={{'font-size': 18}}>
                                14. DERECHOS DE AUTOR Y PROPIEDAD INDUSTRIAL
                            </p>
                            <br/>
                            <p>

                                Los derechos de autor, marcas, logos, colores, dispositivos, dibujos del presente PORTAL
                                pertenecen a TUIKIT salvo que se indique lo contrario y quede expreso por escrito.
                                <br/>
                                El Logo, las marcas y lemas comerciales de TUIKIT, y de sus aliados comerciales que
                                previamente autorizaron su uso en el PORTAL, se encuentran registrados ante las
                                autoridades competentes y protegidos bajo las leyes de propiedad industrial de la
                                República de Colombia y de los países donde opere o funcione esta plataforma. Así mismo,
                                los textos, gráficas, software y en general el diseño y configuración de este PORTAL son
                                de propiedad de TUIKIT y se encuentran legalmente protegidos por el derecho de autor. En
                                consecuencia ésta prohibida la reproducción o uso total y/o parcial de todos y cada uno
                                de los elementos aquí mencionados, por lo que cualquier uso que se les dé a los mismos
                                por parte de cualquier persona, sea natural o jurídica, distinto a los fines del PORTAL,
                                está prohibido, y en consecuencia cualquier conducta contraria a ello, podrá ser
                                judicializada directamente por TUIKIT SAS, debiendo si es del caso los responsables
                                indemnizar por los daños y perjuicios que con su actuar contrario a la ley pueda
                                ocasionar a TUIKIT y/o algún tercero al respecto.

                            </p>


                            <br/>

                            <p style={{'font-size': 18}}>

                                15. OBLIGACIONES DEL USUARIO Y TERCERO PROVEEDOR
                            </p>
                            <br/>

                            <p>


                                El Usuario y terceros proveedores se obligan a no acceder, ni intervenir en el
                                funcionamiento, ni usar o disponer en cualquier forma de los archivos, sistemas,
                                programas, aplicaciones o cualquier otro elemento del PORTAL – entiéndase por PORTAL, la
                                plataforma y página WEB de TUIKIT, todo su contenido de bienes y servicios, sus
                                integraciones propias y adicionales de terceros- como también se obligan a no acceder,
                                ni usar o disponer indebidamente o sin autorización de TUIKIT o de los titulares
                                respectivos, los datos o información incluidos en los mismos programas, archivos,
                                sistemas y aplicaciones, entre otros. Igualmente se obligan a brindar información real,
                                veraz, cierta y comprobable, absteniéndose de utilizar información adulterada o que no
                                corresponda a la verdad o que no sea de su propiedad o no esté debidamente autorizado
                                para el efecto. Deben respetar los derechos de autor y en general de propiedad
                                intelectual, observar y cumplir las condiciones mínimas de acceso y las reglas de
                                seguridad del PORTAL conforme lo dispuesto en los presentes términos y condiciones.
                                <br/>
                                El usuario y terceros proveedores serán responsables por los daños y perjuicios que
                                directa o indirectamente causen a TUIKIT y/o terceros afectados puedan sufrir, como
                                consecuencia del incumplimiento de los presentes términos y condiciones.
                                <br/><br/>

                                Al utilizar el PORTAL de TUIKIT, queda prohibido: (i) enviar o publicar o transmitir
                                contenido sexual u obsceno; (ii) enviar o publicar o transmitir información cuyo
                                contenido sea, directa o indirectamente, agresivo, profano, abusivo, difamatorio y/o
                                fraudulento; (iii) enviar o publicar o transmitir archivos que contengan virus u otras
                                características destructivas que puedan afectar de manera adversa el funcionamiento de
                                un dispositivo ajeno y/o puedan afectar el correcto funcionamiento de los mismos y/o del
                                PORTAL; (iv) utilizar el PORTAL , directa o indirectamente, para violar cualquier ley
                                aplicable, cualquiera fuese su naturaleza, ya sea regional, nacional o internacional;
                                (v) utilizar nuestros servicios con un nombre falso, erróneo o inexistente, ya sea como
                                persona natural o jurídica; (vi) enviar o transmitir información de propiedad de
                                terceros o que pudiera afectar adversamente la imagen y/o los derechos de terceros;
                                (vii) realizar cualquier acto contrario a la ley, o que de cualquier manera viole los
                                presentes términos y condiciones o el contenido de datos del portal o de un tercero
                                relacionado directa o
                                indirectamente con TUIKIT. (viii) Incluir en el PORTAL de TUIKIT cualquier información
                                incompleta, falsa, engañosa o inexacta, (ix) Borrar o revisar cualquier material
                                incluido en el PORTAL por cualquier otra persona o entidad, sin la debida autorización,
                                (x) Usar cualquier elemento, diseño, software o rutina para interferir o intentar
                                interferir el funcionamiento adecuado de este PORTAL o cualquier actividad que sea
                                llevada a cabo en el PORTAL de TUIKIT, (xi) Intentar descifrar, descompilar o
                                desensamblar cualquier software, sistemas operativos, aplicaciones, contenidos,
                                comprendido en el PORTAL o que de cualquier manera haga parte del mismo. (xii) Utilizar
                                el PORTAL DE TUIKIT con fines ilícitos, violatorios de los derechos e intereses de
                                terceros o impedir su funcionamiento o normal utilización.
                                <br/>
                                El usuario y terceros proveedores serán responsables por todos los usos e información de
                                sus registros de datos suministrados al PORTAL DE TUIKIT y en consecuencia, deberán
                                notificar inmediatamente a TUIKIT cualquier modificación de su registro, a través de la
                                página web www.tuikit.com o directamente por vía telefónica o electrónico.
                                <br/>
                                El usuario y el tercero proveedor se responsabilizan de entregar su información personal
                                y no utilizar información de terceros para acceder al presente PORTAL DE TUIKIT, con la
                                consecuencia de tener que responder por los daños y perjuicios que ello ocasione al
                                titular de la información por él suministrada sin la debida autorización. No está
                                permitido actuar como usuario y/o tercero proveedor en representación de un tercero sin
                                autorización previa.
                                <br/>
                                El (los) USUARIO(S) Y TERCEROS PROVEEDORES acepta(n) que se registre la dirección IP
                                desde la que se accede al PORTAL DE TUIKIT, para efectos de velar por la corrección de
                                los servicios y expulsar al(los) USUARIO(S) Y TERCEROS PROVEEDORES que incumpla(n) las
                                presentes CONDICIONES.


                            </p><br/>


                            <p style={{'font-size': 18}}>
                                16. Cobertura, Limitación de responsabilidad; Indemnidad.
                            </p>
                            <br/>
                            <p>
                                COBERTURA Y LIMITACION DE RESPONSABILIDAD POR FALTA DE INFORMACION E IDONEIDAD DEL
                                TERCERO PROVEEDOR.
                                Los servicios que proporciona la plataforma TUIKIT, son de corretaje o intermediación de
                                transportadores de carga. Además, TUIKIT no hace declaración ni presta garantía alguna
                                relativa a la fiabilidad, puntualidad, calidad, idoneidad o disponibilidad de los
                                transportadores o que estarán libres de errores. TUIKIT no garantiza la calidad,
                                idoneidad, seguridad o habilidad de los terceros proveedores o transportadores, solo la
                                información aportada por este para la base de datos, tanto de la persona como del
                                vehículo, presumiéndose la buena fe del transportador y propietario del vehículo en su
                                aporte. El usuario de carga acepta que todo riesgo derivado del uso del servicio del
                                transportador será únicamente suyo y del propietario del vehículo, en la máxima medida
                                permitida por la ley aplicable, acorde con el seguro adquirido y cuyos términos y
                                alcances estén contemplados en el contrato de seguro. Para este efecto, la carga tendrá
                                un seguro suministrado por SEGUROS DEL ESTADO u otra compañía aseguradora,
                                reaseguradora, sea nacional o extranjera, siempre y cuando sea adquirido y pagado en su
                                totalidad por el usuario generador de carga y autorizado por la aseguradora, en los
                                términos y condiciones de la póliza respectiva, cuyo costo será adicional al valor del
                                servicio de carga junto con los impuestos, tasas y demás costos. En caso que el servicio
                                sea cancelado por parte del usuario de carga o por el transportador, el valor de la
                                póliza será reintegrado al usuario de carga, con los descuentos pertinentes. El usuario
                                de carga y el tercero proveedor aceptan que participen otras compañías de seguros, sea
                                nacionales o extranjeras, dentro del amplio ámbito de su competencia, con el fin de
                                amparar todos los aspectos que generen riesgo en cabeza del usuario de carga, de los
                                terceros proveedores o de sus allegados y de posibles terceros afectados. El usuario de
                                carga y el tercero proveedor aceptan todas las condiciones, restricciones, exclusiones y
                                demás estipulaciones contenidas en la póliza de seguros emitidas por la compañía
                                respectiva, sea esta cual fuere. Estas propuestas y costos estarán publicadas en la
                                página www.tuikit.com <br/> <b style={{'font-weight': 'bold'}}>PARAGRAFO PRIMERO:</b> El
                                GENERADOR DE CARGA O USUARIO,
                                deberá asegurar la carga con cualquier compañía aseguradora a nivel nacional o
                                internacional con influencia en Colombia y/o en el país que se preste el servicio de
                                carga, para lo cual TUIKIT sugiere y/o recomienda a SEGUROS DEL ESTADO con quien tiene
                                una póliza – Esta póliza no genera para TUIKIT solidaridad por responsabilidad en ningún
                                siniestro-, lo cual NO obliga al usuario o generador de carga, quién podrá contratar con
                                cualquier otra aseguradora, e inclusive NO ASEGURAR la carga, bajo su exclusiva
                                responsabilidad. En todo caso EL GENERADOR DE CARGA o
                                USUARIO, eximen de toda responsabilidad por la carga, sea total o parcial a TUIKIT, en
                                cuanto a cantidad, calidad, volumen, etc, ya que TUIKIT desconoce físicamente el estado
                                de la misma y no tiene contacto físico, visual ni material con la carga, pues actúa
                                solamente como intermediario entre el generador de carga y el transportador.
                                <br/> <b style={{'font-weight': 'bold'}}>PARAGRAFO SEGUNDO;</b> El usuario de carga y el
                                transportador deben leer minuciosamente y analizar de manera concreta todas las
                                condiciones generales y específicas de la póliza, ya que hay exclusiones específicas,
                                considerando de gran importancia las condiciones de falta de entrega; saqueo, sea total
                                o parcial; pérdida total; maquinaría usada –concepto de la misma, siendo relevante si
                                hay accidente y la mercancía sufre daño pero el vehículo no- casos que involucran si es
                                servicio público o particular- este último si es el propietario de la mercancía o no-,
                                clase de placa, sea amarilla, roja o blanca, o cualquier otra; operación urbana; pérdida
                                de mercado; contenedor como medio de transporte, entre otras. Si no se objeta ninguna de
                                ellas, se supone su aprobación total, exonerando a TUIKIT de cualquier responsabilidad.
                                Se especifica, que todo lo que sea USADO, incluyendo mercancía, maquinaría, equipos,
                                entre otros, estas quedan excluidas del amparo de avería particular y saqueo, de acuerdo
                                a los términos de la póliza.
                                <br/> <b style={{'font-weight': 'bold'}}>PARAGRAFO TERCERO:</b> Según los términos de la
                                póliza de Seguros del Estado, anexo 1, se autoriza la movilización de mercancía en
                                vehículos de placa BLANCA a NIVEL NACIONAL y en vehículos de placa AMARILLA A NIVEL
                                URBANO, siempre que se cumpla con los requerimientos de las autoridades respectivas.
                                Para carga de productos REFRIGERADOS, el propietario y/o empresa transportadora, debe
                                tener un cronograma de mantenimiento preventivo y correctivo de las unidades de carga,
                                máximo CADA TRES (3) MESES.
                            </p>


                            <p style={{'font-size': 18}}>
                                17. PREGUNTAS, QUEJAS Y RECLAMOS
                            </p>
                            <br/>
                            <p>
                                TUIKIT facilitará a los usuarios de carga y terceros proveedores y demás personas que
                                tengan relación directa con los bienes y servicios ofrecidos en el PORTAL DE TUIKIT, un
                                PQR con el fin de atender preguntas, quejas y reclamos, el cual estará en el portal
                                www.tuikit.com, como en los canales electrónicos o telefónico.
                            </p>

                            <p style={{'font-size': 18}}>
                                18. Limitación de responsabilidad.
                            </p>
                            <br/>
                            <p>
                                TUIKIT no será responsable de daños directos, indirectos, incidentales, especiales,
                                ejemplares, punitivos o emergentes, incluidos el lucro cesante, daño emergente, la
                                pérdida de datos, la lesión personal o el daño a la propiedad, ni de perjuicios
                                relativos, o en relación con, o de otro modo derivados de cualquier uso de los servicios
                                de la plataforma, incluso aunque TUIKIT haya sido advertido de la posibilidad de dichos
                                daños. TUIKIT no será responsable de cualquier daño, responsabilidad, pérdida, hurto,
                                por mínima que sea, que se deriven de: (i) la calidad, cantidad, volumen, uso,
                                contenido, legalidad, legitimidad, procedencia, destino, etc, de la carga, entendiéndose
                                que todo esto es responsabilidad única del usuario o propietario de la carga. La carga
                                en sí tendrá un seguro cuyo costo debe asumir el usuario; o (ii) cualquier transacción o
                                relación entre el usuario de la carga y el tercero proveedor; Tampoco será responsable
                                del retraso o de la falta de ejecución resultante de causas que vayan más allá del
                                control razonable de TUIKIT, entendiéndose que es solo un corredor o intermediario entre
                                el usuario de carga y el tercero proveedor. (iii) Del vehículo en su estructura
                                particular y general, para lo cual debe el propietario del mismo o
                                el poseedor tener un seguro vigente que cobije o proteja el vehículo de todo riesgo,
                                siendo este opcional, además de los seguros exigidos por las autoridades, pero en todo
                                caso la única responsabilidad es del tercero proveedor (iv) Al transportador o
                                conductor, sus ayudantes, auxiliares de carga, de lesiones, incapacidad o muerte,
                                limitaciones para laborar, sean parciales o totales, donde el propietario del vehículo o
                                su poseedor, como el conductor y los auxiliares deben tener los seguros respectivos
                                vigentes, pagados por ellos, lo cual se presume que los poseen y son legales. (v) Los
                                terceros proveedores manifiestan que solo transportarán la carga solicitada por el
                                usuario y que no involucrarán ningún otro elemento en ella que no sea autorizada por el
                                usuario de carga, todo lo cual debe ser acorde con las leyes, sin contravenir ninguna de
                                ellas. (vi) Toda carga y el vehículo deben ser fumigados previamente antes del despacho
                                para evitar cualquier contagio, y se deben utilizar todas las medidas sanitarias para su
                                cargue, transporte y descargue, como de todas las personas que intervengan en este
                                proceso, inclusive durante el trayecto de transporte de la carga, siendo obligatorio
                                esta fumigación y control sanitario de la carga y de las personas, responsabilizándose
                                ellos de este control. El usuario de carga, el transportador y el propietario del
                                vehículo, como los auxiliares de carga y cualquier otro interviniente, exoneran de toda
                                responsabilidad a TUIKIT SAS y sus socios, filiales, establecimientos de comercio
                                adscritos, empleados, contratistas, sucursales nacionales y extranjeras, por toda acción
                                u omisión relacionada con lo señalado en los literales anteriores y los que tengan
                                relación directa o indirecta con ellos, ante los mismos infractores aquí señalados y
                                terceros intervinientes ajenos al servicio de la plataforma.
                            </p>


                            <p style={{'font-size': 18}}>
                                PARAGRAFO. Indemnidad.
                            </p>
                            <p>
                                El usuario, el transportador y propietario del vehículo acuerdan mantener indemnes o
                                excluido de toda responsabilidad a TUIKIT por cualquier acto u omisión realizada con
                                ocasión del servicio de carga y responderán frente a terceros y a TUIKIT por cualquier
                                reclamación, demanda, denuncia, pérdida, responsabilidad y gastos (incluidos los
                                honorarios de abogados) que se deriven de: (i) El uso indebido de los Servicios y bienes
                                proporcionados por TUIKIT; (ii) El incumplimiento o violación de cualquiera de estos
                                términos, condiciones y contenido de datos; (iii) El mal uso del Contenido de usuario,
                                transportador o propietario del vehículo en ejercicio del servicio ofrecido por TUIKIT;
                                o (iv) La infracción de los derechos de diversa índole de cualquier tercero, ya sea
                                contractual o extracontractual, responsabilidad civil y comercial, lesiones o muerte del
                                usuario, transportador, propietario del vehículo, o cualquier tercero, o daños a
                                terceros, etc. En todo caso, la aseguradora cubrirá el costo dentro de sus condiciones
                                establecidas en el contrato de seguro adquirido y autorizado, y cualquier otro valor no
                                cubierto por esta compañía aseguradora, será de cargo del usuario y/o transportador y/o
                                propietario del vehículo, individual o conjuntamente, sean solidarios o no; excluyendo a
                                TUIKIT de manera expresa de cualquier responsabilidad directa o indirecta.
                            </p><br/>


                            <p style={{'font-size': 18}}>
                                19. Legislación aplicable; Arbitraje.
                            </p>
                            <br/>

                            <p>
                                Los presentes términos y condiciones se regirán e interpretarán exclusivamente en virtud
                                de la legislación colombiana y de los países donde haya sucursales, franquicias,
                                mandatos, comodatos, compraventa, permuta y cualquier otra figura jurídica y comercial
                                de TUIKIT. Cualquier disputa, conflicto, reclamación o controversia, del tipo que sea,
                                que resulte de los presentes términos y condiciones, incluyendo las relativas a su
                                validez, interpretación y exigibilidad, deberán someterse forzosamente a procedimientos
                                de conciliación y/o mediación de acuerdo a las leyes de Colombia y de los países donde
                                opere. Se podrá aplicar por analogía in bonam partem el Reglamento de Mediación de la
                                Cámara de Comercio Internacional (“Reglamento de Mediación de la CCI”) si existiere
                                algún vacío en la legislación local. Si dicha disputa no fuese solucionada en un plazo
                                de sesenta (60) días desde la fecha en la que se formalice la solicitud de conciliación
                                y luego 30 días de la solicitud de mediación de acuerdo a las leyes de cada país, se
                                solucionará exclusiva y definitivamente mediante arbitraje de acuerdo a las normas de
                                cada país y en analogía in bonam partem con el Reglamento de Arbitraje de la Cámara de
                                Comercio Internacional. El litigio será resuelto por un (1) árbitro nombrado a tal fin
                                en virtud del Reglamento de cada Cámara de Conciliación. El lugar tanto para la
                                conciliación y/o mediación como para el arbitraje será Bogotá D.C Colombia y/o la ciudad
                                principal del país donde se haya registrado TUIKIT con la figura jurídica autorizada,
                                sea sucursal, franquicia u otra forma comercial o civil. El idioma de conciliación,
                                mediación y/o arbitraje será el español y como segundo el inglés o en el idioma materno
                                del representante de TUIKIT.
                                <br/>La existencia y el contenido de los procedimientos de conciliación, mediación y
                                arbitraje, incluidos los documentos e informes presentados por las partes, la
                                correspondencia del conciliador, mediador y los pedidos y los laudos emitidos por el
                                único árbitro deberán permanecer en estricta confidencialidad y no deberán ser revelados
                                a ningún tercero sin el consentimiento expreso de TUIKIT, a menos que: (i) la revelación
                                al tercero sea razonablemente necesaria para llevar a cabo el procedimiento de
                                conciliación, mediación o arbitraje; y (ii) el tercero acepte incondicionalmente por
                                escrito estar sujeto a la obligación de confidencialidad estipulada en el presente
                                documento.
                            </p>

                            <br/>
                            <p style={{'font-size': 18}}>
                                20. Otras disposiciones
                            </p>
                            <br/>
                            <p>
                                a-. Reclamaciones por infracción de derechos de autor.<br/>
                                Las reclamaciones por infracción de derechos de autor deberán enviarse al agente
                                designado de TUIKIT.

                            </p><br/>
                            <p style={{'font-size': 18}}>
                                b-. Notificaciones.
                            </p><br/>

                            <p>
                                TUIKIT podrá notificar mediante un correo electrónico enviado a su dirección electrónica
                                o por comunicación escrita enviada a su dirección. c-. Disposiciones generales.
                                <br/>Nadie podrá ceder ni transferir estos términos y condiciones, en todo o en parte,
                                sin el consentimiento previo por escrito de TUIKIT. Usted da su aprobación a TUIKIT para
                                ceder o transferir estos, en todo o en parte, incluido a: (i) una subsidiaria o un
                                afiliado; (ii) un adquirente del capital, del negocio o de los activos de TUIKIT; o
                                (iii) un sucesor por fusión. No existe entre el usuario, transportador, propietario del
                                vehículo y TUIKIT, una empresa conjunta o relación de socios, empleo o agencia ni
                                relación laboral como resultado del contrato de corretaje o intermediación.
                                <br/>Para todos los efectos, siempre el lugar de intermediación o corretaje de bienes y
                                servicios de TUIKIT será la ciudad de Bogotá – Colombia, independientemente de donde se
                                presté o solicite el servicio de carga o el bien. Si fuere a nivel Internacional,
                                también será la ciudad de Bogotá – Colombia, excepto las normas del país donde se
                                instale una sucursal, filial, franquicia, u otra figura jurídica de los servicios y
                                bienes de TUIKIT SAS.

                            </p>


                            <br/>
                            <p style={{'font-size': 18}}>
                                CAPITULO II. POLITICA DE PRIVACIDAD DE TUIKIT
                            </p><br/>

                            <p>
                                TUIKIT SAS ( en adelante denominado EL PROVEEDOR), propietario exclusivo de la
                                plataforma TUIKIT, el cual cuenta con: (i) vinculado a la URL del sitio web
                                www.tuikit.com que permite el acceso de los usuarios y terceros proveedores y
                                visitantes, etc y (ii) la aplicación del PROVEEDOR para los teléfonos inteligentes y
                                herramientas tecnológicas. Estas herramientas ( "Plataforma Digital") se pueden utilizar
                                para optimizar, mejorar la calidad del servicio, intercambio de experiencias, mejorar la
                                información, interactuar con los visitantes, obtener preguntas, quejas y reclamos y
                                darles soluciones más rápidas y eficientes, optimizar los servicios de intermediación
                                prestados por EL
                                PROVEEDOR.
                                Lea la siguiente ( "Política de Privacidad") para saber cómo recogemos, protegemos y
                                utilizamos su información cuando interactúa con EL PROVEEDOR a través de esta plataforma
                                digital o a través del sistema telefónico, mensajería electrónica, incluyendo SMS.
                                Al acceder a la plataforma digital usted indica que ha leído, acepta sus condiciones y
                                términos, como también que cumplirá cabalmente con esta política de privacidad en su
                                totalidad y sus modificaciones, las cuales se harán sin previo aviso. Si no está de
                                acuerdo con este convenio de privacidad, por favor no hacer uso de la plataforma
                                digital.
                                Este convenio de privacidad se actualizó por última vez el 20 de Enero de 2021.
                            </p>

                            <br/>
                            <p style={{'font-size': 18}}>
                                1. Información Personal que se puede recopilar
                            </p><br/>

                            <p>
                                1.1 La plataforma digital puede recoger los diferentes tipos de información que los
                                clientes ( "Usuarios y terceros proveedores"), conductores y visitantes nos proporcionan
                                para crear, modificar y actualizar en la plataforma digital, sea directamente o por el
                                uso de la plataforma digital, incluyendo pero no limitado a: nombre, dirección,
                                identificación, correo, teléfono móvil y fijo, información de contacto, información
                                sobre la cualificación de los conductores y los vehículos, en todas sus fases ( licencia
                                de conducción, cédula, Soat, tarjeta de propiedad del vehículo, seguros, fotografías del
                                vehículo, etc) como de los USUARIOS ( Detalles de la carga, seguros, Fotografía de la
                                carga, datos del propietario de la carga, direcciones de despacho y entrega, etc), como
                                todos los datos para el pago, como son: banco, cuenta, número de tarjeta, etc.
                                <br/>
                                1.2 Se considera información personal todos los datos no públicos capaces de identificar
                                a una persona o entidad y / o relacionados con la privacidad del titular y aquellos que
                                el cliente desee proporcionar.
                                <br/>
                                1.3 Es posible para EL PROVEEDOR obtener información de fuentes públicas, privadas u
                                otros terceros, que pueden incluir los detalles, antecedentes de todo nivel, información
                                general de los usuarios, terceros proveedores, conductores, vehículos, seguros,
                                infracciones, etc, que conlleven a la seguridad y verificación de los datos aportados.
                                Cualquier falsedad o engaño puede ser reportada a la autoridad competente por parte del
                                PROVEEDOR, no dejando de lado las consecuencias por este actuar contempladas en los
                                términos y condiciones y política de privacidad.
                                <br/>
                                1.4 Además, los conductores pueden utilizar la plataforma digital para actualizar el
                                estado de las cargas que están llevando a través de los servicios de seguimiento de la
                                aplicación.
                            </p>


                            <br/>
                            <p style={{'font-size': 18}}>
                                2. Otra información que recogemos o recopilamos.
                            </p><br/>

                            <p>
                                2.1 También podemos recopilar información acerca de la forma de interactuar con la
                                plataforma digital, tales como:
                                <br/>2.2 Su uso de la plataforma digital (por ejemplo, las páginas que visitó y la
                                duración de acceso); y
                                <br/>2.3 dirección IP y la información sobre el dispositivo que se utiliza para acceder
                                a la plataforma digital, como su dirección de control de acceso al medio (MAC), el tipo
                                de ordenador (Windows, Macintosh, entre otros), resolución de pantalla, el nombre y la
                                versión del sistema operativo, el fabricante y modelo del dispositivo, idioma, tipo y
                                versión del navegador de Internet y el nombre y la versión de la plataforma digital
                                (aplicación, el portal web ) que está utilizando, lugar desde donde interactúa, sea
                                nacional o extranjero.
                                <br/>2.4 Podemos combinar la información recopilada a través de la plataforma digital
                                con la información que nos ha proporcionado directamente por otros medios o la
                                información obtenida de otras fuentes para que nos ayude en la prestación de servicios
                                de calidad y personalizar nuestros servicios. Cuando combinamos información de
                                identificación no personal con información personal, trataremos la información combinada
                                como información personal.
                                <br/>2.5 Podemos usar o compartir dicha información con terceros públicos o privados
                                para cualquier propósito legal, sea nacional o extranjero.
                            </p>


                            <br/>
                            <p style={{'font-size': 18}}>
                                3. Uso de los Cookies
                            </p><br/>

                            <p>
                                <br/>3.1 Nosotros, incluyendo nuestros terceros proveedores de servicios y bienes,
                                utilizamos ciertas herramientas para recopilar información acerca del usuario y terceros
                                proveedores, como de los vehículos, mientras navega por la plataforma digital. Estas
                                herramientas utilizan cookies, que son pequeños archivos de texto que se almacenan en el
                                disco duro de su ordenador (o cualquier otro dispositivo conectado a Internet, tales
                                como teléfonos inteligentes, ordenadores, tabletas etc.).
                                <br/>Las cookies se utilizan, entre otras cosas, para mejorar el rendimiento de la
                                plataforma digital, para ofrecerle opciones de pantalla personalizados, para compilar
                                informes estadísticos sobre las actividades de la Plataforma Digital o con fines de
                                seguridad. También utilizamos cookies para reconocerlo como un visitante anterior a
                                nuestra plataforma digital o para rellenar formularios. Dicha información puede ser
                                revelada a terceros para el suministro de cualquiera de las actividades mencionadas en
                                nuestro nombre.
                                <br/>3.2 Puede configurar su navegador para rechazar las cookies o para que le notifique
                                cuando se utiliza una cookie. Sin embargo, recuerde, que sin cookies es posible que no
                                sea capaz de sacar el máximo provecho de todas las características de nuestra plataforma
                                digital. Para obtener más información acerca de las cookies, incluyendo instrucciones
                                específicas para su navegador sobre cómo restringir o bloquear las cookies, visita
                                www.tuikit.com

                            </p>


                            <br/>
                            <p style={{'font-size': 18}}>
                                4. Cómo utilizamos la Información Personal
                            </p><br/>

                            <p>
                                4.1 Esta información se utiliza principalmente para suministrar la plataforma digital y
                                sus herramientas relacionadas para su utilización a:
                                <br/>· Cumplir con sus solicitudes y responder a sus preguntas;
                                <br/>· Coordinar con los usuarios, terceros proveedores, los conductores y otros
                                intervinientes en el transporte de carga y participantes de la cadena de despacho y
                                entrega;
                                <br/>· Proporcionar los cargos o costos por servicios de transporte para el cliente;
                                <br/>· Realizar un seguimiento y proporcionar información actualizada sobre el estado y
                                la ubicación de la carga;
                                <br/>· Generar el transporte de carga histórica;
                                <br/>· Control de pagos de facturas;
                                <br/>· Facilitar el uso de nuestros servicios y la plataforma digital para eliminar la
                                necesidad de volver a introducir la información;
                                <br/>· Enviar información personal y administrativa a los usuarios o terceros
                                proveedores y otras personas naturales o jurídicas, sean nacionales o extranjeras,
                                cuando se requiera o por ley sea obligatorio;
                                <br/>· Analizar y controlar el uso de la plataforma digital.
                                <br/>· Ayuda a proteger la plataforma digital, prevenir el fraude, las actividades
                                delictivas y otras responsabilidades legales;
                                <br/>· En contacto con el cliente acerca de otros servicios y productos del PROVEEDOR,
                                afiliados, socios, inversionistas, o terceros;
                                <br/>· Realizar otras actividades diferentes a las contenidas en los términos y
                                condiciones, contenido de uso o política de privacidad, con su consentimiento.
                                <br/>· Realizar el análisis de datos y auditoría, desarrollar nuevos productos, la
                                identificación de las tendencias de uso, la determinación de la eficacia de nuestras
                                campañas de promoción y de operar y ampliar nuestras actividades comerciales;
                                <br/>· Cumplir con la ley y nuestras políticas y cooperar con las autoridades o con las
                                partes en conflicto en un procedimiento judicial o administrativo, o de otra manera
                                permitidos por la ley; y / o
                                <br/>· Identificación y protección contra amenazas de seguridad, destrucción de la
                                propiedad, así como las amenazas a nuestro sistema de Tecnologías de la información (TI)
                                y otros activos.
                                <br/>4.2 El PROVEEDOR también puede utilizar y almacenar información no personal y no
                                identificable, incluyendo la información anónima y los datos agregados, para diversos
                                fines, incluyendo, sin limitación, para la presentación de informes y para mejorar
                                nuestros servicios y plataforma digital, y TUIKIT puede compartir esos datos con
                                terceros.

                            </p>


                            <br/>
                            <p style={{'font-size': 18}}>
                                5. Realizar un seguimiento por el dispositivo móvil y el consentimiento para el uso de
                                datos de ubicación.
                            </p><br/>

                            <p>
                                <br/>5.1 El PROVEEDOR ofrece el servicio de seguimiento a través de modo que los
                                conductores proporcionarán su ubicación y/o por medio de la aplicación se les ubicará,
                                para efecto de control de la carga durante su trayecto. Con este fin se utilizaran los
                                medios tecnológicos existentes entre ellos google maps u otros que existan o llegaren a
                                existir.
                                <br/>5.2 Los conductores están de acuerdo para proporcionar su ubicación al seguimiento
                                de la carga, así como el uso de datos de localización de acuerdo con los términos de
                                esta política de privacidad.
                                <br/>5.3 La información de ubicación se obtiene a partir de la localización de los
                                teléfonos inteligentes de los servicios de los conductores u otro medio que exista a
                                futuro.

                            </p>

                            <br/>
                            <p style={{'font-size': 18}}>
                                6. Compartir Información Personal
                            </p><br/>

                            <p>
                                6.1 TUIKIT compartirá la información personal recopilada a través de la plataforma
                                digital sólo como se describe en esta política de privacidad, a menos que acepte otras
                                formas de compartir. En concreto, podemos compartir la información personal del usuario,
                                tercero proveedor u otra persona natural o jurídica que intervenga en el proceso, como
                                también los visitantes:
                                <br/>· Con los usuarios, terceros proveedores, conductores y otros participantes en la
                                cadena de transporte, así como contratistas de terceros o autorizada para prestar un
                                servicio relacionado con estos servicios y para fines de coordinación, ejecución y
                                seguimiento de la carga, y otros servicios legítimos para fines relacionados con esta
                                Política de privacidad;
                                <br/>· Con empresas y proveedores que nos ayudan a operar la plataforma TUIKIT,
                                proporcionando servicios tales como alojamiento de sitios web, análisis de datos,
                                tecnología de la información y la provisión de la infraestructura correspondiente, el
                                servicio al cliente, el envío de correo electrónico, auditorías y otros servicios
                                similares, con la condición de que estas terceras partes recibirán la información
                                personal sólo como y tratado por EL PROVEEDOR;
                                <br/>· Para cumplir con las autoridades judiciales, policivas o de otro nivel, donde la
                                ley así lo determine, también para responder a citaciones y otras obligaciones legales
                                fuera de su país de residencia;
                                <br/>· Para responder a las peticiones de las autoridades públicas y el gobierno
                                nacional o extranjero;
                                <br/>· Para proteger nuestros derechos, propiedad o las operaciones, o los de nuestros
                                usuarios y terceros proveedores;
                                <br/>· Para investigar, prevenir o tomar medidas contra las actividades ilegales o
                                sospechosos potenciales, fraude, amenazas a la seguridad personal de cualquier persona
                                natural o jurídica, del orden nacional o extranjera, o por violaciones de los términos y
                                condiciones de la plataforma digital;
                                <br/>· Con otras empresas de propiedad del PROVEEDOR, afiliados, filiales, socios,
                                inversionistas, etc, según lo permitido por la ley – EL PROVEEDOR es la parte
                                responsable de la gestión de información personal entre las empresas y con comprador
                                parcial o total del PROVEEDOR o una posible fusión o adquisición.

                            </p>

                            <br/>
                            <p style={{'font-size': 18}}>
                                7. Cómo puede acceder, modificar o solicitar la eliminación de su Información Personal
                            </p><br/>

                            <p>
                                7.1 El usuario y el tercero proveedor de nuestra plataforma digital puede solicitar
                                revisar la información personal que ha introducido. Para ello puede ponerse en contacto
                                con nosotros para solicitar que actualicemos o corrijamos la información personal
                                recopilada por nuestra plataforma digital en www.tuikit.com
                            </p>


                            <br/>
                            <p style={{'font-size': 18}}>
                                8. ¿Cómo protegemos su Información Personal
                            </p><br/>

                            <p>
                                <br/>8.1 El PROVEEDOR utiliza medidas técnicas y organizativas para proteger la
                                información personal obtenida a través de su plataforma digital. Tenemos medidas de
                                seguridad electrónicas y de procedimiento para proteger la información personal. Para
                                ello la información queda en los servidores de amazonwebservice (aws), quienes son
                                quienes protegen la información, u otra empresa que preste el servicio de servidores a
                                discreción de TUIKIT.
                                <br/>8.2 La información proporcionada por el usuario y el tercero proveedor, así como la
                                calidad y actualización de información será bajo su propio riesgo y EL PROVEEDOR no
                                tiene ninguna responsabilidad si el cliente no puede mantener la seguridad y
                                confidencialidad de dicha información.
                            </p>

                            <br/>
                            <p style={{'font-size': 18}}>
                                9. Servicios conectados por enlace
                            </p><br/>

                            <p>
                                9.1 No somos responsables de la violación de la privacidad, la información u otras
                                prácticas de cualquier tercero que opera desde cualquier sitio WEB o plataforma sea
                                nacional o extranjera. La inclusión de un enlace en la plataforma digital, no implica
                                reconocimiento de su sitio o servicio por EL PROVEEDOR, Si este enlace es ilegal y con
                                fines ilícitos, esto no compromete a la plataforma TUIKIT, por ende el usuario y tercero
                                proveedor debe tomar las medidas preventivas para acceder a dicho enlace antes de
                                continuar el acceso a cualquier sitio o utilizar cualquiera de los servicios del enlace
                                respectivo. Un enlace enviado directamente por la plataforma TUIKIT debe ser verificado
                                para evitar fraudes y engaños.
                                <br/>9.2 Tenga en cuenta que no somos responsables de las políticas y prácticas de
                                recopilación, uso y divulgación (incluyendo las prácticas de seguridad de datos) de
                                otras organizaciones, incluyendo cualquier información personal que divulga a otras
                                organizaciones a través de la conexión con nuestra plataforma digital.

                            </p>

                            <br/>
                            <p style={{'font-size': 18}}>
                                10. Mensajes promocionales
                            </p><br/>

                            <p>
                                10.1 EL PROVEEDOR puede enviar por correo electrónico, mensajes de texto (SMS) y otros
                                medios a los usuarios y terceros proveedores, promociones, bonificaciones, premios,
                                publicidad, etc, relacionados con los bienes y servicios DEL PROVEEDOR, de sus
                                afiliados, socios, inversionistas, etc.
                            </p>


                            <br/>
                            <p style={{'font-size': 18}}>
                                11. Cambios en esta política de privacidad
                            </p><br/>

                            <p>
                                11.1 Podemos cambiar esta Política de Privacidad en cualquier momento mediante la
                                publicación de una versión actualizada. </p>


                            <br/>
                            <p style={{'font-size': 18}}>
                                12. Período de retención
                            </p><br/>

                            <p>
                                12.1 TUIKIT conservará la información personal por el período que considere necesario
                                para cumplir con los fines descritos en esta política de privacidad, y con su objeto
                                social, teniendo en cuenta que la información personal de cada uno de sus clientes es un
                                activo de TUIKIT y así lo reconocen los usuarios y terceros proveedores.
                            </p>


                            <br/>
                            <p style={{'font-size': 18}}>
                                13. El uso de la plataforma digital por parte de menores
                            </p><br/>

                            <p>
                                13.1 Los usuarios menores de 18 (dieciocho) años de edad no deben proporcionar ninguna
                                información personal sin la ayuda, la asistencia y la autorización de su tutor legal.
                            </p>
                            <br/>
                            <p style={{'font-size': 18}}>
                                14. información sensible
                            </p><br/>

                            <p>
                                14.1 TUIKIT no envía, recopila ni revela información personal confidencial (por ejemplo,
                                información relacionada con el origen racial o étnico, las opiniones políticas, religión
                                u otras creencias) en o a través de la plataforma digital.
                            </p>


                            <br/>
                            <p style={{'font-size': 18}}>
                                15. Cómo ponerse en contacto con EL PROVEEDOR sobre sus preocupaciones y preguntas
                                acerca de la privacidad
                            </p><br/>

                            <p>
                                15.1 Si tiene alguna pregunta o comentario acerca de nuestras prácticas de privacidad,
                                por favor realizarla a través de la página web <a
                                href="https://www.tuikit.com">www.tuikit.com</a>
                            </p>
                        </Card>
                    </div>
                    <div className='mt-12 text-center d-flex justify-content-center'>
                        <Card title="CONTENIDO DE DATOS EN LA BASE DE TUKIT SAS "
                              style={{width: '100%', padding: 40, 'text-align': 'justify', 'margin-top': 81}}>


                            <p style={{'font-size': 18}}>
                                1. REGISTRO.
                            </p> <br/>
                            <p>
                                Cada usuario y tercero proveedor debe registrarse para obtener una cuenta, dicho
                                registro debe ser completo, exacto, actualizado, veraz, sin fraudes ni falsedad en la
                                información, engaño en la misma, con anexo de los soportes respectivos; lo contrario
                                acarrea su desvinculación. Para obtener una Cuenta se debe tener como mínimo 18 años, o
                                tener la mayoría de edad legal en su jurisdicción estatal o que cumpla los requisitos si
                                es en un país extranjero. El registro de la cuenta le requiere que comunique a TUIKIT
                                determinada información personal, como su nombre, dirección, Rut, cámara de comercio,
                                fotocopia cédula del representante legal, número de teléfono móvil, datos del vehículo,
                                como seguros, tarjeta de propiedad, fotografías, revisión tecnomecánica, número de
                                cuenta bancaria, etc, así como por lo menos un método de pago válido (bien una tarjeta
                                de crédito, débito u otro sistema). Esta información será amplia o directa según sea un
                                usuario o un tercero proveedor; su incumplimiento impedirá la continuidad o acceso a la
                                plataforma. Cuando se asigne un nombre de usuario y contraseña, los USUARIOS Y TERCEROS
                                PROVEEDORES se comprometen a mantenerlo de manera segura y secreta, de tal manera que,
                                si un tercero ajeno a ellos lo utiliza, el usuario y tercero proveedor se hacen
                                responsables de cualquier acto irregular o ilícito que genere un daño a TUIKIT o un
                                tercero, siendo desvinculado inmediatamente de la plataforma y poniendo en conocimiento
                                el hecho a las autoridades respectivas, exonerando a TUIKIT de toda responsabilidad. La
                                información de los pagos por concepto del servicio de carga solo la conocerá la pasarela
                                de pagos respectiva, sin que TUIKIT tenga acceso a ella.
                            </p><br/>


                            <p style={{'font-size': 18}}>
                                2. USO DE INFORMACIÓN
                            </p>
                            <br/>
                            <p>
                                Al ingresar y enviar información en los formularios o registro, el usuario y tercero
                                proveedor aceptan y autorizan el envío de datos personales, de manera expresa y
                                voluntaria al PROVEEDOR o a quien represente sus derechos para que directamente o a
                                través de terceros, el PROVEEDOR, su casa matriz, las filiales, subordinadas y/o
                                asociadas, así como todo tipo de entidades con las que el PROVEEDOR tenga convenios o
                                acuerdos, puedan en cualquier tiempo acceder, almacenar, suministrar, reportar,
                                procesar, divulgar, consultar, utilizar, compilar, tratar y compartir, sea de forma
                                verbal, escrita o a través de cualquier medio, información personal, de carácter
                                comercial, financiero, crediticio y de servicios aquí consignada, la cual se recauda con
                                fines comerciales, operativos y de seguridad y con el fin de que se pueda brindar en la
                                debida forma y con la adecuada diligencia información sobre los productos comerciales y
                                operativos del PROVEEDOR.

                                <br/>El acceso, almacenamiento, suministro, reporte, procesamiento, divulgación,
                                consulta, utilización, compilamiento y tratamiento de los datos personales del usuario
                                se hace con arreglo a la Ley de Habeas Data -Ley 1266 de 2008- y todas las demás
                                disposiciones del ordenamiento jurídico Colombiano que la complementen, modifiquen o
                                reemplacen.
                                <br/>El usuario y tercero proveedor autorizan el envío de información comercial, de
                                productos y servicios del Proveedor o sus asociados, afiliados, entidades privadas o
                                públicas de índole nacional o extranjera, que promocionen productos, servicios,
                                artículos u otros medios, noticias, beneficios, bonificaciones, etc, a su terminal
                                móvil, correo electrónico, aplicaciones de mensajería para teléfono inteligente y/o
                                cualquier otro mecanismo de contacto.

                            </p><br/>

                            <p style={{'font-size': 18}}>
                                PARAGRAFO: PERMISO DE LOCALIZACION EN PRIMERO Y SEGUNDO PLANO
                            </p><br/>

                            <p>
                                El USUARIO DE CARGA y EL TERCERO PROVEEDOR, aceptan y permiten de manera amplia el acceso a la localización y/o ubicación de su teléfono móvil y demás dispositivos tecnológicos registrados en la app de Tuikit, con el objetivo de hacer el seguimiento constante de la mercancía en tiempo real, a fin que se obtenga fácilmente por medio del mapa de geolocalización de Googlemaps, conocer por donde marcha y/o transita el vehículo asignado al servicio, garantizando incluso, cuando están activos los dispositivos tecnológicos y la plataforma y/o aplicación de TUIKIT (PRIMER PLANO) y/o cuando estos se encuentren en modo cerrado, suspenso, bloqueado, o no esta en uso (SEGUNDO PLANO).
                                Para este efecto, es obligatorio que el TERCERO PROVEEDOR (TRANSPORTADOR), porte el dispositivo electrónico de manera constante junto con el vehículo donde se transporta la carga, debiendo tener DATOS MOVILES PERMANENTES en el celular u otro dispositivo.
                                Se aclara, que TUIKIT recopila datos para habilitar la ubicación en primero y segundo plano, y así lo acepta el usuario de carga y el tercero proveedor.

                            </p>
                            <br/>

                            <p style={{'font-size': 18}}>
                                3. Requisitos y conducta del usuario y Tercero proveedor
                            </p>
                            <br/>
                            <p>
                                El usuario y tercero proveedor no podrán ceder o transferir cuenta de registro personal
                                a cualquier otra persona o entidad. Se comprometen a cumplir con todas las leyes
                                aplicables en Colombia y en el exterior al utilizar la plataforma TUIKIT, la que se
                                usará únicamente con fines legítimos, si contraviene esta disposición, se enfrentará a
                                la justicia de acuerdo a la ley, exonerando a TUIKIT de cualquier responsabilidad civil,
                                comercial, penal o de otra naturaleza, ya que se presume la buena fe del usuario y
                                tercero proveedor en todos sus actos. En la ejecución del servicio, el usuario y el
                                tercero proveedor no causarán estorbos, molestias, incomodidades o daños a la propiedad,
                                de ninguno de ellos entre sí, ni a terceros ajenos al servicio de la plataforma. Se
                                exigirá a los terceros proveedores que faciliten un documento de identidad, ARL, EPS,
                                Certificado de sanidad, tanto del conductor, del vehículo o de los auxiliares de carga u
                                otro elemento de verificación de identidad, salud, bienestar, para el acceso a alguna
                                entidad pública o privada destino de la carga y aceptan que se le podrá denegar el
                                acceso si se niega a facilitar estos documentos, por lo que responderán directa y
                                solidariamente el conductor y propietario del vehículo por todos los perjuicios que le
                                causen al usuario de la carga. El usuario y los terceros proveedores deberán cumplir con
                                todas la normas que impongan las autoridades con respecto a pandemias, epidemias, actos
                                de seguridad nacional o extranjera y cualquier otro que implique un compromiso social,
                                estatal o universal, con Colombia u otra nación extranjera.
                            </p><br/>

                            <p style={{'font-size': 18}}>
                                4. PREGUNTAS, QUEJAS Y RECLAMOS
                            </p>
                            <br/>
                            <p>
                                TUIKIT facilitará a los usuarios de carga y terceros proveedores y demás personas que
                                tengan relación directa con los bienes y servicios ofrecidos en el PORTAL DE TUIKIT, un
                                PQR con el fin de atender preguntas, quejas y reclamos, el cual estará en el portal
                                www.tuikit.com, como en los canales electrónicos o telefónico.

                                <br/>
                                5. Si tiene alguna pregunta o comentario acerca de nuestras prácticas de privacidad,
                                manejo de datos, o términos y condiciones, por favor realizarla a través de la página
                                web www.tuikit.com
                                <br/>
                                6. Cualquier aspecto no comprendido en este capítulo de contenido de datos, se llenará o
                                complementará IN BONAM PARTEM, con lo estipulado en el contenido de términos y
                                condiciones y política de privacidad.

                            </p><br/>


                        </Card>
                    </div>
                </Col>
            </>
        )
    }
}

export default Terms

