import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Container, Row, Col} from 'reactstrap'
import {API} from '../../api/index'
import {Card, Statistic} from 'antd'

function mapStateToProps({User}) {
    return {
        token: User.token
    }
}

class Header extends Component {

    state = {
        data: {}
    }

    componentDidMount() {
        this.getData()
    }

    getData = async () => {
        const response = await API.Dashboard.getCardCounts(this.props.token)
        if (response.data) {
            this.setState({
                data: response.data
            })
        }
    }

    render() {
        return (
            <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'>
                <Container fluid>
                    <div className='header-body'>
                        <Row>
                            <Col lg='6' xl='3'>
                                <Card
                                    className='mb-4 mb-xl-0'
                                    title={(
                                        <Statistic title='Transportadores nuevos' value={this.state.data.driver}/>
                                    )}
                                    extra={(
                                        <>
                                            <div
                                                className='icon icon-shape bg-primary text-white rounded-circle shadow'>
                                                <i className='fas fa-truck'></i>
                                            </div>
                                        </>
                                    )}
                                />
                            </Col>
                            <Col lg='6' xl='3'>
                                <Card
                                    className='mb-4 mb-xl-0'
                                    title={(
                                        <Statistic title='Transportadores' value={this.state.data.driverAll}/>
                                    )}
                                    extra={(
                                        <>
                                            <div
                                                className='icon icon-shape bg-primary text-white rounded-circle shadow'>
                                                <i className='fas fa-truck'></i>
                                            </div>
                                        </>
                                    )}
                                />
                            </Col>
                            <Col lg='6' xl='3'>
                                <Card
                                    className='mb-4 mb-xl-0'
                                    title={(
                                        <Statistic title='Servicios realizados' value={this.state.data.order}/>
                                    )}
                                    extra={(
                                        <>
                                            <div
                                                className='icon icon-shape bg-success text-white rounded-circle shadow'>
                                                <i className='fas fa-clipboard-list'></i>
                                            </div>
                                        </>
                                    )}
                                />
                            </Col>
                            <Col lg='6' xl='3'>
                                <Card
                                    className='mb-4 mb-xl-0'
                                    title={(
                                        <Statistic title='Servicios cancelados' value={this.state.data.orderCanceled}/>
                                    )}
                                    extra={(
                                        <>
                                            <div
                                                className='icon icon-shape bg-success text-white rounded-circle shadow'>
                                                <i className='fas fa-clipboard-list'></i>
                                            </div>
                                        </>
                                    )}
                                />
                            </Col>
                            <Col lg='6' xl='3' style={{"margin-top": "20px"}}>
                                <Card
                                    className='mb-4 mb-xl-0'
                                    title={(
                                        <Statistic title='Polizas generadas' value={this.state.data.countInsurance}/>
                                    )}
                                    extra={(
                                        <>
                                            <div
                                                className='icon icon-shape bg-success text-white rounded-circle shadow'>
                                                <i className='fas fa-clipboard-list'></i>
                                            </div>
                                        </>
                                    )}
                                />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        )
    }
}

export default connect(mapStateToProps)(Header)

