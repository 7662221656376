import Account from './Account'
import Dashboard from './Dashboard'
import Drivers from './Drivers'
import Order from './Order'
import ClientOrder from './ClientOrder'
import User from './User'
import Vehicle from './Vehicle'
import Client from './Client'
import LegalDocuments from './LegalDocuments'
import Enterprise from './Enterprise'
import Pqr from './Pqr'
import Insurance from './Insurance'


export const API = {
  Account,
  Dashboard,
  Drivers,
  Order,
  ClientOrder,
  User,
  Vehicle,
  Client,
  LegalDocuments,
  Enterprise,
  Pqr,
  Insurance
}
