import React, { Component } from 'react'
import {
  Container,
  Row,
  Col
} from 'reactstrap'
import { Tabs } from 'antd'
import MainHeader from 'components/Headers/MainHeader.jsx'
import DriversTable from '../components/Tables/DriversTable'
import { connect } from 'react-redux'
import { API } from '../api'
import { errorAlert } from '../components/Forms/formUtil'
const { TabPane } = Tabs

function mapStateToProps({ User }) {
  return {
    token: User.token
  }
}

class Drivers extends Component {

  state = {
    drivers: [],
    driversWithEnterprise: [],
  }

  componentDidMount() {
    this._setData()
  }

  updateData = async () => {
    this._setData()
  }

  _setData = async () => {
    this._getDrivers()
    this._getDrivers(true)
  }

  _getDrivers = async withEnterprise => {
    const { data } = await API.Drivers.getDrivers(this.props.token, withEnterprise)
    if (data.error) {
      errorAlert(data.error)
    } else {
      if (withEnterprise) {
        this.setState({
          driversWithEnterprise: data
        })
      } else {
        this.setState({
          drivers: data
        })
      }
    }
  }

  render() {
    return (
      <>
        <MainHeader />
        <Container className='mt--7' fluid>
          <Row>
            <Col>
              <Tabs type='card' tabBarStyle={{ margin: '0', border: '0' }}>
                <TabPane tab='Persona natural' key='1'>
                  <DriversTable
                    data={this.state.drivers}
                  />
                </TabPane>
                <TabPane tab='Empresa' key='2'>
                  <DriversTable
                    data={this.state.driversWithEnterprise}
                    isEnterprise
                  />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </Container >
      </>
    )
  }
}

export default connect(mapStateToProps)(Drivers)
