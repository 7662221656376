import React, { Component } from 'react'
import { API } from '../../api'
import { Form, Button, Select } from 'antd'
import { connect } from 'react-redux'
import { successAlert, errorAlert } from './formUtil'
const { Option } = Select
const { Item } = Form

const itemName = 'servicio'

function mapStateToProps({ User, Profile }) {
  return {
    token: User.token,
    id: Profile._id
  }
}

class AssignDriverForm extends Component {
  state = {
    avaliableDrivers: [],
    avaliableVehicles: []
  }

  componentDidMount() {
    this._loadDriversData()
    this._loadVehiclesData()
  }

  _loadDriversData = async () => {
    const { data } = await API.Drivers.getEnterpriseDrivers(
      this.props.token,
      this.props.id
    )
    if (data.error) {
      errorAlert(data.error)
    } else {
      this.setState({
        avaliableDrivers: data
      })
    }
  }

  _loadVehiclesData = async () => {
    const { data } = await API.Vehicle.getEnterpriseVehicles(
      this.props.token,
      this.props.id
    )
    if (data.error) {
      errorAlert(data.error)
    } else {
      this.setState({
        avaliableVehicles: data
      })
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields(this._update)
  }

  _update = async (err, values) => {
    if (!err) {
      const { _id } = this.props.item
      const { data } = await API.Order.update(this.props.token, {
        _id,
        ...values
      })
      if (data.updated) {
        successAlert(itemName, true)
        this.props.onOk()
        this.props.form.resetFields()
        this.props.updateData()
      } else {
        errorAlert(data.error)
      }
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Form onSubmit={this.handleSubmit}>
        <Item>
          {getFieldDecorator('driverId',
            { rules: [{ required: true, message: 'El conductor es obligatorio' }] }
          )(
            <Select
              placeholder='Conductor'
              optionFilterProp='children'
              showSearch
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.avaliableDrivers.map((driver, index) => (
                <Option key={index} value={driver._id}>
                  {`${driver.identification} - ${driver.name}`}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        <Item>
          {getFieldDecorator('vehicleId',
            { rules: [{ required: true, message: 'El vehiculo es obligatorio' }] }
          )(
            <Select
              placeholder='Vehiculo'
              optionFilterProp='children'
              showSearch
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.avaliableVehicles.map((vehicle, index) => (
                <Option key={index} value={vehicle._id}>
                  {vehicle.licensePlate}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        <Button type='primary' htmlType='submit' block>
          Asignar
          </Button>
      </Form>
    )
  }
}

const AssignForm = Form.create({ name: 'assign_driver_form' })(AssignDriverForm)
export default connect(mapStateToProps)(AssignForm)
