import React from 'react'
import { connect } from 'react-redux'
import { NavLink as NavLinkRRD, Link } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from 'reactstrap'

import moment from 'moment'

class Sidebar extends React.Component {
  state = {
    collapseOpen: false,
    currentDate: moment()
  }
  constructor(props) {
    super(props)
    this.activeRoute.bind(this)
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : ''
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    })
  }
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    })
  }
  _logout = () => {
    this.props.dispatch({
      type: 'LOGOUT'
    })
  }
  // creates the links that appear in the left menu / Sidebar
  createLinks = routes => {
    return routes.map((prop, key) => {
      let option
      if (!prop.hidden) {
        option = (
          <NavItem key={key}>
            <NavLink
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              onClick={this.closeCollapse}
              activeClassName='active'
            >
              <i className={prop.icon} />
              {prop.name}
            </NavLink>
          </NavItem>
        )
      }
      return option
    })
  }

  componentDidMount() {
    this.tick(true)
  }

  componentWillUnmount() {
    this.clearTick(false)
  }

  tick = () => {
    this.interval = setInterval(() => {
      this.setState({
        currentDate: moment()
      })
    }, 1000)
  }

  clearTick = () => {
    clearInterval(this.interval)
  }

  render() {
    const { routes, logo } = this.props
    let navbarBrandProps
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link
      }
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: '_blank'
      }
    }
    return (
      <Navbar
        className='navbar-vertical fixed-left navbar-light bg-white'
        expand='md'
        id='sidenav-main'
      >
        <Container fluid>
          {logo ? (
            <NavbarBrand className='pt-0' {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className='navbar-brand-img'
                src={logo.imgSrc}
              />
            </NavbarBrand>
          ) : null}
          <button
            className='navbar-toggler'
            type='button'
            onClick={this.toggleCollapse}
          >
            <span className='navbar-toggler-icon' />
          </button>
          <Collapse navbar isOpen={this.state.collapseOpen}>
            <div className='navbar-collapse-header d-md-none'>
              <Row>
                {logo ? (
                  <Col className='collapse-brand' xs='6'>
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className='collapse-close' xs='6'>
                  <button
                    className='navbar-toggler'
                    type='button'
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav navbar>
              {this.createLinks(routes)}
              <li className='nav-item d-block d-lg-none'>
                <div
                  className='nav-link'
                  style={{ cursor: 'pointer' }}
                  onClick={this._logout}
                >
                  <i className='fas fa-sign-out-alt text-primary' />
                  Cerrar sesión
                </div>
              </li>
            </Nav>
          </Collapse>
          <span className='d-none d-md-block'>{this.state.currentDate.format('LLL')}</span>
        </Container>
      </Navbar>
    )
  }
}

Sidebar.defaultProps = {
  routes: [{}]
}

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to='...'>...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href='...'>...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  })
}

export default connect(null)(Sidebar)
