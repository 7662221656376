import React, { Component } from 'react'
import { API } from '../../api'
import { connect } from 'react-redux'
import { Button, Form, Input, Steps } from 'antd'
import { successAlert, errorAlert } from './formUtil'
import VehicleBasicsForm from './VehicleBasicsForm'
import VehicleImagesForm from './VehicleImagesForm'
import VehicleDocumentsForm from './VehicleDocumentsForm'
import VehicleProperty from './VehicleProperty'
import VehicleTech from './VehicleTech'
const { Step } = Steps

function mapStateToProps({ User, Profile }) {
  return {
    token: User.token,
    id: Profile._id
  }
}

const itemName = 'vehiculo'

class VehicleForm extends Component {
  state = {
    vehicle: {},
    current: 0
  }

  makeStep = current => {
    this.setState({ current })
  }

  stepSubmit = values => {
    const { vehicle } = this.state
    this.setState(
      {
        vehicle: { ...vehicle, ...values }
      },
      () => {
        if (this.state.current === 4) {
          this._executeAction()
        } else {
          this.makeStep(this.state.current + 1)
        }
      }
    )
  }

  _executeAction = () => {
    if (this.props.item) {
      this._update()
    } else {
      this._register()
    }
  }

  _register = async () => {
    const { data } = await API.Vehicle.create(
      this.props.token,
      {
        _idTypeVehicle: this.state.vehicle._idTypeVehicle,
        bodyWork: this.state.vehicle.bodyWork,
        documents: [
          {
            name: 'Soat',
            expeditionDate: this.state.vehicle.dateInitSoat._d,
            expirationDate: this.state.vehicle.dateEndSoat._d,
            isActive: false,
            images: [
              this.state.vehicle.frontSoat,
              this.state.vehicle.backSoat
            ],
            isPermition: false
          },
          {
            name: 'Tarjeta de propiedad',
            expeditionDate: this.state.vehicle.dateInitProperty._d,
            expirationDate: '',
            isActive: false,
            images: [
              this.state.vehicle.frontProperty,
              this.state.vehicle.backProperty
            ],
            isPermition: false
          },
          {
            name: 'Revisión Tecnicomecánica',
            expeditionDate: '',
            expirationDate: this.state.vehicle.dateEndTec._d,
            isActive: false,
            images: [
              this.state.vehicle.imgTec
            ],
            isPermition: false
          }
        ],
        images: [this.state.vehicle.images],
        model: this.state.vehicle.model,
        licensePlate: `${this.state.vehicle.licensePlate}`,
        brand: this.state.vehicle.brand,
        loadingCapacity: this.state.vehicle.loadingCapacity + `${this.state.vehicle.loadingCapacityNum}`,
        isActive: false
      }
    )
    if (data._id) {
      successAlert(itemName)
      this._success(false)
      this.setState({ vehicle: {}, current: 0 })
    } else {
      errorAlert(data.error)
    }
  }

  _update = async () => {
    const { data } = await API.Vehicle.update(
      this.props.token,
      this.state.vehicle
    )
    if (data.updated) {
      successAlert(itemName, true)
      this._success()
    } else {
      errorAlert(data.error)
    }
  }

  _success = resetFields => {
    this.props.onOk()
    if (resetFields) {
      this.props.form.resetFields()
    }
    if (this.props.updateData) {
      this.props.updateData()
    }
  }

  render() {
    const { current } = this.state
    const steps = [
      {
        title: 'Datos basicos',
        content: <VehicleBasicsForm submit={this.stepSubmit} />
      },
      {
        title: 'Especificaciones',
        content: <VehicleImagesForm submit={this.stepSubmit} />
      },
      {
        title: 'SOAT',
        content: <VehicleDocumentsForm submit={this.stepSubmit} />
      },
      {
        title: 'Tarjeta de propiedad',
        content: <VehicleProperty submit={this.stepSubmit} />
      },
      {
        title: 'Tecnicomecánica',
        content: <VehicleTech submit={this.stepSubmit} />
      }
    ]

    return (
      <>
        <Steps direction="vertical" current={current}>
          {steps.map(step => (
            <Step key={step.title} title={step.title} />
          ))}
        </Steps>
        <div className='steps-content mt-4'>{steps[current].content}</div>
        <div className='steps-action'>
          {current > 0 && (
            <Button
              onClick={this.makeStep.bind(this, current - 1)}
              block
              className='mt-4'
            >
              Anterior
            </Button>
          )}
        </div>
      </>
    )
  }
}

export default connect(mapStateToProps)(VehicleForm)
