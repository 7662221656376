function Profile (data, dispatch, option) {
  switch (option) {
    case 'SET':
      dispatch({
        type: 'SET_PROFILE',
        payload: {
          ...data
        }
      })
      break
    case 'REMOVE':
      dispatch({
        type: 'REMOVE_PROFILE'
      })
      break
    default:
      dispatch({
        type: 'REMOVE_PROFILE'
      })
  }
}

export default Profile
