import React, {Component} from 'react'
import {API} from '../../api'
import {connect} from 'react-redux'
import {Button, Steps} from 'antd'
import {successAlert, errorAlert} from './formUtil'
import DestinationForm from './Order/DestinationForm'
import TypeLoadForm from './Order/TypeLoadForm'
import TypeVehicleForm from './Order/TypeVehicleForm'
import DateForm from './Order/DateForm'
import Price from './Order/PriceForm'

const {Step} = Steps

function mapStateToProps({User, Profile}) {
    return {
        token: User.token,
        id: Profile._id
    }
}

const itemName = 'servicio'

class OrderForm extends Component {
    state = {
        order: {},
        current: 0
    }

    makeStep = current => {
        this.setState({current})
    }

    stepSubmit = values => {
        const {order} = this.state
        this.setState(
            {
                order: {...order, ...values}
            },
            () => {
                if (this.state.current === 4) {
                    this._executeAction()
                } else {
                    this.makeStep(this.state.current + 1)
                }
            }
        )
    }

    _executeAction = async () => {
        const origin = JSON.parse(localStorage.getItem('origin'))
        const destination = JSON.parse(localStorage.getItem('destination'))
        const images = JSON.parse(localStorage.getItem('images'))
        const typeload = localStorage.getItem('typeload')
        const typevehicle = localStorage.getItem('typevehicle')
        const dateform = JSON.parse(localStorage.getItem('dateform'))
        const meditions = JSON.parse(localStorage.getItem('meditions'))
        const km = localStorage.getItem('km')
        const price = parseInt(localStorage.getItem('price'))


        var date = new Date(dateform.dateReservation)
        const hourSelect = date.getHours()
        const minSelect = date.getMinutes()
        const hourService = (hourSelect > 12 ? hourSelect - 12 : hourSelect) + ':' + (minSelect < 10 ? '0' + minSelect : minSelect) + (hourSelect > 12 ? ' PM' : ' AM')

        const order = {
            origin,
            destination,
            images,
            typeLoad: typeload,
            typeVehicleId: typevehicle,
            description: dateform.description,
            reservationDate: dateform.dateReservation,
            high: meditions.high ? `${meditions.high} ${meditions.high_medition}` : '',
            weight: meditions.weight ? `${meditions.weight} ${meditions.weight_medition}` : '',
            width: meditions.width ? `${meditions.width} ${meditions.width_medition}` : '',
            volume: meditions.volume ? `${meditions.volume} ${meditions.volume_medition}` : '',
            pieces: meditions.pieces ? `${meditions.pieces} ${meditions.pieces_medition}` : '',
            bottom: meditions.bottom ? `${meditions.bottom} ${meditions.bottom_medition}` : '',
            price,
            hourService,
        }
        const {data} = await API.Order.create(
            this.props.token,
            order
        )
        if (data._id) {
            localStorage.removeItem('origin')
            localStorage.removeItem('destination')
            localStorage.removeItem('images')
            localStorage.removeItem('typeload')
            localStorage.removeItem('typevehicle')
            localStorage.removeItem('dateform')
            localStorage.removeItem('meditions')
            localStorage.removeItem('km')
            localStorage.removeItem('price')


            successAlert('servicio', false)
            this.handleReset()
            this.setState({current: 0})
            this.props.updateData()
        } else {
            errorAlert(data.error)
        }
    }

    handleReset = () => {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        this.setState({
            itemvalues: [{}]
        });
    };


    render() {
        const {current} = this.state
        const steps = [
            {
                title: '',
                content: <DestinationForm submit={this.stepSubmit}/>
            },
            {
                title: '',
                content: <TypeLoadForm submit={this.stepSubmit}/>
            },
            {
                title: '',
                content: <TypeVehicleForm submit={this.stepSubmit}/>
            },
            {
                title: '',
                content: <DateForm submit={this.stepSubmit}/>
            },
            {
                title: 'Precio',
                content: <Price submit={this.stepSubmit}/>
            }
        ]

        return (
            <>
                <Steps current={current}>
                    {steps.map(step => (
                        <Step key={step.title} title={step.title}/>
                    ))}
                </Steps>
                <div className='steps-content mt-4'>{steps[current].content}</div>
                <div className='steps-action'>
                    {current > 0 && (
                        <Button
                            onClick={this.makeStep.bind(this, current - 1)}
                            block
                            className='mt-4'
                        >
                            Anterior
                        </Button>
                    )}
                    {
                        current == 0 && (
                            <Button
                                type='primary'
                                onClick={this.makeStep.bind(this, current + 1)}
                                block
                                className='mt-4'
                            >
                                Siguiente
                            </Button>
                        )
                    }
                </div>
            </>
        )
    }
}

export default connect(mapStateToProps)(OrderForm)
