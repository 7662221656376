import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from './reducers'

const persistConfig = {
  key: 'root',
  storage
}

const persistdReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(persistdReducer)
const persistor = persistStore(store)

export { store, persistor }
