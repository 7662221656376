import React from 'react'
import { Tag } from 'antd'
import { getRoleTraduction } from '../../utils/RoleConditions'

const rolInfo = [
  {
    rol: 'administrator',
    color: 'blue'
  },
  {
    rol: 'accountancy',
    color: 'green'
  },
  {
    rol: 'enterprise',
    color: 'purple'
  },
  {
    rol: 'driver',
    color: 'cyan'
  },
  {
    rol: 'client',
    color: 'geekblue'
  },
  {
    rol: 'enterprisedriver',
    color: 'geekblue'
  },
  {
    rol: 'cliententerprise',
    color: 'geekblue'
  },
  {
    rol: 'soport',
    color: 'geekblue'
  }
]


const RolTag = ({ rol }) => {
  const selectedItem = (rolInfo.filter(item => item.rol === rol))[0]
  return (
    <Tag color={selectedItem ? selectedItem.color : 'red'}>
      {selectedItem ? getRoleTraduction(selectedItem.rol) : 'Error'}
    </Tag>
  )
}

RolTag.defaultProps = {
  rol: ''
}

export default RolTag