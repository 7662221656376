import React, { Component } from 'react'
import { API } from '../../api'
import { connect } from 'react-redux'
import { Button, Steps } from 'antd'
import DetailPay from './Pay/DetailPay'
import Pay from './Pay/Pay'
const { Step } = Steps

function mapStateToProps({ User, Profile }) {
  return {
    token: User.token,
    id: Profile._id
  }
}

class OrderForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      order: {},
      current: 0
    }
  }

  makeStep = current => {
    this.setState({ current })
  }

  stepSubmit = values => {
    const { order } = this.state
    this.setState(
      {
        order: { ...order, ...values }
      },
      () => {
        if (this.state.current === 2) {
          console.log('Paso 2')
        } else {
          this.makeStep(this.state.current + 1)
        }
      }
    )
  }

  backStep = values => {
    this.setState({ current: 0 })
  }

  render() {
    const { current } = this.state
    const steps = [
      {
        title: 'Detalle de pago',
        content: <DetailPay submit={this.stepSubmit} order={this.props.order} />
      },
      {
        title: 'Pago',
        content: <Pay submit={this.stepSubmit} order={this.props.order} back={this.backStep} />
      },
    ]

    return (
      <>
        <Steps current={current}>
          {steps.map(step => (
            <Step key={step.title} title={step.title} />
          ))}
        </Steps>
        <div className='steps-content mt-4'>{steps[current].content}</div>
        <div className='steps-action'>
          {current > 0 && (
            <Button
              onClick={this.makeStep.bind(this, current - 1)}
              block
              className='mt-4'
            >
              Anterior
            </Button>
          )}
          {
            current == 0 && (
              <Button
                type='primary'
                onClick={this.makeStep.bind(this, current + 1)}
                block
                className='mt-4'
              >
                Siguiente
              </Button>
            )
          }
        </div>
      </>
    )
  }
}

export default connect(mapStateToProps)(OrderForm)
