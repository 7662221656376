import React, { Component } from 'react'
import { Container } from 'reactstrap'
import MainHeader from 'components/Headers/MainHeader.jsx'
import { connect } from 'react-redux'
import { API } from '../api'
import CancelTable from '../components/Tables/CancelTable'
import { errorAlert } from '../components/Forms/formUtil'

function mapStateToProps({ User, Profile }) {
  return {
    token: User.token,
    profile: Profile
  }
}

class Canceled extends Component {
  state = {
    data: []
  }

  componentDidMount() {
    this._getData()
  }

  updateData = async () => {
    this._getData()
  }

  _getData = async () => {
    const { data } = await API.Order.getCanceled(this.props.token)
    if (data.error) {
      errorAlert(data.error)
    } else {
      this.setState({ data })
    }
  }

  render() {
    return (
      <>
        <MainHeader lines />
        <Container className='mt--7' fluid>
          <CancelTable updateData={this.updateData} data={this.state.data} />
        </Container>
      </>
    )
  }
}

export default connect(mapStateToProps)(Canceled)
