import React from 'react'
import { Badge } from 'antd'

const statusList = {
  EnProceso: 'processing',
  Pendiente: 'default',
  Solucionado: 'success'
}

const PqrStatusBadge = ({ status }) => {
  return (
    <Badge status={statusList[status]} text={status} />
  )
}

export default PqrStatusBadge
