import React, { Component } from 'react'
import {
  Container,
  Row,
  Col
} from 'reactstrap'
import MainHeader from 'components/Headers/MainHeader.jsx'
import { connect } from 'react-redux'
import { API } from '../api'
import DocumentsTable from '../components/Tables/DocumentsTable'
import { errorAlert } from '../components/Forms/formUtil'
import { isAdmin } from '../utils/RoleConditions'
import LegalDocumentsOverviewer from '../components/Overviewers/LegalDocumentsOverviewer'

function mapStateToProps({ User, Profile }) {
  return {
    token: User.token,
    rol: Profile.rol
  }
}

class LegalDocuments extends Component {
  state = {
    data: []
  }

  componentDidMount() {
    this._setData()
  }

  updateData = async () => {
    this._setData()
  }

  _setData = async () => {
    const { data } = await API.LegalDocuments.findAll(this.props.token)
    if (data.error) {
      errorAlert(data.error)
    } else {
      this.setState({
        data
      })
    }
  }

  render() {
    return (
      <>
        <MainHeader lines />
        <Container className='mt--7' fluid>
          {isAdmin(this.props.rol) ?
            <Row>
              <Col>
                <DocumentsTable
                  updateData={this.updateData}
                  data={this.state.data}
                />
              </Col>
            </Row>
            :
            <LegalDocumentsOverviewer data={this.state.data} />
          }
        </Container>
      </>
    )
  }
}

export default connect(mapStateToProps)(LegalDocuments)