import React from 'react'
import { Icon, notification } from 'antd'

export const getPrefix = (icon, isNotIcon) => {
  return isNotIcon ? (
    <span style={{ color: 'rgba(0,0,0,.25)' }}>{icon}</span>
  ) : (
    <Icon type={icon} style={{ color: 'rgba(0,0,0,.25)' }} />
  )
}

export const successAlert = (itemName, isEdit) => {
  notification.open({
    type: 'success',
    message: 'Hecho',
    description: `El ${itemName} se ha ${
      isEdit ? 'actualizado' : 'registrado'
    } correctamente`
  })
}

export const errorAlert = responseError => {
  notification.open({
    type: 'error',
    message: 'Error',
    description: responseError
      ? responseError.toString()
      : 'Ha ocurrido un error inesperado'
  })
}

export const infoAlert = description => {
  notification.open({
    type: 'info',
    message: 'Información',
    description
  })
}
