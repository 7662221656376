import React, {Component} from 'react'
import {API} from '../../api'
import {Form, Input, Icon, Button, Upload, Select, Modal} from 'antd'
import {connect} from 'react-redux'
import {getPrefix, successAlert, errorAlert} from './formUtil'
import {convertBase64} from '../../utils/image'
import {getRoleTraduction} from '../../utils/RoleConditions'

const {Item} = Form
const {Option} = Select

function mapStateToProps({User}) {
    return {
        token: User.token
    }
}

const permitedRoles = ['administrator', 'accountancy','soport']

class UserForm extends Component {
    constructor() {
        super();
        this.state = {
            previewVisible: false,
            previewImage: '',
            previewTitle: '',
            fileList: [],
        }
    }

    handleSubmit = e => {
        e.preventDefault()
        this.props.form.validateFields(this._mainAction)
    }

    _mainAction = async (err, values) => {
        let {fileList} = this.state
        if (!err) {

            let newDocuments = []
            for(let document of fileList){
                let newName = document.name.split(".")
                newDocuments.push({
                    name : newName[0].toUpperCase(),
                    expeditionDate : "",
                    expirationDate : "",
                    isActive : false,
                    images : [document.thumbUrl]
                })
            }
            values.documents = newDocuments
            convertBase64(values.image.originFileObj, image => {
                const rol = values.rol || this.props.rol
                this._register({...values, image, rol})
            })
        }
    }

    _register = async values => {
        const {data} = await API.User.register(this.props.token, values)
        if (data._id) {
            const itemName = this.props.isBackofficeUser ? 'Usuario' : `usuario ${getRoleTraduction(this.props.rol)}`
            successAlert(itemName)
            this._success(true)
        } else {
            errorAlert(data.error)
        }
    }

    _success = resetFields => {
        this.props.onOk()
        if (resetFields) {
            this.props.form.resetFields()
        }
        if (this.props.updateData) {
            this.props.updateData()
        }
    }

    handleCancel = () => this.setState({previewVisible: false});

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };

    handleChange = ({fileList}) => this.setState({fileList});

    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    render() {
        const {previewVisible, previewImage, fileList, previewTitle} = this.state;
        const {isBackofficeUser} = this.props
        const {getFieldDecorator} = this.props.form
        const uploadButton = (
            <Icon type="camera" style={{fontSize: '16px', color: '#08c'}}
                  theme="outlined"/>
        );
        return (
            <>
                <Form onSubmit={this.handleSubmit}>
                <Item>
                    {getFieldDecorator('identification', {
                        rules: [
                            {
                                required: true,
                                message: isBackofficeUser ? 'La cédula es obligatoria' : 'El nit es obligatorio'
                            },
                        ]
                    })(
                        <Input
                            type='number'
                            prefix={getPrefix('number')}
                            placeholder={isBackofficeUser ? 'Cédula' : 'Nit'}
                        />
                    )}
                </Item>
                <Item>
                    {getFieldDecorator('name', {
                        rules: [
                            {required: true, message: 'El nombre es obligatorio'}
                        ]
                    })(
                        <Input
                            prefix={getPrefix('user')}
                            placeholder='Nombre'
                        />
                    )}
                </Item>
                <Item>
                    {getFieldDecorator('cellPhone', {
                        rules: [
                            {required: true, message: 'El telefono es obligatorio'},
                        ]
                    })(
                        <Input
                            type='number'
                            prefix={getPrefix('phone')}
                            placeholder='Telefono'
                        />
                    )}
                </Item>
                <Item>
                    {getFieldDecorator('email', {
                        rules: [
                            {required: true, message: 'El email es obligatorio'},
                            {type: 'email', message: 'La entrada no es un email válido'}
                        ]
                    })(
                        <Input
                            prefix={getPrefix('mail')}
                            placeholder='Email'
                        />
                    )}
                </Item>
                <Item>
                    {getFieldDecorator('password', {
                        rules: [
                            {required: true, message: 'La contraseña es obligatoria'},
                        ]
                    })(
                        <Input
                            type='password'
                            prefix={getPrefix('lock')}
                            placeholder='Contraseña'
                        />
                    )}
                </Item>
                {isBackofficeUser &&
                <Item>
                    {getFieldDecorator('rol', {
                        rules: [
                            {required: true, message: 'Selecciona algún rol'}
                        ]
                    })(
                        <Select
                            placeholder='Rol'
                        >
                            {permitedRoles.map((item, index) => (
                                <Option key={index} value={item}>{getRoleTraduction(item)}</Option>
                            ))}
                        </Select>
                    )}
                </Item>
                }
                <Item>
                    {getFieldDecorator('image', {
                        getValueFromEvent: e => e.file,
                        rules: [{required: true, message: 'Selecciona una imagen'}]
                    })(
                        <Upload.Dragger
                            accept='image/jpeg'
                            type='picture'
                            action='https://api.tuikit.com/v1/user/image/upload/data'
                        >
                            <p className='ant-upload-drag-icon'>
                                <Icon type='inbox'/>
                            </p>
                            <p className='ant-upload-text'>Click o arrastra para subir</p>
                            <p className='ant-upload-hint'>Unicamente se guarda la última foto subida.</p>
                        </Upload.Dragger>
                    )}
                </Item>
                    {
                        !isBackofficeUser ?   <Item label="Documentos legales:">
                            {getFieldDecorator('documents', {
                                getValueFromEvent: e => e.file,
                                rules: [{required: true, message: 'Selecciona una imagen'}]
                            })(
                                <Upload
                                    action="https://api.tuikit.com/v1/user/image/upload/data"
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={this.handlePreview}
                                    onChange={this.handleChange}
                                    multiple={true}
                                >
                                    {fileList.length >= 8 ? null : uploadButton}
                                </Upload>
                            )}
                            <em>Tenga en cuenta que el nombre del documento se tomara de acuerdo al de la imagen.</em>
                        </Item> : ''
                    }

                <Button type='primary' htmlType='submit' block>
                    Registrar
                </Button>
            </Form>
                <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <img alt="example" style={{width: '100%'}} src={previewImage}/>
                </Modal>
            </>

        )
    }
}

const createdForm = Form.create({name: 'user_form'})(UserForm)
export default connect(mapStateToProps)(createdForm)
