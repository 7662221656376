import React from 'react'
import { Tag } from 'antd'

const UserStatusTag = ({ status }) => {
  return (
    <Tag color={status ? 'lime' : 'orange'}>
      {status ? 'Disponible' : 'Ocupado'}
    </Tag>
  )
}

export default UserStatusTag
