import Axios from 'axios'
import { URL_USER } from './Constant'

class Enterprise {
  async findAll(token) {
    const response = await Axios.get(`${URL_USER}/enterprises`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response
  }
}

export default new Enterprise()
