import React from 'react'
import { Checkbox, Collapse } from 'antd'
import { Row, Col } from 'reactstrap'
import moment from 'moment'
import IsPermitTooltip from '../Tags/IsPermitTooltip'
const { Panel } = Collapse

const DocumentsOverviewer = ({ documents, handleDocIsActive }) => {
  return (
    <div className='mt-4'>
      <h4>Documentos</h4>
      <Collapse
        accordion
      >
        {documents.map((doc, index) => (
          <Panel
            header={(
              <IsPermitTooltip isPermit={doc.isPermition} name={doc.name} />
            )}
            key={index}
            extra={
              <Checkbox
                index={index}
                checked={doc.isActive}
                onClick={event => { event.stopPropagation() }}
                onChange={handleDocIsActive}
              >
                {doc.isActive ? 'Invalidar' : 'Validar'}
              </Checkbox>
            }
          >
            <Row>
              {doc.images && doc.images.map((img, index) => {
                if (img.length > 0) {
                  return (
                    <Col className='text-center' key={index}>
                      <img key={index} src={img} alt='...' className='img-fluid' style={{ maxWidth: '80%' }} />
                    </Col>
                  )
                } else {
                  return undefined
                }
              }
              )}
            </Row>
            <div className='d-flex justify-content-around flex-wrap mt-4'>
              <h4>Fecha de expedición: <small>{moment(doc.expeditionDate).format('l')}</small></h4>
              <h4>Fecha de vencimiento: <small>{moment(doc.expirationDate).format('l')}</small></h4>
            </div>
          </Panel>
        ))}
      </Collapse>
    </div>
  )
}

DocumentsOverviewer.defaultProps = {
  documents: [],
}

export default DocumentsOverviewer
