import React from 'react'
import ModalForm from '../Modals/ModalForm'
import ClientAcceptOfferForm from '../Forms/ClientAcceptOfferForm'
import PayServiceForm from '../Forms/PayServiceForm'
import Map from '../Maps/Map'
import SupportDocumentForm from '../Forms/SupportDocumentForm'
import RateServiceForm from '../Forms/RateServiceForm'

const ServiceClientButton = ({order, updateData}) => {
    switch (order.status) {
        case 0:
            return (
                <ModalForm
                    modalId='clientOffers'
                    icon='far fa-handshake'
                    title='Tomar contraofertas'
                    type='primary'
                    block
                    padding
                    isForm
                >
                    <ClientAcceptOfferForm order={order}/>
                </ModalForm>
            )
        case 1:
            if (!order.pay.transaction || (order.pay.status !== 'CAPTURED' && order.pay.status !== 'ACCEPTED')) {
                return (
                    <>
                        <ModalForm
                            modalId='payService'
                            icon='fas fa-money-bill'
                            title='Pagar servicio'
                            type='primary'
                            block
                            padding
                            isForm
                        >
                            <PayServiceForm order={order}/>
                        </ModalForm>
                        <small className='text-muted'>
                            Paga el servicio para terminar el proceso
                        </small>
                    </>
                )
            } else {
                return (
                    <>
                        {(order.inPosition && !order.supportDocument) || !order.inPosition ? (
                            <small className='text-muted'>
                                Esperando la subida del documento legal por parte del transportador
                            </small>
                        ) : (
                            <small className='text-muted'>
                                Servicio programado exitosamente
                            </small>
                        )}
                    </>
                )
            }
        case 2:
            return (
                <ModalForm
                    modalId='serviceMap'
                    icon='fas fa-map-marker-alt'
                    title='Mapa'
                    block
                >
                    <Map
                        driver={order.driver}
                        origin={order.origin}
                        destination={order.destination}
                    />
                </ModalForm>
            )
        case 3:
            if (order.calification) {
                return (<>
                    <small className='text-muted'>
                        Finalizado
                    </small>
                </>)
            } else {
                return (
                    <ModalForm
                        modalId='rateModal'
                        icon='fas fa-star'
                        title='Puntuar el servicio'
                        type='primary'
                        block
                        isForm
                        padding
                    >
                        <RateServiceForm order={order}/>
                    </ModalForm>
                )
            }
        case 4:
            return <small className='text-muted'>Servicio cancelado</small>
        default:
            return null
    }
}

export default ServiceClientButton
