import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

function mapStateToProps({ User, Profile }) {
  return {
    token: User.token,
    role: Profile.rol
  }
}

const Auth = ({ component: Component, token, role, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (token && role) {
        return <Redirect to={'/main/index'} />
      } else {
        return <Component {...props} />
      }
    }}
  />
)

export default connect(mapStateToProps)(Auth)
