import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Button, Input, DatePicker } from 'antd'
import { API } from '../../../api'
import { Container, Row, Col } from 'reactstrap';
import {errorAlert, successAlert} from "../formUtil";
import Select from "react-select";
const { Item } = Form

function mapStateToProps({ User }) {
    return {
        token: User.token
    }
}

class TypeVehicleForm extends Component {
    constructor() {
        super();
        this.state = {
            typevehicles: [{value : 1, label: "aca"}],
            typevehicle: null,
            selectedOption: null
        }
    }

    componentDidMount() {
        this._list()
    }

    _handleSubmit = e => {
        e.preventDefault()
        this.props.form.validateFields(this._mainAction)
    }

    _mainAction = (err, values) => {
        if (!err) {
            this.props.submit(values)
            localStorage.setItem('meditions', JSON.stringify(values))
        }
    }
    _list = async () => {
        const { data } = await API.Vehicle.getAllTypeVehicle(this.props.token)
        const dataSelect = data[0].children
        if (dataSelect.length>0) {
            let newData = []
            for (const typeVehicle of dataSelect){
                newData.push({
                    value: typeVehicle.id,
                    label: typeVehicle.name
                })
            }
            this.setState({typevehicles:newData})
            successAlert('Se han cargado los datos correctamente')
        } else {
            errorAlert(data.error)
        }
    }
    handleChangeInput = selectedOption => {
        this.setState({ typevehicle:selectedOption })

        localStorage.setItem('typevehicle', selectedOption.value)
    }


    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <Form onSubmit={this._handleSubmit}>
                <Item label="Seleccione tipo de vehiculo">
                    <Select
                        value={this.state.typevehicle}
                        onChange={this.handleChangeInput}
                        options={this.state.typevehicles}
                    />
                </Item>

                <Row>
                    <Col xs="8">
                        <Item label='Alto'>
                            {getFieldDecorator('high', {
                                rules: [{ required: true, message: 'La altura es obligatoria' }]
                            })(<Input placeholder='Alto' />)}
                        </Item>
                    </Col>
                    <Col xs="4">
                        <Item label="Medida:">
                            {getFieldDecorator('high_medition', {
                                rules: [{ required: true, message: 'La medida es obligatoria' }]
                            })(<select class="ant-input">
                                <option></option><option value="cm">Cm</option>
                                <option value="M">M</option>
                            </select>)}
                        </Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs="8">
                        <Item label='Ancho'>
                            {getFieldDecorator('width', {
                                rules: [{ required: true, message: 'El ancho es obligatorio' }]
                            })(<Input placeholder='Ancho*' />)}
                        </Item>
                    </Col>
                    <Col xs="4">
                        <Item label="Medida:">
                            {getFieldDecorator('width_medition', {
                                rules: [{ required: true, message: 'La medicion es obligatoria' }]
                            })(<select class="ant-input">
                                <option></option><option value="cm">Cm</option>
                                <option value="M">M</option>
                            </select>)}
                        </Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs="8">
                        <Item label='Piezas'>
                            {getFieldDecorator('pieces', {
                                rules: [{ required: false, message: '' }]
                            })(<Input placeholder='Piezas' />)}
                        </Item>
                    </Col>
                    <Col xs="4">
                        <Item label="Medida:">
                            {getFieldDecorator('pieces_medition', {
                                rules: [{ required: false, message: '' }]
                            })(<select class="ant-input">
                                <option></option>
                                <option value="Unidades">Unidades</option>
                                <option value="Decenas">Decenas</option>
                            </select>)}
                        </Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs="8">
                        <Item label='Peso'>
                            {getFieldDecorator('weight', {
                                rules: [{ required: true, message: 'El peso es obligatorio' }]
                            })(<Input placeholder='Peso*' />)}
                        </Item>
                    </Col>
                    <Col xs="4">
                        <Item label="Medida:">
                            {getFieldDecorator('weight_medition', {
                                rules: [{ required: true, message: 'La medida es obligatoria' }]
                            })(<select class="ant-input">
                                <option></option><option value="kl">KL</option>
                                <option value="tn">TN</option>
                            </select>)}
                        </Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs="8">
                        <Item label='Volumen'>
                            {getFieldDecorator('volume', {
                                rules: [{ required: false, message: '' }]
                            })(<Input placeholder='Volumen' />)}
                        </Item>
                    </Col>
                    <Col xs="4">
                        <Item label="Medida:">
                            {getFieldDecorator('volume_medition', {
                                rules: [{ required: false, message: '' }]
                            })(<select class="ant-input">
                                <option></option><option value="cm">Cm</option>
                                <option value="M">M</option>
                            </select>)}
                        </Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs="8">
                        <Item label='Fondo'>
                            {getFieldDecorator('bottom', {
                                rules: [{ required: false, message: '' }]
                            })(<Input placeholder='Fondo' />)}
                        </Item>
                    </Col>
                    <Col xs="4">
                        <Item label="Medida:">
                            {getFieldDecorator('bottom_medition', {
                                rules: [{ required: false, message: '' }]
                            })(<select class="ant-input">
                                <option></option><option value="cm">Cm</option>
                                <option value="M">M</option>
                            </select>)}
                        </Item>
                    </Col>
                </Row>

                <Button type='primary' htmlType='submit' block>
                    Siguiente
                </Button>
            </Form>
        )
    }
}

const CreatedForm = Form.create({ name: 'type_vehicle_form' })(
    TypeVehicleForm
)
export default connect(mapStateToProps)(CreatedForm)
