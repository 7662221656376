import React from 'react'
import { Tooltip } from 'antd'

const IsPermitTooltip = ({ isPermit, name }) => {
  return isPermit ?
    <>
      <Tooltip
        title='Este documento pertenece a un tipo de carga'
      >
        {name}
        <span className='fas fa-exclamation-triangle ml-2 text-warning'></span>
      </Tooltip>
    </>
    : name

}

export default IsPermitTooltip
