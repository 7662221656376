import React from 'react'
import { Row, Col } from 'reactstrap'
import { Card, Button } from 'antd'

const LegalDocumentsOverviewer = ({ data }) => {
  return (
    <Row>
      {data.map((doc, i) => (
        <Col xs='12' sm='6' md='6' lg='3' key={i}>
          <Card
            title={doc.name}
            extra={(
              <Button target='_blank' href={doc.document} block type='primary'>
                <i className='fas fa-file-download'></i>&nbsp;
              </Button>
            )}
          />
        </Col>
      ))}
    </Row>
  )
}

export default LegalDocumentsOverviewer
