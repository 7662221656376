import React, { Component } from 'react'
import { connect } from 'react-redux'
import { API } from '../api'
import { Row, Col, Container } from 'reactstrap'
import MainHeader from '../components/Headers/MainHeader'
import Wait from '../components/Feedback/Wait'
import { Card, Button, Avatar, Checkbox } from 'antd'
import ReactStars from 'react-stars'
import DocumentsOverviewer from '../components/Overviewers/DocumentsOverviewer'
import FeatureOverviewer from '../components/Overviewers/FeatureOverviewer'
import ImagesOverviewer from '../components/Overviewers/ImagesOverviewer'
import IsActiveBadge from '../components/Badges/IsActiveBadge'
import ServicesTable from '../components/Tables/ServicesTable'
import { errorAlert, successAlert } from '../components/Forms/formUtil'
import TypeLoadSelector from '../components/Selectors/TypeLoadSelector'

function mapStateToProps({ User }) {
  return {
    token: User.token
  }
}

const itemName = 'vehiculo'

class Vehicle extends Component {
  state = {
    vehicle: {},
    services: [],
    loading: true
  }

  componentDidMount() {
    this.setData()
  }

  setData = async () => {
    await this.getVehicleInformation()
    this.getVehicleServices()
  }

  updateData = async () => {
    this.setState(
      {
        services: []
      },
      () => this.getVehicleServices()
    )
  }

  getVehicleInformation = async () => {
    const { licensePlate } = this.props.match.params
    const { data } = await API.Vehicle.getDetail(this.props.token, licensePlate)
    if (data.error) {
      errorAlert(data.error)
    } else {
      this.setState({
        vehicle: data,
        loading: false
      })
    }
  }

  getVehicleServices = async () => {
    const { _id } = this.state.vehicle
    const { data } = await API.Order.findAll(this.props.token, 'vehicle', _id)
    if (data.error) {
      errorAlert(data.error)
    } else {
      this.setState({
        services: data
      })
    }
  }

  handleDocIsActive = e => {
    const { checked, index } = e.target
    const { vehicle } = this.state
    vehicle.documents[index].isActive = checked
    this.setState({ vehicle })
  }

  handleIsActive = e => {
    const { vehicle } = this.state
    vehicle.isActive = !vehicle.isActive
    this.setState({ vehicle })
  }

  handleSubmit = async () => {
    const { _id, documents, isActive, permissions } = this.state.vehicle
    const { data } = await API.Vehicle.update(this.props.token, {
      _id,
      documents,
      isActive,
      permissions
    })
    if (data.updated) {
      successAlert(itemName, true)
    } else {
      errorAlert(data.error)
    }
  }

  handleTypeSelect = permissions => {
    const { vehicle } = this.state
    vehicle.permissions = permissions
    this.setState({
      vehicle
    })
  }

  render() {
    const { vehicle } = this.state
    const { user } = vehicle
    return (
      <>
        <MainHeader />
        <Container className='mt--7' fluid>
          <Row>
            <Col>
              <h4 className='text-light'>Información general</h4>
              <Card
                title={
                  <div className='d-flex'>
                    <Avatar
                      icon='user'
                      src={user ? user.image : ''}
                      size='large'
                    />
                    <div className='mx-2'>
                      <h4 className='mb-0'>{user ? user.name : ''}</h4>
                      <ReactStars
                        count={5}
                        value={user ? user.score : 0}
                        edit={false}
                      />
                    </div>
                  </div>
                }
                extra={
                  <div className='d-flex flex-column align-items-end'>
                    <h4 className='mb-0'>{vehicle.licensePlate}</h4>
                    <IsActiveBadge isActive={vehicle.isActive} />
                  </div>
                }
                actions={[
                  <></>,
                  <Button type='primary' onClick={this.handleSubmit} block>
                    Guardar
                  </Button>,
                  <></>
                ]}
              >
                <div className='reset-padding'>
                  {this.state.loading ? (
                    <Wait />
                  ) : (
                    <>
                      <Row>
                        <Col xs='12' lg='9'>
                          <ImagesOverviewer images={vehicle.images} />
                          <Row className='mt-4'>
                            <Col>
                              <h4>Estado del vehiculo</h4>
                              <Checkbox
                                checked={vehicle.isActive}
                                onChange={this.handleIsActive}
                              >
                                Activo
                              </Checkbox>
                            </Col>
                            <Col>
                              <h4>Tipos de carga</h4>
                              <TypeLoadSelector
                                handleTypeSelect={this.handleTypeSelect}
                                values={vehicle.permissions}
                              />
                            </Col>
                          </Row>
                          <DocumentsOverviewer
                            documents={vehicle.documents}
                            handleDocIsActive={this.handleDocIsActive}
                          />
                        </Col>
                        <Col xs='12' lg='3' className='mt-4 mt-lg-0'>
                          <FeatureOverviewer
                            name='Tipo de vehiculo'
                            value={vehicle._idTypeVehicle.name}
                            icon='fa-truck'
                          />
                          <br />
                          <FeatureOverviewer
                            name='Capacidad de carga'
                            value={vehicle.loadingCapacity}
                            icon='fa-box'
                          />
                          <br />
                          <FeatureOverviewer
                            name='Marca'
                            value={vehicle.brand}
                          />
                          <br />
                          <FeatureOverviewer
                            name='Placa'
                            value={vehicle.licensePlate}
                          />
                          <br />
                          <FeatureOverviewer
                            name='Modelo'
                            value={vehicle.model}
                            icon='fa-calendar-alt'
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
          <h4 className='mt-5'>Servicios asociados</h4>
          <Row className='mb-5'>
            <Col>
              <ServicesTable
                data={this.state.services}
                updateData={this.updateData}
              />
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default connect(mapStateToProps)(Vehicle)
