import React, { Component } from 'react'
import getColumnSearchProps from '../../utils/filterTable'
import { Avatar, Button, Tag } from 'antd'
import TableList from './TableList'
import { Link } from 'react-router-dom'
import UserIsActiveTag from '../Tags/UserIsActiveTag'
import UserForm from '../Forms/UserForm'
import { getRoleTraduction } from '../../utils/RoleConditions'

const sectionName = 'Usuarios backoffice'

class UsersTable extends Component {

  state = {
    searchText: '',
    filter: null
  }

  _updateState = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  handleChange = filters => {
    this.setState({
      filter: filters
    })
  }

  _mainAction = item => {
    return (
      <Link
        to={`/main/detalle/${item._id}`}
      >
        <Button block>
          <i className='fas fa-search' />
        </Button>
      </Link>
    )
  }

  render() {
    const columns = [
      {
        key: 'identification',
        title: 'Cédula',
        dataIndex: 'identification',
        ...getColumnSearchProps('identification', this.state.searchText, this._updateState),
      },
      {
        key: 'image',
        dataIndex: 'image',
        width: 50,
        render: image => {
          return (
            <Avatar
              icon='user'
              src={image}
            />
          )
        },
      },
      {
        key: 'name',
        title: 'Nombre',
        dataIndex: 'name',
        ...getColumnSearchProps('name', this.state.searchText, this._updateState),
      },
      {
        key: 'cellPhone',
        title: 'Telefono',
        dataIndex: 'cellPhone',
        ...getColumnSearchProps('cellPhone', this.state.searchText, this._updateState)
      },
      {
        key: 'email',
        title: 'Email',
        dataIndex: 'email',
        ...getColumnSearchProps('email', this.state.searchText, this._updateState)
      },
      {
        key: 'isActive',
        title: 'Activo',
        dataIndex: 'isActive',
        filters: [
          { text: 'Activo', value: true },
          { text: 'Inactivo', value: false }
        ],
        onFilter: (value, record) => record.isActive === value,
        render: isActive => {
          return (
            <UserIsActiveTag isActive={isActive} />
          )
        }
      },
      {
        key: 'rol',
        title: 'Rol',
        dataIndex: 'rol',
        filters: [
          { text: 'Administrador', value: 'administrator' },
          { text: 'Contabilidad', value: 'accountancy' }
        ],
        onFilter: (value, record) => record.rol.toString().includes(value),
        render: rol => <Tag>{getRoleTraduction(rol)}</Tag>
      },
      {
        title: 'Detalle',
        key: 'operation',
        fixed: 'right',
        width: 100,
        render: item => this._mainAction(item),
      }
    ]

    return (
      <TableList
        title={sectionName}
        data={this.props.data}
        columns={columns}
        filter={this.handleChange}
      >
        <UserForm
          isBackofficeUser
          updateData={this.props.updateData}
        />
      </TableList>
    )
  }
}

export default UsersTable