import React, { Component } from 'react'
import {
  Container,
  Row,
  Col
} from 'reactstrap'
import MainHeader from 'components/Headers/MainHeader.jsx'
import { connect } from 'react-redux'
import { API } from '../api'
import ClientsTable from '../components/Tables/ClientsTable'
import { errorAlert } from '../components/Forms/formUtil'
import { Tabs } from 'antd'

const { TabPane } = Tabs

function mapStateToProps({ User }) {
  return {
    token: User.token
  }
}

class Clients extends Component {
  state = {
    clients: [],
    clientsEnterprise: []
  }

  componentDidMount() {
    this._setData()
  }

  updateData = async () => {
    this._setData()
  }

  _setData = async () => {
    const { data } = await API.Client.getAll(this.props.token)
    if (data.error) {
      errorAlert(data.error)
    } else {
      this.setState({
        data
      })
    }
  }

  _setData = async () => {
    this._getClients()
    this._getClients(true)
  }

  _getClients = async isEnterprise => {
    const { data } = await API.Client.getAll(this.props.token, isEnterprise)
    if (data.error) {
      errorAlert(data.error)
    } else {
      if (isEnterprise) {
        this.setState({
          clientsEnterprise: data
        })
      } else {
        this.setState({
          clients: data
        })
      }
    }
  }

  render() {
    return (
      <>
        <MainHeader lines />
        <Container className='mt--7' fluid>
          <Row>
            <Col>
              <Tabs type='card' tabBarStyle={{ margin: '0', border: '0' }}>
                <TabPane tab='Persona natural' key='1'>
                  <ClientsTable
                    data={this.state.clients}
                  />
                </TabPane>
                <TabPane tab='Empresa' key='2'>
                  <ClientsTable
                    data={this.state.clientsEnterprise}
                    isEnterprise
                  />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default connect(mapStateToProps)(Clients)