import { combineReducers } from 'redux'
import { User } from './user'
import { Profile } from './profile'

const reducer = combineReducers({
  User,
  Profile
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined
  }
  return reducer(state, action)
}

export default rootReducer
