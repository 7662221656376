import React, { Component } from 'react'
import { connect } from 'react-redux'
import { API } from '../api'
import { Row, Col, Container } from 'reactstrap'
import MainHeader from '../components/Headers/MainHeader'
import Wait from '../components/Feedback/Wait'
import { Card, Statistic, Button } from 'antd'
import moment from 'moment'
import ReactStars from 'react-stars'
import DocumentsOverviewer from '../components/Overviewers/DocumentsOverviewer'
import UserIsActiveSelector from '../components/Selectors/UserIsActiveSelector'
import VehiclesOverviewer from '../components/Overviewers/VehiclesOverviewer'
import ServicesTable from '../components/Tables/ServicesTable'
import { successAlert, errorAlert } from '../components/Forms/formUtil'
import RolTag from '../components/Tags/RolTag'
import {
  isEnterprise,
  isDriver,
  isClient,
  isClientEnterprise
} from '../utils/RoleConditions'
import UserIsActiveTag from '../components/Tags/UserIsActiveTag'
import UserStatusTag from '../components/Tags/UserStatusTag'

function mapStateToProps({ User }) {
  return {
    token: User.token
  }
}

const itemName = 'usuario'

class Profile extends Component {
  state = {
    user: {},
    loading: true,
    services: []
  }

  componentDidMount() {
    this.setData()
  }

  setData = async () => {
    await this.getUserInformation()
    this.getUserServices()
  }

  getUserInformation = async () => {
    const { _id } = this.props.match.params
    const { data } = await API.User.detailUser(this.props.token, _id)
    if (data.error) {
      errorAlert(data.error)
    } else {
      this.setState({
        user: data,
        loading: false
      })
    }
  }

  updateData = async () => {
    this.setState(
      {
        services: []
      },
      () => this.getUserServices()
    )
  }

  getUserServices = async () => {
    const { _id, rol } = this.state.user
    const { data } = await API.Order.findAll(this.props.token, rol, _id)
    if (data.error) {
      errorAlert(data.error)
    } else {
      this.setState({
        services: data
      })
    }
  }

  handleIsActive = e => {
    const { value } = e.target
    const { user } = this.state
    user.isActive = value
    this.setState({ user })
  }

  handleDocIsActive = e => {
    const { checked, index } = e.target
    const { user } = this.state
    user.documents[index].isActive = checked
    this.setState({ user })
  }

  handleSubmit = async () => {
    const { data } = await API.User.update(this.props.token, this.state.user)
    if (data.updated) {
      successAlert(itemName, true)
    } else {
      errorAlert(data.error)
    }
  }

  render() {
    const { user } = this.state
    return (
      <>
        <MainHeader />
        <Container className='mt--7' fluid>
          <Row>
            <Col>
              <h4 className='text-light'>Perfil</h4>
              <Card
                actions={[
                  <></>,
                  <Button type='primary' onClick={this.handleSubmit} block>
                    Guardar
                  </Button>,
                  <></>
                ]}
              >
                <div className='reset-padding'>
                  {this.state.loading ? (
                    <Wait />
                  ) : (
                    <>
                      <Row>
                        <Col xs='12' lg='3'>
                          <div className='text-center'>
                            <div>
                              <img
                                alt='...'
                                style={{ width: '70%', margin: '0 auto' }}
                                className='img-fluid rounded d-block mb-4'
                                src={
                                  user.image
                                    ? user.image
                                    : require('../assets/img/brand/tuikit/cut-camion.png')
                                }
                              />
                              <img
                                className='mt-4 position-absolute img-fluid rounded-circle'
                                style={{ left: '1em', top: '0', width: '20%' }}
                                alt=''
                                src={
                                  user.enterprise ? user.enterprise.image : ''
                                }
                              />
                              <RolTag rol={user.rol} />
                            </div>
                            <div className='mt-4'>
                              <h4>Cédula</h4>
                              {user.identification}
                            </div>
                            <div className='mt-4'>
                              <h4>Correo</h4>
                              {user.email}
                            </div>
                            <div className='mt-4'>
                              <h4>Telefono</h4>
                              {user.cellPhone}
                            </div>
                          </div>
                        </Col>
                        <Col xs='12' lg='9' className='mt-4 mt-lg-0'>
                          <div className='d-flex justify-content-around py-2 bg-secondary rounded'>
                            <Statistic
                              title='Activo desde'
                              value={moment(user.dateCreate).format('ll')}
                            />
                            <Statistic
                              title='Ingresos asociados'
                              prefix='$'
                              value={user.countPrice || 0}
                            />
                            <Statistic
                              title='Pedidos asociados'
                              value={user.orderCount || 0}
                            />
                          </div>
                          <div
                            className='mt-4 position-absolute'
                            style={{ right: '1em' }}
                          >
                            <UserIsActiveTag isActive={user.isActive} />
                            {isDriver(user.rol) && (
                              <UserStatusTag status={user.status} />
                            )}
                          </div>
                          <h2 className='mt-4 mb-0'>{user.name}</h2>
                          {(isEnterprise(user.rol) || isDriver(user.rol)) && (
                            <ReactStars
                              count={5}
                              value={user.score}
                              edit={false}
                              size={25}
                            />
                          )}
                          <div className='mt-4'>
                            <UserIsActiveSelector
                              handleIsActive={this.handleIsActive}
                              isActive={user.isActive}
                            />
                          </div>
                          {(isEnterprise(user.rol) || isDriver(user.rol)) && (
                            <DocumentsOverviewer
                              documents={user.documents}
                              handleDocIsActive={this.handleDocIsActive}
                            />
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
          {(isDriver(user.rol) ||
            isEnterprise(user.rol) ||
            isClient(user.rol) ||
            isClientEnterprise(user.rol)) && (
            <>
              <h4 className='mt-5'>Servicios asociados</h4>
              <Row className='mb-5'>
                <Col>
                  <ServicesTable
                    data={this.state.services}
                    updateData={this.updateData}
                  />
                </Col>
              </Row>
            </>
          )}
          {(isEnterprise(user.rol) || isDriver(user.rol)) && (
            <VehiclesOverviewer vehicles={user.vehicles} />
          )}
        </Container>
      </>
    )
  }
}

export default connect(mapStateToProps)(Profile)
