import React, { Component } from 'react'
import { notification, Steps, Button } from 'antd'
import { API } from '../../api'
import SendTokenForm from './SendTokenForm'
import ChangePasswordForm from './ChangePasswordForm'
const { Step } = Steps

class MainResetForm extends Component {

  state = {
    token: '',
    email: '',
    newPassword: '',
    current: 0
  }

  _redirect = () => {
    this.props.history.replace('/auth/login')
  }

  makeStep = current => {
    this.setState({ current })
  }

  submitToken = async values => {
    this.setState({
      email: values.email
    })
    const { data } = await API.Account.createTokenPassword(values.email)
    if (data) {
      notification.open({
        type: 'info',
        message: 'Info',
        description: 'Si el correo se encuentra registrado recibirá un código que debe digitar a continuación'
      })
      this.makeStep(this.state.current + 1)
    }
  }

  changePassword = async values => {
    const { data } = await API.Account.changePassword(this.state.email, values.token, values.newPassword)
    if (data.updated) {
      notification.open({
        type: 'success',
        message: 'Hecho',
        description: 'La contraseña se ha restablecido'
      })
    } else {
      notification.open({
        type: 'error',
        message: 'Error',
        description: data.mensaje
      })
    }
  }

  render() {
    const { current } = this.state
    const steps = [
      {
        title: 'Envío de correo',
        content: (
          <SendTokenForm submitToken={this.submitToken} />
        )
      },
      {
        title: 'Cambio de contraseña',
        content: (
          <ChangePasswordForm changePassword={this.changePassword} />
        )
      }
    ]

    return (
      <>
        <Steps
          direction='vertical'
          current={current}
        >
          {steps.map(step => (
            <Step key={step.title} title={step.title} icon={step.icon} />
          ))}
        </Steps>
        <div className='steps-content'>{steps[current].content}</div>
        <div className='steps-action'>
          {current > 0 && (
            <Button onClick={this.makeStep.bind(this, current - 1)} block>
              Anterior
            </Button>
          )}
        </div>
      </>
    )
  }
}

export default MainResetForm
