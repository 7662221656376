import React, { Component } from 'react'
import { API } from '../../api'
import { connect } from 'react-redux'
import { Form, Input, Button, Radio } from 'antd'
import { getPrefix, successAlert, errorAlert } from './formUtil'
const { TextArea } = Input
const { Item } = Form

function mapStateToProps({ User }) {
  return {
    token: User.token
  }
}

const itemName = 'pqr'

class PqrForm extends Component {

  componentDidMount() {
    if (this.props.item._id) {
      this._loadEdit(this.props.item)
    }
  }

  _loadEdit(obj) {
    let jsonValues = {}
    for (let key in obj) {
      jsonValues[key] = { value: obj[key] }
    }
    this.props.form.setFields(jsonValues)
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields(this._mainAction)
  }

  _mainAction = async (err, values) => {
    if (!err) {
      this._answerRequest(values)
    }
  }

  _answerRequest = async values => {
    const { _id } = this.props.item
    const { data } = await API.Pqr.answerRequest(this.props.token, { _id, ...values })
    if (data.updated) {
      successAlert(itemName, true)
      this._success()
    } else {
      errorAlert(data.error)
    }
  }

  _success = resetFields => {
    this.props.onOk()
    if (resetFields) {
      this.props.form.resetFields()
    }
    if (this.props.updateData) {
      this.props.updateData()
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Form onSubmit={this.handleSubmit}>
        <Item>
          {getFieldDecorator('response', {
            rules: [{ required: true, message: 'La respuesta es obligatoria' }]
          })(
            <TextArea
              prefix={getPrefix('edit')}
              placeholder='Respuesta'
            />
          )}
        </Item>
        <Item>
          {getFieldDecorator('status')(
            <Radio.Group>
              <Radio.Button value='Solucionado'>Solucionado</Radio.Button>
              <Radio.Button value='EnProceso'>En proceso</Radio.Button>
              <Radio.Button value='Pendiente'>Pendiente</Radio.Button>
            </Radio.Group>
          )}
        </Item>
        <Button type='primary' htmlType='submit' block>
          Responder
        </Button>
      </Form>
    )
  }
}

const createdForm = Form.create({ name: 'pqr_form' })(PqrForm)
export default connect(mapStateToProps)(createdForm)
