import React from 'react'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Admin from './navigation/admin-navigator'
import Auth from './navigation/auth-navigator'

import 'assets/vendor/nucleo/css/nucleo.css'
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css'
import './assets/css/antd.css'
import 'assets/scss/argon-dashboard-react.scss'
import 'assets/css/general.css'

import {store, persistor} from './store'
import AdminLayout from 'layouts/Admin.jsx'
import AuthLayout from 'layouts/Auth.jsx'
import ResetLayout from 'layouts/Reset.jsx'
import LandingPage from 'layouts/Splash.jsx'
import TermsConditions from 'layouts/Terms.jsx'
import './firebase/index'
const App = () => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router>
                <Switch>
                    <Route exact path='/' component={LandingPage}/>
                    <Auth path='/auth' component={AuthLayout}/>
                    <Auth path='/reset' component={ResetLayout}/>
                    <Auth path='/term' component={TermsConditions}/>
                    <Admin path='/main' component={AdminLayout}/>
                </Switch>
            </Router>
        </PersistGate>
    </Provider>
)

export default App
