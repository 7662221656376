import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Form, Button, Upload, message, DatePicker, Icon} from 'antd'
import {errorAlert} from "./formUtil";

const {Item} = Form

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

function mapStateToProps({User}) {
    return {
        token: User.token
    }
}

class VehicleDocumentsForm extends Component {
    constructor() {
        super()
        this.state = {
            frontSoat: undefined,
            backSoat: undefined,
            loaderFront: false,
            loaderBack: false
        }
    }

    _handleSubmit = e => {
        e.preventDefault()
        this.props.form.validateFields(this._mainAction)
    }

    _mainAction = (err, values) => {
        if (!err) {
            if (values.dateInitSoat < values.dateEndSoat) {
                values.frontSoat = this.state.frontSoat
                values.backSoat = this.state.backSoat
                this.props.submit(values)
            } else {
                errorAlert('La fecha de vencimiento debe ser mayor a la de expedición')
            }
        }
    }

    handleChangeFront = info => {
        if (info.file.status === 'uploading') {
            this.setState({loaderFront: true})
            return
        }
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    frontSoat: imageUrl,
                }),
            )
        }
        this.setState({loaderFront: false})
    }

    handleChangeBack = info => {
        if (info.file.status === 'uploading') {
            this.setState({loaderBack: true})
            return
        }
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    backSoat: imageUrl,
                }),
            )
        }
        this.setState({loaderBack: false})
    }


    render() {
        const {getFieldDecorator} = this.props.form
        const {frontSoat, loaderFront, loaderBack, backSoat} = this.state

        return (
            <Form onSubmit={this._handleSubmit}>
                <Item label='Foto delantera SOAT'>
                    {getFieldDecorator('frontSoat', {
                        rules: [
                            {
                                required: true,
                                message: 'Selecciona una imagen'
                            }
                        ]
                    })(
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="https://api.tuikit.com/v1/user/image/upload/data"
                            beforeUpload={beforeUpload}
                            onChange={this.handleChangeFront}
                        >
                            {loaderFront ?
                                <Icon type="loading" style={{fontSize: '16px', color: '#08c'}}
                                      theme="outlined"/> : ''}
                            {!loaderFront && frontSoat == undefined ?
                                <Icon type="camera" style={{fontSize: '16px', color: '#08c'}}
                                      theme="outlined"/> : ''}
                            {frontSoat ? <img src={frontSoat} alt="avatar" style={{width: '100%'}}/> : ''}
                        </Upload>
                    )}
                </Item>
                <Item label='Fecha de expedición'>
                    {getFieldDecorator('dateInitSoat', {
                        rules: [
                            {
                                type: 'object',
                                required: true,
                                message: 'La fecha de expedición es obligatoria'
                            }
                        ],
                    })(
                        <DatePicker
                            format='YYYY-MM-DD'
                            className='w-100'
                        />
                    )}
                </Item>
                <Item label='Foto trasera SOAT'>
                    {getFieldDecorator('backSoat', {
                        rules: [
                            {
                                required: true,
                                message: 'Selecciona una imagen'
                            }
                        ]
                    })(
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="https://api.tuikit.com/v1/user/image/upload/data"
                            beforeUpload={beforeUpload}
                            onChange={this.handleChangeBack}
                        >
                            {loaderBack ?
                                <Icon type="loading" style={{fontSize: '16px', color: '#08c'}}
                                      theme="outlined"/> : ''}
                            {!loaderBack && backSoat == undefined ?
                                <Icon type="camera" style={{fontSize: '16px', color: '#08c'}}
                                      theme="outlined"/> : ''}
                            {backSoat ? <img src={backSoat} alt="avatar" style={{width: '100%'}}/> : ''}
                        </Upload>
                    )}
                </Item>
                <Item label='Fecha de vencimiento'>
                    {getFieldDecorator('dateEndSoat', {
                        rules: [
                            {
                                type: 'object',
                                required: true,
                                message: 'La fecha de vencimiento es obligatoria'
                            }
                        ],
                    })(
                        <DatePicker
                            format='YYYY-MM-DD'
                            className='w-100'
                        />)}
                    )}
                </Item>
                <Button type='primary' htmlType='submit' block>
                    Siguiente
                </Button>
            </Form>
        )
    }
}

const CreatedForm = Form.create({name: 'vehicle_documents_form'})(
    VehicleDocumentsForm
)
export default connect(mapStateToProps)(CreatedForm)
