import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from 'reactstrap'
import { connect } from 'react-redux'
import MainHeader from '../components/Headers/MainHeader'
import { getRoleTraduction } from '../utils/RoleConditions'
import AOS from 'aos'

function mapStateToProps({ User, Profile }) {
  return {
    token: User.token,
    profile: Profile
  }
}

class Index extends Component {

  componentWillMount() {
    AOS.init()
  }

  getRoleFunctions() {
    switch (this.props.profile.rol) {
      case 'administrator':
        return 'En el sistema puedes visualizar la información de los usuarios de la aplicación. Así como ver la información de los servicios realizados, los vehiculos y las empresas.'
      case 'accountancy':
        return 'En el sistema puedes visualizar la información contable de los servicios realizados.'
      case 'enterprise':
        return 'En el sistema puedes aceptar y contraofertar servicios, luego de esto asignar un conductor y un vehiculo para el mismo. También puede gestionar sus vehiculos y ver la información de sus conductores.'
      case 'cliententerprise':
        return 'En el sistema puedes solicitar servicios, ofertar un precio por el mismo y recibir contraofertas de los transportadores.'
      default:
        return ''
    }
  }

  render() {
    return (
      <>
        <MainHeader />
        <Container className='mt--7' fluid>
          <Row>
            <Col xs='12' sm='12' md='12' lg='4'>
              <Card
                data-aos='fade-up'
                className='shadow border-0'
              >
                <CardBody className='px-sm-5'>
                  <img
                    src={require('assets/img/brand/tuikit/personas-mirando.png')}
                    alt='...'
                    className='img-fluid mt--7'
                  />
                  <div className='text-center py-4'>
                    <p>
                      Bienvenido a Tuikit, en el menú lateral encontrarás las opciones disponibles.
                    </p>
                    <hr />
                    <p>
                      Al presionar en la esquina superior derecha podrás encontrar opciones adicionales.
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className='mt-7 mt-lg-0' xs='12' sm='12' md='12' lg='4'>
              <Card
                data-aos='fade-up'
                data-aos-delay='500'
                className='shadow border-0'
              >
                <CardBody className='px-sm-5'>
                  <img
                    src={require('assets/img/brand/tuikit/persona-hojas.png')}
                    alt='...'
                    className='img-fluid mt--7'
                  />
                  <div className='text-center py-4'>
                    <p className='text-lead'>
                      Este sistema fue diseñado para darle funciones especificas en función de tu rol, actualmente tu rol es <span className='text-primary'>{getRoleTraduction(this.props.profile.rol)}</span>.
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className='mt-7 mt-lg-0' xs='12' sm='12' md='12' lg='4'>
              <Card
                data-aos='fade-up'
                data-aos-delay='1000'
                className='shadow border-0'
              >
                <CardBody className='px-sm-5'>
                  <img
                    src={require('assets/img/brand/tuikit/rendimiento.png')}
                    alt='...'
                    className='img-fluid mt--7'
                  />
                  <div className='text-center py-4'>
                    <p className='text-lead'>
                      {this.getRoleFunctions()}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container >
      </>
    )
  }
}

export default connect(mapStateToProps)(Index)
