import React, { Component } from 'react'
import { Form, Upload, Icon, Button, Input } from 'antd'
import { connect } from 'react-redux'
import { errorAlert, successAlert } from './formUtil'
import { convertBase64 } from '../../utils/image'
import { API } from '../../api'
const { Item } = Form

function mapStateToProps({ User }) {
  return {
    token: User.token
  }
}

class SupportDocumentForm extends Component {
  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields(this._mainAction)
  }

  _mainAction = async (err, values) => {
    if (!err) {
      try {
        convertBase64(values.supportDocument.originFileObj, supportDocument => {
          this._load({ ...values, supportDocument })
        })
      } catch (error) {
        errorAlert('Intente nuevamente cargar el archivo')
      }
    }
  }

  _load = async item => {
    const { order, token, onOk } = this.props
    const { data } = await API.ClientOrder.loadSupportDoc(
      order._id,
      item,
      token
    )
    if (data.updated) {
      successAlert('Documento subido exitosamente')
      onOk()
    } else {
      errorAlert(data.error)
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <>
        <div className='text-muted mb-4'>
          <small>
            Suba el documento de soporte que le proporciona el conductor para
            poder iniciar el servicio. O espere a que el conductor realice la
            carga del mismo.
          </small>
        </div>
        <Form onSubmit={this.handleSubmit}>
          <Item>
            {getFieldDecorator('idSupportDocument', {
              rules: [{ required: true, message: 'El id es obligatorio' }]
            })(<Input placeholder='Id documento' />)}
          </Item>
          <Item>
            {getFieldDecorator('supportDocument', {
              valuePropName: 'file',
              getValueFromEvent: e => e.file,
              rules: [
                {
                  required: true,
                  message: 'Selecciona algún archivo'
                }
              ]
            })(
              <Upload.Dragger
                multiple={false}
                accept='.jpg'
                type='picture'
                action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
              >
                <p className='ant-upload-drag-icon'>
                  <Icon type='inbox' />
                </p>
                <p className='ant-upload-text'>Click o arrastra para subir</p>
                <p className='ant-upload-hint'>
                  Unicamente se guarda el último documento subido.
                </p>
              </Upload.Dragger>
            )}
          </Item>
          <Button type='primary' htmlType='submit' block>
            Cargar
          </Button>
        </Form>
      </>
    )
  }
}

const createdForm = Form.create({ name: 'support_doc_form' })(
  SupportDocumentForm
)
export default connect(mapStateToProps)(createdForm)
