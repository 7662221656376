import React from 'react'
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media
} from 'reactstrap'
import { connect } from 'react-redux'
import { getRoleTraduction } from '../../utils/RoleConditions'
import { Avatar } from 'antd'

function mapStateToProps({ User, Profile }) {
  return {
    token: User.token,
    profile: Profile
  }
}

class AdminNavbar extends React.Component {

  _logout = () => {
    this.props.dispatch({
      type: 'LOGOUT'
    })
  }

  render() {
    const { profile } = this.props
    return (
      <>
        <Navbar className='navbar-top navbar-dark' expand='md' id='navbar-main'>
          <Container fluid>
            <div className='h4 mb-0 text-white d-none d-lg-inline-block'>
              {this.props.brandText}
            </div>
            <Nav className='align-items-center d-none d-md-flex' navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className='pr-0' nav>
                  <Media className='align-items-center'>
                    <Media className='ml-2 d-none d-lg-block'>
                      <span className='mb-0 text-sm font-weight-bold'>
                        {getRoleTraduction(this.props.profile.rol)}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className='dropdown-menu-arrow' right>
                  <DropdownItem className='noti-title' header tag='div'>
                    <div className='d-flex align-items-center flex-column'>
                      <Avatar size={60} icon='user' src={profile.image} />
                      <h4 className='text-capitalize mt-2 mb-0'>{profile.name}</h4>
                    </div>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={this._logout}>
                    <i className='fas fa-sign-out-alt text-primary' />
                    Cerrar sesión
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    )
  }
}

export default connect(mapStateToProps)(AdminNavbar)
