import React, { Component } from 'react'
import moment from 'moment'
import { Form, Button, Input } from 'antd'
import { connect } from 'react-redux'
import { errorAlert, successAlert } from './formUtil'
// import { convertBase64 } from '../../utils/image'
import { API } from '../../api'
const { TextArea } = Input
const { Item } = Form

function mapStateToProps({ User }) {
  return {
    token: User.token
  }
}

class CancelServiceForm extends Component {
  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields(this._mainAction)
  }

  _mainAction = async (err, values) => {
    if (!err) {
      // try {
      //   convertBase64(values.supportDocument.originFileObj, supportDocument => {
      //     this._load({ ...values, supportDocument })
      //   })
      // } catch (error) {
      //   errorAlert('Intente nuevamente cargar el archivo')
      // }
      this.cancel(values)
    }
  }

  cancel = async item => {
    const { order, token, onOk } = this.props
    const { data } = await API.ClientOrder.cancelService({ orderId: order._id }, token)
    if (data._id) {
      successAlert('Servicio cancelado')
      onOk()
    } else {
      errorAlert(data.error)
    }
  }

  getCancelMessage = () => {
    const { createdAt } = this.props.order
    const cleanCancel = moment(createdAt).add(2, 'h')
    return (
      <>
        <small>
          Recuerda que pasadas dos horas de la fecha de creación del servicio,
          se cobrará un 5% del valor total para cancelar.
        </small>
        <hr />
        <span>Fecha de reserva: {moment(createdAt).format('LLL')}</span>
        <br />
        <span>
          Fecha máxima de cancelación sin cobro:&nbsp;
          {moment(cleanCancel).format('LLL')}
        </span>
      </>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <>
        <div className='text-muted mb-4'>{this.getCancelMessage()}</div>
        <Form onSubmit={this.handleSubmit}>
          <Item>
            {getFieldDecorator('message', {
              rules: [{ required: true, message: 'El motivo es obligatorio' }]
            })(<TextArea placeholder='Motivo de cancelación' />)}
          </Item>
          {/* <Item>
            {getFieldDecorator('supportDocument', {
              valuePropName: 'file',
              getValueFromEvent: e => e.file,
              rules: [
                {
                  required: true,
                  message: 'Selecciona algún archivo'
                }
              ]
            })(
              <Upload.Dragger
                multiple={false}
                accept='.jpg'
                type='picture'
                action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
              >
                <p className='ant-upload-drag-icon'>
                  <Icon type='inbox' />
                </p>
                <p className='ant-upload-text'>Click o arrastra para subir</p>
                <p className='ant-upload-hint'>
                  Unicamente se guarda el último documento subido.
                </p>
              </Upload.Dragger>
            )}
          </Item> */}
          <Button type='danger' htmlType='submit' block>
            Cancelar
          </Button>
        </Form>
      </>
    )
  }
}

const createdForm = Form.create({ name: 'cancel_form' })(CancelServiceForm)
export default connect(mapStateToProps)(createdForm)
