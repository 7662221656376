import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Button, Input, DatePicker } from 'antd'
import { API } from '../../../api'
import { Container, Row, Col } from 'reactstrap';
import { errorAlert, infoAlert, successAlert } from "../formUtil";
import Select from "react-select";

const { Item } = Form

function mapStateToProps({ User }) {
    return {
        token: User.token
    }
}

class PriceForm extends Component {
    constructor() {
        super();
        this.state = {
            priceDefault: 0,
            price: 0,
            percent: 0,
        }
    }

    componentDidMount() {
        this._suggestedPrice()
    }

    _handleSubmit = e => {
        e.preventDefault()
        this.props.form.validateFields(this._mainAction)
    }

    _mainAction = (err, values) => {
        if (!err) {
            this.props.submit(values)
            if (values.price) {
                localStorage.setItem('price', this.state.price ? `${this.state.price}` : `${values.price}`)
            }
        }
    }

    _suggestedPrice = async () => {
        const km = localStorage.getItem('km')
        const typevehicle = localStorage.getItem('typevehicle')
        const { data } = await API.Order.suggestedPrice({ typeVehicleId: typevehicle, km, token: this.props.token })
        if (data.price) {
            this.setState({ priceDefault: data.price })
        } else {
            errorAlert(data.error)
        }
    }

    handleChangeInput = selectedOption => {
        this.setState({ typevehicle: selectedOption })
        localStorage.setItem('typevehicle', selectedOption.value)
    }

    _selectPrice = () => {
        infoAlert('Ha elegido el precio por defecto, tenga en cuenta que si oferta un precio, sera elegido')
        localStorage.setItem('price', `${this.state.priceDefault}`)
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <Form onSubmit={this._handleSubmit}>
                <Row>
                    <Col xs="6">
                        <Item label='Precio sugerido' onClick={this._selectPrice}>
                            <Input value={this.state.priceDefault} disabled />
                        </Item>
                    </Col>
                    <Col xs="6">

                        <Item label='Precio a ofertar'>
                            {getFieldDecorator('price', {
                                rules: [{ required: false, message: '' }]
                            })(<Input type="number" />)}
                        </Item>
                    </Col>
                </Row>
                <Item label='Recuerde que si digita un precio se le sumará el 19% de IVA incluido.' />
                <Button type='primary' htmlType='submit' block>
                    Siguiente
                </Button>
            </Form>
        )
    }
}

const CreatedForm = Form.create({ name: 'price_form' })(
    PriceForm
)
export default connect(mapStateToProps)(CreatedForm)
