import React from 'react'
import { Badge } from 'antd'

const IsActiveBadge = ({ isActive = false }) => {
  return (
    <Badge color={isActive ? 'green' : 'red'} text={isActive ? 'Activo' : 'Inactivo'} />
  )
}

export default IsActiveBadge
