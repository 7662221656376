import React from 'react'
import { Statistic } from 'antd'

// const ServicePriceStatistic = ({ order }) => {
//   return (
//     <Statistic
//       title={order.offer ? 'Valor acordado' : 'Valor no acordado aún'}
//       prefix='$'
//       value={order.offer ? order.offer.value : order.price}
//     />
//   )
// }

// export default ServicePriceStatistic

export const ServicePriceStatistic = ({ value, valueStyle }) => {
  return (
    <Statistic
      title={value ? 'Acordado' : 'Aún no acordado'}
      prefix='$'
      value={value}
      valueStyle={valueStyle}
    />
  )
}

export default ServicePriceStatistic
