import React, { Component } from 'react'
import { connect } from 'react-redux'
import TableList from './TableList'
import getColumnSearchProps from '../../utils/filterTable'
import IdTooltip from '../Tags/IdTooltip'
import moment from 'moment'
import { Button, Tag } from 'antd'
import { getRoleTraduction } from '../../utils/RoleConditions'

function mapStateToProps({ User, Profile }) {
  return {
    token: User.token,
    _id: Profile._id
  }
}

const sectionName = 'Cancelados'

class CancelTable extends Component {
  state = {
    searchText: '',
    filter: null
  }

  _updateState = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  handleChange = filters => {
    this.setState({
      filter: filters
    })
  }

  render() {
    const columns = [
      {
        key: 'order_id',
        title: 'Id orden',
        dataIndex: 'order',
        width: 100,
        ...getColumnSearchProps(
          'order',
          this.state.searchText,
          this._updateState,
          '_id'
        ),
        render: order => {
          return <IdTooltip id={order._id} />
        }
      },
      // {
      //   key: 'clientImage',
      //   width: 50,
      //   dataIndex: 'client',
      //   render: client => {
      //     return <Avatar icon='user' src={client.image} />
      //   }
      // },
      // {
      //   key: 'clientName',
      //   title: 'Dueño de carga',
      //   dataIndex: 'client',
      //   render: client => client.name
      // },
      {
        key: 'dateCreateAt',
        title: 'Fecha cancelación',
        dataIndex: 'dateCreateAt',
        render: dateCreateAt => moment(dateCreateAt).format('LLL')
      },
      {
        key: 'serviceDate',
        title: 'Fecha servicio',
        dataIndex: 'order',
        render: order => moment(order.createdAt).format('LLL')
      },
      {
        key: 'description',
        title: 'Motivo',
        dataIndex: 'description',
        width: 500,
        ...getColumnSearchProps(
          'description',
          this.state.searchText,
          this._updateState
        )
      },
      {
        key: 'userCancel',
        title: 'Cédula',
        dataIndex: 'user',
        ...getColumnSearchProps(
          'user',
          this.state.searchText,
          this._updateState,
          'identification'
        ),
        render: user => user.identification
      },
      {
        key: 'userCancel',
        title: 'Cancelado por',
        dataIndex: 'user',
        ...getColumnSearchProps(
          'user',
          this.state.searchText,
          this._updateState,
          'name'
        ),
        render: user => user.name
      },
      {
        key: 'userRole',
        title: 'Rol',
        dataIndex: 'user',
        render: user => <Tag>{getRoleTraduction(user.rol)}</Tag>
      }
      // {
      //   key: 'origin',
      //   title: 'Origen',
      //   dataIndex: 'origin',
      //   ...getColumnSearchProps(
      //     'origin',
      //     this.state.searchText,
      //     this._updateState,
      //     'name'
      //   ),
      //   render: origin => <IdTooltip id={origin.name} />
      // },
      // {
      //   key: 'destination',
      //   title: 'Destino',
      //   dataIndex: 'destination',
      //   ...getColumnSearchProps(
      //     'destination',
      //     this.state.searchText,
      //     this._updateState,
      //     'name'
      //   ),
      //   render: destination => <IdTooltip id={destination.name} />
      // },
      // {
      //   key: 'status',
      //   title: 'Estado',
      //   dataIndex: 'status',
      //   filters: [
      //     { text: 'Pendiente', value: 0 },
      //     { text: 'Asignado', value: 1 },
      //     { text: 'En proceso', value: 2 },
      //     { text: 'Finalizado', value: 3 },
      //     { text: 'Cancelado', value: 4 }
      //   ],
      //   onFilter: (value, record) => record.status.toString().includes(value),
      //   render: status => {
      //     return <StatusTag status={status} />
      //   }
      // },
      // {
      //   title: 'Fotos',
      //   key: 'photos',
      //   width: 100,
      //   render: item => this._seePhotos(item)
      // },
      // {
      //   key: 'map',
      //   title: 'Mapa',
      //   width: 100,
      //   render: item => this._seeMap(item)
      // },
      // {
      //   title: 'Detalle',
      //   key: 'operation',
      //   fixed: 'right',
      //   width: 100,
      //   render: item => this._mainAction(item)
      // }
    ]

    return (
      <TableList
        title={sectionName}
        data={this.props.data}
        columns={columns}
        filter={this.handleChange}
        notRegister
        buttonDonwload={
          <Button
            type='primary'
            icon='reload'
            onClick={this.props.updateData}
          />
        }
      />
    )
  }
}

export default connect(mapStateToProps)(CancelTable)
