import React from 'react'
import { Spin, Icon } from 'antd'

const Wait = (props) => {
  const { height } = props
  return (
    <>
      <div style={{ height }} className='d-flex align-items-center justify-content-center'>
        <Spin
          indicator={
            <Icon type='loading' spin style={{ fontSize: 30 }} />
          }
        />
      </div>
    </>
  )
}

Wait.defaultProps = {
  height: '500px'
}

export default Wait
