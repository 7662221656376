import React from 'react'
import {Table, Card, Button} from 'antd'
import {connect} from 'react-redux'
import ModalForm from '../Modals/ModalForm'


import ExportExcel from 'react-export-excel'
import moment from "moment";

const ExcelFile = ExportExcel.ExcelFile
const ExcelSheet = ExportExcel.ExcelSheet
const ExcelColumn = ExportExcel.ExcelColumn


const TableList = ({title, data, columns, filter, children, buttonDonwload, notRegister, dataReport}) => (
    <Card
        title={title}
        extra={
            <>
                {dataReport ? <> <ExcelFile element={<Button type="primary" icon="download" style={{"float": "rigth"}}>
                    Descargar reporte
                </Button>} filename={"Reporte_" + moment(new Date()).format("YYYY_MM_DD_HH_mm_ss") + "_tuikit"}>
                    <ExcelSheet data={dataReport} name="Contabilidad Tuikit">
                        <ExcelColumn label="Estado de la orden" value="statusOrder"></ExcelColumn>
                        <ExcelColumn label="id" value="_id"></ExcelColumn>
                        <ExcelColumn label="Numero de identificación" value="identification"></ExcelColumn>
                        <ExcelColumn label="Estado" value="status"></ExcelColumn>
                        <ExcelColumn label="Fecha" value="date"></ExcelColumn>
                        <ExcelColumn label="Transportador" value="driver.name"></ExcelColumn>
                        <ExcelColumn label="Banco" value="driver.pay.bank"></ExcelColumn>
                        <ExcelColumn label="Tipo de cuenta" value="driver.pay.accountType"></ExcelColumn>
                        <ExcelColumn label="Numero de cuenta" value="driver.pay.accountNumber"></ExcelColumn>
                        <ExcelColumn label="Viaje" value="travel"></ExcelColumn>
                        <ExcelColumn label="Ganancia transportador" value="profitsDriver"></ExcelColumn>
                        <ExcelColumn label="Ganancia tuikit" value="profits"></ExcelColumn>
                        <ExcelColumn label="Costo" value="totalCost"></ExcelColumn>
                        <ExcelColumn label="Valor declarado" value="priceCommodity"></ExcelColumn>
                        <ExcelColumn label="Detalle del pago" value="description"></ExcelColumn>

                        <ExcelColumn label="Venta de poliza" value="priceInsurance"></ExcelColumn>
                        <ExcelColumn label="Iva" value="ivaCost"></ExcelColumn>
                        <ExcelColumn label="Costo de poliza" value="priceTuikitInsurance"></ExcelColumn>
                        <ExcelColumn label="Iva de poliza" value="percentTuikitInsurance"></ExcelColumn>
                        <ExcelColumn label="Total recaudado" value="total"></ExcelColumn>

                    </ExcelSheet>

                </ExcelFile> </> : ''}


                {!notRegister &&
                <ModalForm
                    type='primary'
                    modalId='tableListModalForm'
                    icon='fas fa-plus'
                    title={`Registrar ${title.toLowerCase()}`}
                    padding
                    isForm
                >
                    {children}
                </ModalForm>
                }
                {buttonDonwload}
            </>
        }
    >
        <Table
            size='middle'
            columns={columns}
            dataSource={data}
            onChange={filter}
            scroll={{x: 1100}}
            rowKey={record => record._id.substr(18)}
        />
    </Card>
)

export default connect(null)(TableList)
