import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Form, Button, DatePicker} from 'antd'

const {Item} = Form

function mapStateToProps({User}) {
    return {
        token: User.token
    }
}

class DateForm extends Component {
    _handleSubmit = e => {
        e.preventDefault()
        this.props.form.validateFields(this._mainAction)
    }

    _mainAction = (err, values) => {
        this.props.submit(values)

        localStorage.setItem('dateform', JSON.stringify(values))
    }

    render() {
        const {getFieldDecorator} = this.props.form
        return (
            <Form onSubmit={this._handleSubmit}>
                <Item label='Fecha y hora de entrega'>
                    {getFieldDecorator('dateReservation', {
                        rules: [
                            {
                                type: 'object',
                                required: true,
                                message: 'La fecha de entrega es obligatoria'
                            }
                        ],
                    })(
                        <DatePicker
                            showTime
                            /* format='YYYY-MM-DD HH:mm:ss' */
                            className='w-100'
                        />
                    )}
                </Item>
                <Item label='Descripción'>
                    {getFieldDecorator('description', {
                        rules: [
                            {
                                required: false,
                                message: ''
                            }
                        ],
                    })(
                        <textarea class="ant-input"/>
                    )}
                </Item>
                <Button type='primary' htmlType='submit' block>
                    {this.props.item ? 'Editar' : 'Registrar'}
                </Button>
            </Form>
        )
    }
}

const CreatedForm = Form.create({name: 'date_form'})(
    DateForm
)
export default connect(mapStateToProps)(CreatedForm)
