import React, {Component} from 'react'
import {Form, Spin} from 'antd'
import {connect} from 'react-redux'
import io from 'socket.io-client'
import API from '../../../api/Order'
import {getUrl} from '../../../api/Constant'
import {infoAlert} from '../../../components/Forms/formUtil'

function mapStateToProps({User, Profile}) {
    return {
        token: User.token,
        id: Profile._id
    }
}

class PayServiceForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            order: this.props.order,
            price: undefined,
            date: undefined,
            orderId: undefined,
            signature: undefined,
            orderIdFull: this.props.order._id,
            loading: true
        }
    }

    componentDidMount = async () => {
        this.setState({orderId: await this.generatedId()})
        this.getSignature()
        this.detailPay()
    }

    detailPay = async () => {
        const socket = io(getUrl())
        socket.on('orderStatusMobile', async (value) => {
            switch (value.respuesta) {
                case "ABANDONED":
                    this.setState({orderId: await this.generatedId()})
                    localStorage.removeItem('finalPrice')
                    this.props.back()
                    infoAlert(
                        <>
                            {'El pago ha sido abandonado por el usuario'}&nbsp;
                        </>
                    )
                    break
                case "CANCELLED":
                    this.setState({orderId: await this.generatedId()})
                    localStorage.removeItem('finalPrice')
                    this.props.back()
                    infoAlert(
                        <>
                            {'El pago ha sido cancelado por el usuario'}&nbsp;
                        </>
                    )
                    break
                case "CAPTURE_FAILED":
                    this.setState({orderId: await this.generatedId()})
                    localStorage.removeItem('finalPrice')
                    this.props.back()
                    infoAlert(
                        <>
                            {'El pago no ha sido capturado'}&nbsp;
                        </>
                    )
                    break
                case "EXPIRED":
                    this.setState({orderId: await this.generatedId()})
                    localStorage.removeItem('finalPrice')
                    this.props.back()
                    infoAlert(
                        <>
                            {'El pago expiró'}&nbsp;
                        </>
                    )
                    break
                case "REFUSED":
                    this.setState({orderId: await this.generatedId()})
                    localStorage.removeItem('finalPrice')
                    this.props.back()
                    infoAlert(
                        <>
                            {'El pago ha sido rechazado'}&nbsp;
                        </>
                    )
                    break
                default:
                    localStorage.removeItem('finalPrice')
                    infoAlert(
                        <>
                            {'El pago ha sido realizado'}&nbsp;
                        </>
                    )
            }
        })
        return () => {
            socket.close()
            // cleanup
        }
    }

    generatedId = async () => {
        var text = ""
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
        for (var i = 0; i < 6; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length))
        await API.update(this.props.token, {_id: this.state.orderIdFull, orderPay: text})
        return text
    }

    hideSpinner = () => {
        this.setState({
            loading: false
        })
    }

    getSignature = async () => {
        var d = new Date()
        const y = d.getUTCFullYear()
        const m = d.getUTCMonth()
        const day = d.getUTCDate()
        const h = d.getUTCHours()
        const ms = d.getUTCMinutes()
        const s = d.getUTCSeconds()
        const date = `${y}` + `${m < 10 ? "0" + (m + 1) : m + 1}` + `${day < 10 ? "0" + day : day}` + `${h < 10 ? "0" + h : h}` + `${ms < 10 ? "0" + ms : ms}` + `${s < 10 ? "0" + s : s}`
        this.setState({date: date})
        const priceInsurance = localStorage.getItem('finalPrice')
        const price = priceInsurance + "00"
        this.setState({price: price})
        var sign = `INTERACTIVE+${price}+PRODUCTION+170+${this.state.orderIdFull}+PAYMENT+SINGLE+55947977+${date}+${this.state.orderId}+V2+AoUP8E32WaOhOS0z`
        const get = await API.getSignature({
            sign: sign,
            token: this.props.token
        })
        if (get) {
            this.setState({signature: get, loading: false})
        } else {
            this.setState({loading: false})
            console.log('Error:', get)
        }
    }

    _handleSubmit = e => {
        e.preventDefault()
    }

    render() {

        const html = `
        <!DOCTYPE html>
          <html lang="en">

          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Document</title>
          </head>

          <body onload="document.forms[0].submit();">
            <form method="POST" action="https://secure.payzen.lat/vads-payment/">
              <input type="hidden" name="vads_action_mode" value="INTERACTIVE" />
              <input type="hidden" name="vads_amount" value="${this.state.price}" />
              <input type="hidden" name="vads_ctx_mode" value="PRODUCTION" />
              <input type="hidden" name="vads_currency" value="170" />
              <input type="hidden" name="vads_cust_id" value="${this.state.orderIdFull}" />
              <input type="hidden" name="vads_page_action" value="PAYMENT" />
              <input type="hidden" name="vads_payment_config" value="SINGLE" />
              <input type="hidden" name="vads_site_id" value="55947977" />
              <input type="hidden" name="vads_trans_date" value="${this.state.date}" />
              <input type="hidden" name="vads_trans_id" value="${this.state.orderId}" />
              <input type="hidden" name="vads_version" value="V2" />
              <input type="hidden" name="signature" value="${this.state.signature}" />
            </form>
          </body>

          </html>
        `

        return (
            <Form onSubmit={this._handleSubmit}>
                {
                    this.state.loading ?
                        <div style={{textAlign: 'center'}}>
                            <Spin spinning={this.state.loading}/>
                        </div> :
                        <iframe srcDoc={html} width="100%" height="500em" onLoad={this.hideSpinner}/>
                }
            </Form>
        )
    }
}

const CreatedForm = Form.create({name: 'pay_service_form'})(PayServiceForm)
export default connect(mapStateToProps)(CreatedForm)
