import React, { Component } from 'react'
import { API } from '../api'
import { connect } from 'react-redux'
import { errorAlert } from '../components/Forms/formUtil'
import MainHeader from '../components/Headers/MainHeader'
import { Container, Row, Col } from 'reactstrap'
import DriversTable from '../components/Tables/DriversTable'

function mapStateToProps({ User, Profile }) {
  return {
    token: User.token,
    id: Profile._id
  }
}

class EnterpriseDrivers extends Component {

  state = {
    data: []
  }

  componentDidMount() {
    this._setData()
  }

  updateData = async () => {
    this._setData()
  }

  _setData = async () => {
    const { data } = await API.Drivers.getEnterpriseDrivers(this.props.token, this.props.id)
    if (data.error) {
      errorAlert(data.error)
    } else {
      this.setState({
        data
      })
    }
  }

  render() {
    return (
      <>
        <MainHeader />
        <Container className='mt--7' fluid>
          <Row>
            <Col>
              <DriversTable data={this.state.data}></DriversTable>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default connect(mapStateToProps)(EnterpriseDrivers)